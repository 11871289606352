import React from 'react';


interface TextAreaProps extends React.TextareaHTMLAttributes<HTMLTextAreaElement> {
  label?: string;
  error?: string;
  theme?: string
}

export const TextArea: React.FC<TextAreaProps> = ({ label, error, theme, children, ...props }) => {
  return (
    <div className={`w-full flex flex-col justify-center space-y-1 ${theme==='black' ? 'text-black' : 'text-white'}`}>
      <div className='flex justify-between items-center'>
        {children}
      </div>
      <textarea
        className={`focus:outline-none  border rounded-lg py-1 px-3 ${theme==='black' ? 'bg-transparent border-main' : 'text-white bg-transparent border-white'}`}
        {...props}
      ></textarea>
      {error && <div className="text-xs text-red-600">{error}</div>}
    </div>
  );
};