import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useInfiniteQuery, useMutation, useQuery } from "react-query";
import {
  getRequest,
  optimisticOptions,
  postRequest,
  queries,
} from "../../react-query";
import { TriangleLeftIcon, TriangleRightIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import { ReactPlayer, Toast } from "../../components";
import { SourceInput } from "../../components/upload";

// const validationRules = {
//   name: [{ required: true, message: "Please enter project name" }],
//   source_language_id: [
//     { required: true, message: "Please select source language" },
//   ],
//   target_language_id: [
//     { required: true, message: "Please select target language" },
//   ],
//   media_url: [{ required: true, message: "Please select source language" }],
//   media_type: [{ required: false, message: "Please select source language" }],
// };

interface VideoDetail {
  id: string;
  name?: string;
  description?: string;
  preview_link: string;
  access_link: string;
}

interface selectProps {
  videoDetail: VideoDetail;
}

export const Videos = () => {
  const navigate = useNavigate();

  // Constants and State
  let size = 5;
  // const loopInterval = 3000;
  // const previewImageFetchInterval = 3000;
  const [page, setPage] = useState<number>(1);
  const [select, setSelect] = useState<any>({
    video: "",
  });
  // const [loopEnabled, setLoopEnabled] = useState<boolean>(false);
  const [activeVideos, setActiveVideos] = useState<VideoDetail[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const videoRef = useRef<any>(null);
  const canvasRef = useRef<any>(null);
  const [screenshot, setScreenshot] = useState<string | null>(null);

  // Data Fetching
  const {
    data = {},
    isLoading,
    error,
  }: any = useQuery(
    [queries.videos, page, size],
    () => getRequest(`/video?signed=true&page=${page}&pageSize=${size}`),
    {
      enabled: !!page,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  // Destructuring Data
  const { data: videos, pagination } = data;
  const totalPages = useMemo(
    () => Math.ceil(pagination?.totalCount / pagination?.pageSize),
    [pagination]
  );

  // Event Handlers
  const handleNextPage = useCallback(
    () => setPage((prevPage) => prevPage + 1),
    []
  );
  const handlePrevPage = useCallback(
    () => setPage((prevPage) => prevPage - 1),
    []
  );

  const onSelectChange = useCallback(
    (value: VideoDetail, name: string) =>
      setSelect({ ...select, [name]: value }),
    []
  );

  // const handleButtonClick = useCallback(() => {
  //   setLoopEnabled((prev) => !prev);
  // }, []);

  const sendSnapshotToServer = useCallback(
    async (blob: any) => {
      try {
        // setScreenshot(URL.createObjectURL(blob));
        const snapshotFileName = `${Date.now()}_${select.video.id}.jpg`;
        const formData = new FormData();
        formData.append("file", blob, snapshotFileName);
        formData.append("video_detail_id", select.video.id);
        formData.append("is_streaming", "false");

        const res = await postRequest({
          endPoint: "/pre-event",
          payload: formData,
        });

        if (res.id) {
          setIsOpen(true);
        }
      } catch (error) {
        console.error("Error uploading snapshot:", error);
      }
    },
    [select.video]
  );

  const recordPreview = useCallback(async () => {
    if (videoRef.current && canvasRef.current) {
      const player = videoRef.current.getInternalPlayer();
      const canvas: any = canvasRef.current;

      // Draw the current frame of the video on the canvas
      canvas.width = player.videoWidth;
      canvas.height = player.videoHeight;
      canvas
        .getContext("2d")
        .drawImage(player, 0, 0, canvas.width, canvas.height);

      // Get the snapshot data from the canvas as a Blob object
      const snapshotBlob: any = await new Promise((resolve) =>
        canvas.toBlob(resolve, "image/jpeg")
      );

      // Trigger the download of the snapshot Blob
      await sendSnapshotToServer(snapshotBlob);
    }
  }, [videoRef.current, select.video]);

  // Effects
  useEffect(() => {
    if (videos) setActiveVideos(videos);
  }, [videos]);

  // useEffect(() => {
  //   let intervalId: NodeJS.Timeout;
  //   if (loopEnabled) {
  //     intervalId = setInterval(() => {
  //       setCurrentIndex((prevIndex) => (prevIndex + 1) % activeVideos.length);
  //     }, loopInterval);
  //   }
  //   return () => clearInterval(intervalId);
  // }, [loopEnabled, activeVideos.length]);

  useEffect(() => {
    if (videos) {
      setSelect({ ...select, ["video"]: videos[currentIndex] });
    }
  }, [videos, currentIndex]);

  const { mutate: videoSave } = useMutation(
    postRequest,
    optimisticOptions(queries.videos)
  );

  const onFileChangeHandler = (access_link: string, preview_link: string) => {
    videoSave({
      endPoint: "/video",
      payload: {
        preview_link,
        access_link,
        name: "Default",
        description: "Default",
      },
    });
  };

  // Rendering
  return (
    <div className="w-full h-full">
      <div className="w-full px-8 py-4 flex justify-between items-center">
        <div className="flex space-x-2 items-center">
          <button disabled={page === 1} onClick={handlePrevPage}>
            <TriangleLeftIcon
              className={`w-10 h-10 cursor-pointer ${
                page === 1 ? "text-gray-500" : "text-white"
              }`}
            />
          </button>

          <div className="flex space-x-4 items-center text-white">
            {Array.isArray(videos) &&
              activeVideos?.map((obj: any) => {
                return (
                  <div
                    onClick={() => onSelectChange(obj, "video")}
                    key={obj.id}
                    className={`w-32 h-32 cursor-pointer relative border ${
                      select.video.id === obj.id
                        ? "border-green"
                        : "border-header-border"
                    } rounded`}
                  >
                    <img
                      src={obj.preview_link}
                      alt="preview"
                      className="w-full h-full rounded"
                    />

                    <div className="w-full absolute bottom-0 px-1 flex justify-between items-center text-white">
                      <p>{obj.name}</p>
                    </div>
                  </div>
                );
              })}

            <SourceInput
              accept="video/*"
              innerClassName="grid place-content-center p-3"
              className="w-32 h-32 rounded border border-dashed"
              label="Upload video"
              onFileChangeHandler={onFileChangeHandler}
            />
          </div>

          <button disabled={page === totalPages} onClick={handleNextPage}>
            <TriangleRightIcon
              className={`w-10 h-10 ${
                page === totalPages ? "text-gray-500" : "text-white"
              }`}
            />
          </button>
        </div>

        <div className="flex h-full flex-col space-y-2">
          {/* <button className="bg-[#6E6767] p-1 rounded text-white">
            Gallery
          </button> */}
          {/* <button
            onClick={handleButtonClick}
            className={`p-1 rounded text-white ${
              loopEnabled ? "bg-blue-600" : "bg-[#6E6767]"
            }`}
          >
            {loopEnabled ? "Stop Looping" : "Start Looping"}
          </button> */}
          {select && select.video && (
            <button
              onClick={() => {
                navigate(`/video/${select.video.id}/labelling`);
              }}
              className="w-36 bg-blue-600 p-1 text-white"
            >
              Submit Label
            </button>
          )}
        </div>
      </div>

      {select && select.video && (
        <div className="relative h-[calc(100vh-240px)] px-4">
          <ReactPlayer videoRef={videoRef} url={select.video.access_link} />
          {screenshot && (
            <div>
              <h3>Screenshot:</h3>
              <img src={screenshot} alt="Video Screenshot" />
            </div>
          )}
          <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
          <div className="fixed bottom-24 right-8">
            {select.video.status === "ACTIVE" && (
              <Toast open={isOpen} setOpen={setIsOpen}>
                <button
                  onClick={recordPreview}
                  className="bg-red-500 active:bg-red-800 px-6 py-1.5 text-white rounded-full"
                >
                  Record
                </button>
              </Toast>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
