import React, { useCallback, useState } from "react";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import { TextInput, UploadFile } from "../../../../components";
import { useMutation } from "react-query";
import {
  optimisticOptions,
  postRequest,
  queries,
} from "../../../../react-query";
import { validateForm } from "../../../../utils";

export const CreateInstruction = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    pdf_link: "",
  });

  const [errors, setErrors] = useState<Record<string, string>>({});

  const onChangeHandler = (value: string, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const { mutate: createClient } = useMutation(
    postRequest,
    optimisticOptions(queries.instructions)
  );

  // Define validation rules
  const validationRules = {
    name: [{ required: true, message: "Name is required." }],
    pdf_link: [{ required: true, message: "PDF Link is required." }],
  };

  const onSubmitHandler = useCallback(
    (e: any) => {
      e.preventDefault();

      // Validate form data
      const formErrors = validateForm({ ...formData }, validationRules);
      setErrors(formErrors);

      // If no validation errors, proceed with submission
      if (Object.keys(formErrors).length === 0) {
        let form = new FormData();
        form.append("name", formData.name);
        form.append("file", formData.pdf_link);
        createClient({
          endPoint: "/instruction",
          payload: form
        });
        navigate(-1);
      }
    },
    [formData]
  );

  return (
    <div className="bg-white w-full h-full p-8 space-y-4">
      <div className="flex w-full h-10 space-x-4 items-center">
        <ArrowLeftIcon
          onClick={() => navigate("/settings")}
          className="w-6 h-6 text-black cursor-pointer"
        />
        <span className="font-bold">Create Instruction</span>
      </div>
      <form
        onSubmit={onSubmitHandler}
        className="w-full h-[calc(100vh-180px)] flex flex-col justify-between border border-gray-200 p-4 rounded"
      >
        <div className="grid grid-cols-2 gap-8">
          <TextInput
            type="text"
            placeholder=""
            value={formData.name}
            onChange={(e) => onChangeHandler(e.target.value, "name")}
            theme="black"
            error={errors.name}
          >
            <label htmlFor="name">Name*</label>
          </TextInput>

          <UploadFile
            accept="application/pdf,application/vnd.ms-excel"
            onChange={(e: any) =>
              onChangeHandler(e.target.files[0], "pdf_link")
            }
            theme="black"
            className="w-full flex items-center file:text-text file:text-end file:bg-main file:border-none"
          >
            <label htmlFor="pdf_link">Upload PDF*</label>
          </UploadFile>
        </div>
        <div className="w-full flex justify-end items-center">
          <button
            type="submit"
            className="rounded text-white bg-green px-6 py-2"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};