import React, { useState } from "react";
import Bg from "../assets/images/bg.png";
import { SigninForm, SignupForm } from "../components";

export function AuthWrapper() {
  const [authPage, setAuthPage] = useState("SigninForm");

  const renderTitle = () => {
    switch (authPage) {
      case "SigninForm":
        return <h1 className="text-2xl">Sign in now</h1>;
      case "SignupForm":
        return <h1 className="text-2xl">Sign up now</h1>;
      default:
        return <h1 className="text-2xl">Sign in now</h1>;
    }
  };

  const renderForm = () => {
    switch (authPage) {
      case "SigninForm":
        return <SigninForm setAuthPage={setAuthPage} />;
      case "SignupForm":
        return <SignupForm setAuthPage={setAuthPage} />;
      default:
        return <SigninForm setAuthPage={setAuthPage} />;
    }
  };

  const renderBottom = () => {
    switch (authPage) {
      case "SigninForm":
        return (
          <p className="text-sm font-light text-gray-500 dark:text-gray-400 text-center">
            Not a member?{" "}
            <a
              
              onClick={() => setAuthPage("SignupForm")}
              className="text-blue-500 font-medium text-primary-600 hover:underline dark:text-primary-500"
            >
              Signup here
            </a>
          </p>
        );
      case "SignupForm":
        return (
          <p className="text-sm font-light text-gray-500 dark:text-gray-400 text-center">
            Already have an account?{" "}
            <a
              href="javascript:void(0);"
              onClick={() => setAuthPage("SigninForm")}
              className="text-blue-500 font-medium text-primary-600 hover:underline dark:text-primary-500"
            >
              signin here
            </a>
          </p>
        );
      default:
        return (
          <p className="text-sm font-light text-gray-500 dark:text-gray-400 text-center">
            Not a member?{" "}
            <a
              href="javascript:void(0);"
              onClick={() => setAuthPage("SignupForm")}
              className="text-blue-500 font-medium text-primary-600 hover:underline dark:text-primary-500"
            >
              Signup here
            </a>
          </p>
        );
    }
  };

  return (
    <div className="w-full h-full flex  items-center bg-primary">
      <div className="relative w-1/2 h-[400px] flex flex-col justify-center items-center text-white">
        <div className="px-[124px]">
          <h1 className="text-2xl font-bold text-white">Unlock the power of AI for your industry with your data.</h1>
        </div>

        <div className="w-full h-[300px]">
          <img className="w-full h-full" src={Bg} alt="bg" />
        </div>
      </div>

      <div className="h-[560px] w-[500px] bg-white rounded-lg p-8 space-y-5">
        {renderTitle()}
        {renderForm()}
        {renderBottom()}
      </div>
    </div>
  );
}
