import React from "react";
import { useQuery } from "react-query";
import { getRequest, queries } from "../../../../react-query";
import { AvatarIcon } from "@radix-ui/react-icons";

export const Profile = () => {
  const { data: profileData = {}, isLoading }: any = useQuery(
    [queries.profile],
    () => getRequest(`/profile`)
  );

  // Destructuring Data
  const { data: profile = {} } = profileData;

  if (isLoading) {
    return <div />;
  }

  return (
    <div className="text-black">
      <div className="relative flex h-52 justify-between items-end  bg-gradient-to-r from-[#CD6C6C] to-[#004CE1] p-6">
        <div className="w-full flex space-x-4">
          <div className="w-[120px] h-[120px]">
            <img src={profile.image} alt="profile" className="w-full h-full rounded-full" />
          </div>

          <div className="space-y-0">
            <div className="font-bold text-2xl text-white">
              {profile.username}
            </div>
            <div className="text-white">{profile.email}</div>
            <div className="text-white">Role : {profile.roles}</div>
            <div className="text-white">Contact : {profile.contact}</div>
          </div>
        </div>

        <div>
          <button className="text-nowrap bg-white px-4 py-1 rounded">
            Password Change
          </button>
        </div>
      </div>

      <div className="grid grid-cols-4 gap-x-4 mt-8">
        <div className="bg-white p-2 shadow-[#E1DDDD] rounded  shadow-lg drop-shadow-lg text-black">
          <p className="font-bold">Events Captured</p>
          <p>{profile.captured}</p>
        </div>
        <div className="bg-white p-2 shadow-[#E1DDDD] rounded   shadow-lg drop-shadow-lg text-black">
          <p className="font-bold">Events Approved</p>
          <p>{profile.approved}</p>
        </div>
        <div className="bg-white p-2 shadow-[#E1DDDD] rounded  shadow-lg drop-shadow-lg text-black">
          <p className="font-bold">Rejected Events</p>
          <p>{profile.rejected}</p>
        </div>
        <div className="bg-white p-2 shadow-[#E1DDDD] rounded  shadow-lg drop-shadow-lg text-black">
          <p className="font-bold">Performance Badge</p>
          <p>{profile.performance_category}</p>
        </div>
      </div>
    </div>
  );
};
