import { QueryClient } from "react-query";
import { toast } from "react-toastify";
import { queries } from "./Queries";

function queryErrorHandler(error: any): void {
  const title =
    error instanceof Error ? error.message : "error connecting to server";
   
  // toast.error(title);
}

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      onError: queryErrorHandler,
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    },
    mutations: {
      onError: queryErrorHandler,
    },
  },
});

const optimisticOptions = (query: string) => {
  return {
    onMutate: async (newChange: any) => {
      await queryClient.cancelQueries([query, newChange.id]);

      const previousData = await queryClient.getQueryData([query, newChange.id]);

      await queryClient.setQueryData([query, newChange.id], newChange);

      return { previousData, newChange };
    },
    onError: async (err: any, newChange: any, context: any) => {
      await queryClient.setQueryData([query, context.newChange.id], context.previousData);
    },
    onSettled: async (newChange: any) => {
      await queryClient.invalidateQueries([query]); // Invalidate the single item query
      await queryClient.invalidateQueries([query]); // Invalidate the list query to trigger a refetch
    },
  };
};

export { queryClient, optimisticOptions };
