import React from "react";

export const ColumnFilter = ({ column }: any) => {
  const { filterValue, setFilter } = column;
  return (
    <input
      value={filterValue || ""}
      className="w-20 border border-gray-500 focus:outline-none px-2"
      onChange={(e) => setFilter(e.target.value)}
    />
  );
};
