import ls from "localstorage-slim";

/**
 * Retrieves a value from storage using the provided key.
 *
 * @param {string} key - The key to retrieve the value from storage
 * @return {any} The value stored in the specified key
 */
export const getStorage = (key: string):any => {
  return ls.get(key, { decrypt: true });
};

/**
 * Removes the specified item from the storage.
 *
 * @param {string} key - the key of the item to be removed from storage
 * @return {void}
 */
export const removeStorage = (key: string) => {
  // console.log("removeStorage: ", key);
  ls.remove(key);
};

/**
 * Sets the value in the storage for the given key.
 *
 * @param {string} key - The key for the value in the storage
 * @param {any} value - The value to be stored
 * @return {undefined}
 */
export const setStorage = (key: string, value: any) => {
  // console.log("setStorage: ", `${key}: ${value}`);
  ls.set(key, value);
};

export default getStorage;
