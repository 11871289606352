import React, { useCallback, useEffect, useState } from "react";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  NormalSelect,
  ScrollArea,
  TagsInput,
  TextInput,
} from "../../../../components";
import { useMutation, useQuery } from "react-query";
import {
  getRequest,
  optimisticOptions,
  putRequest,
  queries,
} from "../../../../react-query";
import { validateForm } from "../../../../utils";
import errorToast from "../../../../utils/errorToast";

const status = [
  {
    label: "ACTIVE",
    value: "ACTIVE"
  },
  {
    label: "INACTIVE",
    value: "INACTIVE"
  }
]

export const UpdateClient = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [formData, setFormData] = useState({
    full_name: "",
    company_name: "",
    designation: "",
    email: "",
    contact: "",
    state: "",
    city: "",
    pin_code: "",
    status: {
      label: "",
      value: "",
    },
    street_address: "",
    previous_status: "",
    country: {
      label: "",
      value: "",
    },
  });

  const [locations, setLocations] = useState<string[]>([]);

  const [errors, setErrors] = useState<Record<string, string>>({});

  const { data: countriesData = {}, isLoading: isCountryLoading }: any =
    useQuery([queries.clients], () => getRequest(`/country`));

  const { data: countries = [], pagination } = countriesData;

  const { data: clientData = {}, isLoading: isClientLoading }: any = useQuery(
    [queries.country, id],
    () => getRequest(`/client/${id}`),
    {
      enabled: !!id,
    }
  );

  const { data: client = {} } = clientData;

  const { mutate: updateClient } = useMutation(
    putRequest,
    optimisticOptions(queries.clients)
  );

  const onChangeHandler = (value: string, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  // Define validation rules
  const validationRules = {
    full_name: [{ required: true, message: "Name is required." }],
    company_name: [{ required: true, message: "Legal name is required." }],
    state: [{ required: true, message: "State is required." }],
    designation: [{ required: true, message: "Legal name is required." }],
    city: [{ required: true, message: "City is required." }],
    pin_code: [{ required: true, message: "Pin code is required." }],
    street_address: [{ required: true, message: "Street address is required." }],
    country: [{ required: true, message: "Country selection is required." }],
    contact: [{ required: true, message: "Contact is required." }],
    email: [
      { required: true, message: "Email is required." },
      { pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: "Invalid email format." },
    ],
  };

  const onSubmitHandler = useCallback(
    (e: any) => {
      e.preventDefault();

      // Validate form data
      const formErrors = validateForm(formData, validationRules);
      setErrors(formErrors);
      console.log("formerroe", formErrors)

      // If there are no errors, proceed with the submission
      if (Object.keys(formErrors).length === 0) {
        updateClient({
          endPoint: `/client/${client.id}`,
          payload: {
            locations: locations,
            status: formData.status.value,
            previous_status: formData.previous_status,
            full_name: formData.full_name,
            email: formData.email,
            contact: formData.contact,
            company_name: formData.company_name,
            designation: formData.designation,
            address: {
              state: formData.state,
              pin_code: formData.pin_code,
              street_address: formData.street_address,
              city: formData.city,
              country_id: formData.country.value,
            },

          },
        });
        navigate(-1);
      } else {
        errorToast('please check all fields')
      }
    },
    [formData, locations, client]
  );

  useEffect(() => {
    if (client && client.id) {
      console.log("useee", client)
      setFormData({
        ...formData,
        full_name: client.full_name,
        designation: client.designation,
        company_name: client.company_name,
        state: client?.address?.state,
        city: client?.address?.city,
        email: client.email,
        status: {
          label: client.status,
          value: client.status,
        },
        previous_status: client.status,
        contact: client.contact,
        pin_code: client?.address?.pin_code,
        street_address: client?.address?.street_address,
        country: {
          label: client?.address?.country.name,
          value: client?.address?.country?.id,
        }
      });
      setLocations(client.locations?.map((ele: any) => ele.name));
    }
  }, [client]);

  if (isClientLoading && isCountryLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {formData && (
        <div className="bg-white w-full h-full p-8 space-y-4">
          <div className="flex w-full h-10 space-x-4 items-center">
            <ArrowLeftIcon
              onClick={() => navigate("/settings")}
              className="w-6 h-6 text-black cursor-pointer"
            />
            <span className="font-bold">Update Client</span>
          </div>
          <form
            onSubmit={onSubmitHandler}
            className="w-full h-[calc(100vh-210px)] space-y-4 border border-gray-200 py-4 rounded"
          >
            <div className="px-4">
              <ScrollArea className="h-[calc(100vh-230px)] py-4">
                <div className="grid grid-cols-3 gap-8">
                  <TextInput
                    type="text"
                    placeholder=""
                    value={formData.full_name}
                    onChange={(e) => onChangeHandler(e.target.value, "full_name")}
                    required
                    theme="black"
                    error={errors.full_name}
                  >
                    <label htmlFor="name">User Name*</label>
                  </TextInput>

                  <TextInput
                    type="text"
                    placeholder=""
                    value={formData.company_name}
                    onChange={(e) =>
                      onChangeHandler(e.target.value, "company_name")
                    }
                    required
                    theme="black"
                    error={errors.company_name}
                  >
                    <label htmlFor="streamingKey">Company Name*</label>
                  </TextInput>

                  <TextInput
                    type="text"
                    placeholder=""
                    value={formData.designation}
                    onChange={(e) => onChangeHandler(e.target.value, "designation")}

                    theme="black"
                    error={errors.designation}
                  >
                    <label htmlFor="streamingKey">Designation*</label>
                  </TextInput>

                  <TextInput
                    type="email"
                    placeholder=""
                    value={formData.email}
                    onChange={(e) => onChangeHandler(e.target.value, "email")}

                    theme="black"
                    error={errors.email}
                  >
                    <label htmlFor="streamingKey">Email*</label>
                  </TextInput>

                  <TextInput
                    type="text"
                    placeholder=""
                    value={formData.contact}
                    onChange={(e) => onChangeHandler(e.target.value, "contact")}

                    theme="black"
                    error={errors.contact}
                  >
                    <label htmlFor="applicationName">Contact*</label>
                  </TextInput>

                  <NormalSelect
                    theme="black"
                    keyField="id"
                    valueField="name"
                    className="border border-main text-nowrap"
                    data={countries}
                    onValueChange={(value) =>
                      onChangeHandler(value, "country")
                    }
                    defaultValue={formData.country}
                    placeholder="Select"
                    error={errors.country}
                  >
                    <label htmlFor="remark">Country*</label>
                  </NormalSelect>

                  <NormalSelect
                    theme="black"
                    keyField="label"
                    valueField="value"
                    className="border border-main text-nowrap"
                    data={status}
                    onValueChange={(value) => {
                      onChangeHandler(formData.status.value, "previous_status")
                      onChangeHandler(value, "status")
                    }
                    }
                    defaultValue={formData.status}
                    placeholder="Select"
                    error={errors.country}
                  >
                    <label htmlFor="remark">Status*</label>
                  </NormalSelect>

                  <TextInput
                    type="text"
                    placeholder=""
                    value={formData.state}
                    onChange={(e) => onChangeHandler(e.target.value, "state")}
                    required
                    theme="black"
                    error={errors.state}
                  >
                    <label htmlFor="applicationName">State*</label>
                  </TextInput>

                  <TextInput
                    type="text"
                    placeholder=""
                    value={formData.city}
                    onChange={(e) => onChangeHandler(e.target.value, "city")}
                    required
                    theme="black"
                    error={errors.city}
                  >
                    <label htmlFor="applicationName">City*</label>
                  </TextInput>

                  <TextInput
                    type="text"
                    placeholder=""
                    value={formData.pin_code}
                    onChange={(e) =>
                      onChangeHandler(e.target.value, "pin_code")
                    }
                    required
                    theme="black"
                    error={errors.pin_code}
                  >
                    <label htmlFor="applicationName">Pin Code*</label>
                  </TextInput>

                  <div className="space-y-1 col-span-3">
                    <span>Locations*</span>
                    <TagsInput
                      placeholder="Add a location"
                      tags={locations}
                      onValuePush={(value: string) => {
                        setLocations([...locations, value]);
                      }}
                      onValueRemove={(value: string) => {
                        const filterLocations = locations.filter(
                          (e) => e !== value
                        );
                        setLocations(filterLocations);
                      }}
                    />
                  </div>

                  <TextInput
                    type="text"
                    placeholder=""
                    value={formData.street_address}
                    onChange={(e) =>
                      onChangeHandler(e.target.value, "street_address")
                    }
                    required
                    theme="black"
                    error={errors.street_address}
                  >
                    <label htmlFor="applicationName">Street Address</label>
                  </TextInput>
                </div>
              </ScrollArea>
            </div>

            <div className="w-full flex justify-end items-center">
              <button
                type="submit"
                className="rounded text-white bg-green px-6 py-2"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};
