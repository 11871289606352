import React, { useEffect, useState } from "react";
import { ScrollArea } from "../scrollable";

interface SubCategory {
  id: string;
  name: string;
}

interface Category {
  id: string;
  name: string;
  color: string;
  sub_categories: SubCategory[];
}

interface SelectedCategory {
  category_id: string;
  subcategory_ids: string[];
}

interface MultiSelectDropdownProps {
  categories: Category[];
  onSelect: (selectedCategory: SelectedCategory | null) => void;
  children: any;
  theme: string;
  className: string;
  defaultValue?: any;
}

export const SingleSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
  categories,
  onSelect,
  children,
  theme,
  className,
  defaultValue,
}) => {
  const [selectedCategory, setSelectedCategory] = useState<SelectedCategory | null>(null);
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleSubcategoryChange = (subcategoryId: string, categoryId: string) => {
    const category = categories.find(c => c.id === categoryId);
    if (!category) return;

    // Automatically select the category if a subcategory is chosen
    setSelectedCategory(prev => {
      if (!prev || prev.category_id !== categoryId) {
        // Reset subcategory_ids if selecting a different category
        return { category_id: categoryId, subcategory_ids: [subcategoryId] };
      }

      // Toggle subcategory selection
      const subcategoryIds = prev.subcategory_ids.includes(subcategoryId)
        ? prev.subcategory_ids.filter(id => id !== subcategoryId) // Uncheck
        : [...prev.subcategory_ids, subcategoryId]; // Check

      return { ...prev, subcategory_ids: subcategoryIds };
    });
  };

  const handleSubmit = () => {
    onSelect(selectedCategory);
    toggleDropdown(); // Close dropdown after submission
  };

  useEffect(() => {
    if (defaultValue) {
      setSelectedCategory(defaultValue);
      onSelect(defaultValue);
    }
  }, [defaultValue]);

  return (
    <div className="space-y-1">
      {children}
      <div className="relative">
        <div
          onClick={toggleDropdown}
          className={`w-full inline-flex min-h-10 items-center justify-between bg-transparent ${className} px-2 space-x-2 focus:outline-none ${
            theme === "black"
              ? "text-black border-main rounded-lg"
              : "text-white border-white rounded-lg"
          }`}
        >
          {selectedCategory ? (
            <div>
              <span>
                {categories.find(c => c.id === selectedCategory.category_id)?.name}
              </span>
              <span className="mr-1">-</span>
              {selectedCategory.subcategory_ids.map((subcategoryId, index) => (
                <span key={subcategoryId}>
                  {
                    categories
                      .find(c => c.id === selectedCategory.category_id)
                      ?.sub_categories.find(s => s.id === subcategoryId)
                      ?.name
                  }
                  {index < selectedCategory.subcategory_ids.length - 1 && ", "}
                </span>
              ))}
            </div>
          ) : (
            <span>Select</span>
          )}
        </div>
        {showDropdown && (
          <div>
            <div className="absolute z-[9999] mt-1 min-w-[400px] bg-white border border-gray-300 rounded-md shadow-lg">
              <div className="p-4 space-y-4">
                <ScrollArea className="h-32">
                  <div className="space-y-4">
                    {categories.map(category => (
                      <div key={category.id}>
                        <div className="font-bold">{category.name}</div>
                        <ul className="flex space-x-2">
                          {category.sub_categories.map(subcategory => (
                            <li key={subcategory.id} className="flex items-center">
                              <input
                                type="checkbox"
                                id={subcategory.id}
                                checked={selectedCategory?.category_id === category.id && selectedCategory.subcategory_ids.includes(subcategory.id)}
                                onChange={() => handleSubcategoryChange(subcategory.id, category.id)}
                                className="mr-2"
                              />
                              <label htmlFor={subcategory.id}>{subcategory.name}</label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </ScrollArea>
                <div className="mt-4 flex justify-end">
                  <button
                    onClick={handleSubmit}
                    className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
