import { postRequest } from "../react-query";

export  const getThumbnailForVideo = async (file: any) => {
    let key = `${file.name.split(".")[0]}_${Date.now()}.png`;

    const videoUrl = URL.createObjectURL(file);
    const video = document.createElement("video");

    video.src = videoUrl;
    video.crossOrigin = "anonymous"; // Allows canvas access to video pixels

    // Wait for video metadata to load
    await new Promise((resolve) => {
      video.addEventListener("loadedmetadata", resolve);
    });

    // Create a canvas for drawing the thumbnail
    const canvas = document.createElement("canvas");
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    const ctx: any = canvas.getContext("2d");

    // Draw video frame at 25% of video duration
    video.currentTime = video.duration * 0.25;
    await new Promise((resolve: any) => {
      video.addEventListener("seeked", async () => {
        // Draw video frame onto the canvas
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
        // Convert canvas content to base64 image URL
        // const thumbUrl = canvas.toDataURL("image/png");
        // setThumbnailUrl(thumbUrl);
        resolve();
      });
    });

    canvas.toBlob(async (blob: any) => {
      const formData = new FormData();
      formData.append("file", blob, key);
      formData.append("path", "thumbnails");

      try {
        await postRequest({
          endPoint: `/upload/image`,
          payload: formData,
        });
      } catch (error) {
        console.log(error);
      }
    });

    return key;
  };