export const queries = {
   camera:"camera",
   videos:"videos",
   images:"images",
   preEvent:"preEvent",
   category:"category",
   event:"event",
   quality:"quality",
   country:"country",
   clients:"clients",
   categories:"categories",
   users:"users",
   profile:"profile",
   dataset:"dataset",
   slots:'slots',
   instructions:'instructions'
}