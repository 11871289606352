import React, { useCallback, useMemo, useState } from "react";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import {
  SingleSelectDropdown,
  NormalSelect,
  ScrollArea,
  TextArea,
  TextInput,
  MultiSelect,
} from "../../../../components";
import {
  getRequest,
  optimisticOptions,
  postRequest,
  queries,
} from "../../../../react-query";
import { useMutation, useQuery } from "react-query";
import { SourceZip } from "../../../../components/upload";
import { validateForm } from "../../../../utils";
import errorToast from "../../../../utils/errorToast";

export const CreateImage = () => {
  const navigate = useNavigate();
  const [status, setStatus] = useState("initial");
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    client: {
      label: "",
      value: "",
    },
    image_captains: [],
    image_quality_controllers: [],
    zip_file: "",
    instruction_id: {
      label: "",
      value: "",
    }
  });

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [selectedCategory, setSelectedCategory] = useState<any>();

  const { data: clientData = [], isClientLoading }: any = useQuery(
    [queries.clients],
    () => getRequest(`/client`)
  );

  // Destructuring Data
  const { data: clients = [] } = clientData;
  const { data: categoryData = [], isCategoryLoading }: any = useQuery(
    [queries.categories],
    () => getRequest(`/category`)
  );

  // Destructuring Data
  const { data: categories = [] } = categoryData;

  const {
    data: instructionsData = {}
  }: any = useQuery([queries.instructions], () => getRequest(`/instruction`));

  const { data: instructions = [] } = instructionsData;

  const { data: safetyMembers = [], isSafetyLoading }: any = useQuery(
    [queries.users],
    () => getRequest(`/users`)
  );

  // Destructuring Data
  const { data: captains = [] } = safetyMembers;

  const onChangeHandler = (value: string | number, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const { mutate: createDataset } = useMutation(
    postRequest,
    optimisticOptions(queries.images)
  );

  // Define validation rules
  const validationRules = {
    name: [{ required: true, message: "Name is required." }],
    description: [{ required: true, message: "Description is required." }],
    client: [{ required: true, message: "Client selection is required." }],
    image_captains:[{required: true, message:"select a captain"}],
    image_quality_controllers:[{required: true, message:"select a captain"}],
    instruction_id: [{ required: true, message: "Instruction is required" }],
  };


  const onSubmitHandler = useCallback(
    (e: any) => {
      e.preventDefault();

      // Validate form data
      const formErrors = validateForm(formData, validationRules);
      setErrors(formErrors);

      // Proceed with submission if no validation errors
      if (Object.keys(formErrors).length === 0) {
        let formData_ = new FormData();
        formData_.append("name", formData.name);
        formData_.append("description", formData.description);
        formData_.append("client_id", formData.client.value);
        formData_.append("category_id", selectedCategory.category_id);
        selectedCategory.subcategory_ids.map((id: string) => {
          formData_.append("sub_categories[]", id);
        });
        formData.image_captains.map((captain: any) => {
          formData_.append("image_captains[]", captain.value);
        });
        formData.image_quality_controllers.map((captain: any) => {
          formData_.append("image_quality_controllers[]", captain.value);
        });
        formData_.append("file", formData.zip_file);
        formData_.append("instruction_id", formData.instruction_id.value)

        createDataset({
          endPoint: "/dataset",
          payload: formData_,
        });

        navigate(-1);
      } else {
        errorToast('please check all fields')
      }
    },
    [formData, selectedCategory]
  );

  const handleSelectCategories = (selectedCategory: any) => {
    setSelectedCategory(selectedCategory);
  };

  // Memoized filtered captains
  const image_captains = useMemo(
    () =>
      captains
        .filter((c: any) => c.roles.includes("IMAGE_CAPTAIN"))
        .map((c: any) => ({ label: c.username, value: c.id })),
    [captains]
  );
  const image_quality_controllers = useMemo(
    () =>
      captains
        .filter((c: any) => c.roles.includes("IMAGE_QUALITY_CONTROLLER"))
        .map((c: any) => ({ label: c.username, value: c.id })),
    [captains]
  );

  //console.log("image_captains", image_captains, "quality_controllers", quality_controllers)
  if (isCategoryLoading || isClientLoading) {
    return <div />;
  }



  return (
    <div className="bg-white w-full h-full p-8 space-y-4">
      <div className="flex w-full h-10 space-x-4 items-center">
        <ArrowLeftIcon
          onClick={() => navigate("/settings")}
          className="w-6 h-6 text-black cursor-pointer"
        />
        <span className="font-bold">Create Image Dataset</span>
      </div>
      <form
        onSubmit={onSubmitHandler}
        className="w-full h-[calc(100vh-210px)] space-y-4 border border-gray-200 py-4 rounded"
      >
        <div className="px-4">
          <ScrollArea className="h-[calc(100vh-230px)] py-4">
            <div className="grid grid-cols-3 gap-8">
              <TextInput
                type="text"
                placeholder=""
                value={formData.name}
                onChange={(e) => onChangeHandler(e.target.value, "name")}
           
                theme="black"
                error={errors.name}
              >
                <label htmlFor="name">Dateset Name*</label>
              </TextInput>

              <NormalSelect
                theme="black"
                keyField="id"
                valueField="name"
                className="border border-main text-nowrap"
                data={instructions}
                error={errors.instruction_id}
                onValueChange={(value: string) =>
                  onChangeHandler(value, "instruction_id")
                }
                defaultValue={formData.instruction_id}
                placeholder="Select"
              >
                <label htmlFor="remark">Insruction Template</label>
              </NormalSelect>

              <NormalSelect
                theme="black"
                keyField="id"
                valueField="company_name"
                className="border border-main text-nowrap"
                data={clients}
                error={errors.client}
                onValueChange={(cli: any) => {
                  let client = clients.find(
                    (client: any) => client.id === cli.value
                  );
                  setFormData({ ...formData, ["client"]: cli });
                }}
                defaultValue={formData.client}
                placeholder="Select"
              >
                <label htmlFor="remark">Clients Name*</label>
              </NormalSelect>

              <div className="col-span-3">
                <SingleSelectDropdown
                  className="border border-main text-nowrap"
                  theme="black"
                  categories={categories}
                  onSelect={handleSelectCategories}
                >
                  <label htmlFor="Select">Categories*</label>
                </SingleSelectDropdown>
              </div>

              <div className="col-span-3">
                <MultiSelect
                  theme="white"
                  keyField="value"
                  valueField="label"
                  data={image_captains}
                  error={errors.image_captains}
                  onValueChange={(value) =>
                    onChangeHandler(value, "image_captains")
                  }
                  value={formData.image_captains}
                  defaultValue={formData.image_captains}
                  placeholder="Image Captains"
                  isMulti
                >
                  <label htmlFor="role">Image Captains*</label>
                </MultiSelect>
              </div>

              <div className="col-span-3">
                <MultiSelect
                  theme="white"
                  keyField="value"
                  valueField="label"
                  data={image_quality_controllers}
                  error={errors.image_quality_controllers}
                  onValueChange={(value) =>
                    onChangeHandler(value, "image_quality_controllers")
                  }
                  value={formData.image_quality_controllers}
                  defaultValue={formData.image_quality_controllers}
                  placeholder="Quality Controllers"
                  isMulti
                >
                  <label htmlFor="role">Image Quality Controllers*</label>
                </MultiSelect>
              </div>

              <div className="col-span-3">
                <TextArea
                  placeholder=""
                  value={formData.description}
                  onChange={(e) =>
                    onChangeHandler(e.target.value, "description")
                  }
                
                  theme="black"
                  error={errors.description}
                >
                  <label htmlFor="description">Dataset Description*</label>
                </TextArea>
              </div>

              <div className="col-span-3">
                <SourceZip
                  status={status}
                  accept=".zip"
                  innerClassName="border border-dashed p-8"
                  label="Upload zip"
                  onChange={(e: any) => {

                    if (e.target.files[0]) {
                      setStatus("uploaded")
                    } else {
                      setStatus("initial")
                    }
                    onChangeHandler(e.target.files[0], "zip_file");
                  }}
                >
                  <label htmlFor="description">Zip Source*</label>
                </SourceZip>
              </div>
            </div>
          </ScrollArea>
        </div>

        <div className="w-full flex justify-end items-center">
          <button
            type="submit"
            className="rounded text-white bg-green px-6 py-2"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};
