import { AnonymousLayout, MainLayout } from "../layouts";

import {
  AuthWrapper,
  PageNotFound,
  Monitor,
  Videos,
  DatasetList,
  Gallery,
  Quality,
  Settings,
  Forbidden,
} from "../pages";

import MonitorIcon from "../assets/images/sidebar/monitor.svg";
import VideosIcon from "../assets/images/sidebar/movie.svg";
import ImagesIcon from "../assets/images/sidebar/camera.svg";
import QualityIcon from "../assets/images/sidebar/guarantee.svg";
import SettingsIcon from "../assets/images/sidebar/support.svg";
import { renderRoutes } from "./generate-routes";
import { StreamingLabelling, VideoLabelling, ImageLabelling } from "../pages";
import {
  CreateCamera,
  CreateCaptain,
  CreateClient,
  CreateImage,
  CreateVideo,
  UpdateCamera,
  UpdateCaptain,
  UpdateClient,
  UpdateImage,
  UpdateVideo,
} from "../pages/settings/tabs";
import { CreateCategory } from "../pages/settings/tabs/categories/create.category";
import { UpdateCategory } from "../pages/settings/tabs/categories/update.category";
import { Role } from "../utils/enum";
import { CreateSlot, UpdateSlot } from "../pages/settings/tabs/slots";
import { UpdateInstruction } from "../pages/settings/tabs/instructions/update.instruction";
import { CreateInstruction } from "../pages/settings/tabs/instructions/create.instruction";

interface Route {
  name: string;
  title: string;
  element: React.ReactElement;
  path: string;
  icon?: React.ReactNode;
  roles?: string[];
  routes?: Route[];
}

interface Layout {
  layout: React.ComponentType<any>;
  isPublic: boolean;
  routes: Route[];
}

export const routes: Layout[] = [
  {
    layout: AnonymousLayout,
    isPublic: true,
    routes: [
      {
        name: "auth",
        title: "Auth page",
        element: <AuthWrapper />,
        path: "/auth",
      },
      {
        name: "page-not-found",
        title: "Page Not Found",
        element: <PageNotFound />,
        path: "*",
      },
    ],
  },
  {
    layout: MainLayout,
    isPublic: false,
    routes: [
      {
        name: "monitor",
        title: "Monitor",
        element: <Monitor />,
        path: "/",
        icon: MonitorIcon,
        roles: [Role.STREAMING_CAPTAIN],
        routes: [
          {
            name: "labelling",
            title: "Labelling",
            element: <StreamingLabelling />,
            path: "/:camera_id/labelling",
          },
        ],
      },
      {
        name: "videos",
        title: "Videos",
        element: <Videos />,
        path: "/video",
        icon: VideosIcon,
        roles: [Role.VIDEO_CAPTAIN],
        routes: [
          {
            name: "labelling",
            title: "Labelling",
            element: <VideoLabelling />,
            path: "/video/:video_id/labelling",
          },
        ],
      },
      {
        name: "images",
        title: "Images",
        element: <DatasetList />,
        path: "/image",
        icon: ImagesIcon,
        roles: [Role.IMAGE_CAPTAIN],
        routes: [
          {
            name: "gallery",
            title: "Gallery",
            element: <Gallery />,
            path: "/image/gallery",
          },
          {
            name: "labelling",
            title: "Labelling",
            element: <ImageLabelling />,
            path: "/image/:image_id/labelling",
          },
        ],
      },
      {
        name: "quality",
        title: "Quality Assurance",
        element: <Quality />,
        path: "/quality",
        icon: QualityIcon,
        roles: [
          Role.IMAGE_QUALITY_CONTROLLER,
          Role.STREAMING_QUALITY_CONTROLLER,
          Role.VIDEO_QUALITY_CONTROLLER
        ],
      },
      {
        name: "setting",
        title: "Settings",
        element: <Settings />,
        path: "/settings",
        icon: SettingsIcon,
        roles: [Role.ADMIN, Role.IMAGE_CAPTAIN, Role.IMAGE_QUALITY_CONTROLLER, Role.STREAMING_CAPTAIN, Role.STREAMING_QUALITY_CONTROLLER, Role.VIDEO_CAPTAIN, Role.VIDEO_QUALITY_CONTROLLER],
        routes:[
          {
            name: "create-camera",
            title: "Create Camera",
            element: <CreateCamera />,
            path: "/settings/camera/create",
          },
          {
            name: "update-camera",
            title: "Update Camera",
            element: <UpdateCamera />,
            path: "/settings/camera/update/:id",
          },
          {
            name: "create-client",
            title: "Create Client",
            element: <CreateClient />,
            path: "/settings/client/create",
          },
          {
            name: "update-client",
            title: "Update Client",
            element: <UpdateClient />,
            path: "/settings/client/update/:id",
          },
          {
            name: "create-category",
            title: "Create Category",
            element: <CreateCategory />,
            path: "/settings/category/create",
          },
          {
            name: "update-category",
            title: "Update Category",
            element: <UpdateCategory />,
            path: "/settings/category/update/:id",
          },
          {
            name: "create-captain",
            title: "Create Captain",
            element: <CreateCaptain />,
            path: "/settings/captain/create",
          },
          {
            name: "update-captain",
            title: "Update Captain",
            element: <UpdateCaptain />,
            path: "/settings/captain/update/:id",
          },
          {
            name: "create-video",
            title: "Create Video",
            element: <CreateVideo />,
            path: "/settings/video/create",
          },
          {
            name: "update-video",
            title: "Update Video",
            element: <UpdateVideo />,
            path: "/settings/video/update/:id",
          },
          {
            name: "create-image",
            title: "Create Image",
            element: <CreateImage />,
            path: "/settings/image/create",
          },
          {
            name: "update-image",
            title: "Update Image",
            element: <UpdateImage />,
            path: "/settings/image/update/:id",
          },
          {
            name: "create-work-slot",
            title: "Create Work Slot",
            element: <CreateSlot />,
            path: "/settings/work-slot/create",
          },
          {
            name: "update-work-slot",
            title: "Update Work Slot",
            element: <UpdateSlot />,
            path: "/settings/work-slot/update/:id",
          },
          {
            name: "create-instruction",
            title: "Create Instruction",
            element: <CreateInstruction />,
            path: "/settings/instruction/create",
          },
          {
            name: "update-instruction",
            title: "Update Instruction",
            element: <UpdateInstruction />,
            path: "/settings/instruction/update/:id",
          },
        ],
      },
      {
        name: "forbidden",
        title: "403 Forbidden",
        element: <Forbidden />,
        path: "/403", // Add this route for forbidden access
      },
    ],
  },
];

export const renderSidebars = (targetNames: string[]): Route[] => {
  const mainLayout = routes.find((layout) => layout.layout === MainLayout);
  const mainRoutes = mainLayout?.routes || [];
  return mainRoutes.filter((route) => targetNames.includes(route.name));
};

// Generate routes
export const Routes = renderRoutes(routes);