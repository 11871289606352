import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useInfiniteQuery, useQuery } from "react-query";
import { getRequest, postRequest, queries } from "../../react-query";
import { TriangleLeftIcon, TriangleRightIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import { ReactPlayer, Toast } from "../../components";
import dashjs from "dashjs"; // Import dash.js
import { STREAMING_TYPE } from "./streaming-enum";

interface Camera {
  id: string;
  name: string;
  preview_link: string;
  hls_link: string;
  dash_link: string;
  status: string;
}

interface selectProps {
  camera: Camera;
}

export const DashCameraViewer = ({ setStreamingType, streamingType }: any) => {
  const navigate = useNavigate();

  // Constants and State
  let size = 5;
  const loopInterval = 3000;
  const previewImageFetchInterval = 3000;
  const [page, setPage] = useState<number>(1);
  const [select, setSelect] = useState<any>({
    camera: "",
  });
  const [loopEnabled, setLoopEnabled] = useState<boolean>(false);
  const [activeCameras, setActiveCameras] = useState<Camera[]>([]);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const videoRef = useRef<any>(null);
  const canvasRef = useRef(null);
  const [segmentNumber, setSegmentNumber] = useState(null);

  const streaming = streamingType === STREAMING_TYPE.dash
                  ? STREAMING_TYPE.hls
                  : STREAMING_TYPE.dash

  // Data Fetching
  const {
    data = {},
    isLoading,
    error,
  }: any = useQuery(
    [queries.camera, page, size],
    () => getRequest(`/camera?status=ACTIVE&page=${page}&pageSize=${size}`),
    {
      enabled: !!page,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  // Destructuring Data
  const { data: cameras, pagination } = data;
  const totalPages = useMemo(
    () => Math.ceil(pagination?.totalCount / pagination?.pageSize),
    [pagination]
  );

  // Event Handlers
  const handleNextPage = useCallback(
    () => setPage((prevPage) => prevPage + 1),
    []
  );
  const handlePrevPage = useCallback(
    () => setPage((prevPage) => prevPage - 1),
    []
  );
  const onSelectChange = useCallback(
    (value: Camera, name: string) => setSelect({ ...select, [name]: value }),
    []
  );

  const handleButtonClick = useCallback(() => {
    setLoopEnabled((prev) => !prev);
  }, []);

  const sendSnapshotToServer = async (blob: any, segmentNumber: any) => {
    try {
      const snapshotFileName = `${Date.now()}_${select.camera.id}.jpeg`;
      const formData = new FormData();
      formData.append("file", blob, snapshotFileName);
      formData.append("camera_detail_id", select.camera.id);
      formData.append("is_streaming", "true");
      formData.append("segment_number", segmentNumber);

      const res = await postRequest({
        endPoint: "/pre-event",
        payload: formData,
      });

      if (res.id) {
        setIsOpen(true);
      }
    } catch (error) {
      console.error("Error uploading snapshot:", error);
    }
  };

  const recordPreview = useCallback(async () => {
    if (videoRef.current && canvasRef.current && dashjs) {
      const player = videoRef.current;
      const canvas: any = canvasRef.current;
      const time = player.currentTime;
      // Draw the current frame of the video on the canvas
      canvas.width = player.videoWidth;
      canvas.height = player.videoHeight;
      canvas
        .getContext("2d")
        .drawImage(player, 0, 0, canvas.width, canvas.height);

      // Get the snapshot data from the canvas as a Blob object
      const snapshotBlob = await new Promise((resolve) =>
        canvas.toBlob(resolve, "image/jpeg")
      );

      // Trigger the download of the snapshot Blob
      await sendSnapshotToServer(snapshotBlob, segmentNumber);
    }
  }, [segmentNumber]);

  useEffect(() => {
    if (select.camera && videoRef.current && dashjs) {
      const dashInstance = dashjs.MediaPlayer().create();

      // Initialize the player
      dashInstance.initialize(videoRef.current, select.camera.dash_link, true);

      // Track fragment loading completion
      dashInstance.on(
        dashjs.MediaPlayer.events.FRAGMENT_LOADING_COMPLETED,
        (e: any) => {
          setSegmentNumber(e.request.index);
          //console.log("Current segment index:", e.request.index);
        }
      );

      return () => {
        //off(dashjs.MediaPlayer.events.FRAGMENT_LOADING_COMPLETED);
        if (dashInstance) {
          //Remove all event listeners
          dashInstance.off(
            dashjs.MediaPlayer.events.FRAGMENT_LOADING_COMPLETED,
            () => {
              console.log("DASH media fragment off");
            }
          );
          // Reset and destroy the instance
          dashInstance.reset();
        }
      };
    }
  }, [select.camera, videoRef.current]);

  useEffect(() => {
    if (cameras) setActiveCameras(cameras);
  }, [cameras]);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveCameras(
        cameras?.map((camera: any) => ({
          ...camera,
          preview_link: `${camera.preview_link}?timestamp=${Date.now()}`,
        }))
      );
    }, previewImageFetchInterval);

    return () => clearInterval(interval);
  }, [cameras]);

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (loopEnabled && activeCameras) {
      intervalId = setInterval(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % activeCameras?.length);
      }, loopInterval);
    }
    return () => clearInterval(intervalId);
  }, [loopEnabled, activeCameras]);

  useEffect(() => {
    if (cameras) {
      setSelect({ ...select, ["camera"]: cameras[currentIndex] });
    }
  }, [cameras, currentIndex]);

  // Rendering
  return (
    <div className="w-full h-full">
      <div className="w-full px-8 py-4 flex justify-between items-center">
        <div className="flex space-x-2 items-center">
          <button disabled={page === 1} onClick={handlePrevPage}>
            <TriangleLeftIcon
              className={`w-10 h-10 cursor-pointer ${
                page === 1 ? "text-gray-500" : "text-white"
              }`}
            />
          </button>

          <div className="flex space-x-4 items-center text-white">
            {Array.isArray(cameras) &&
              activeCameras?.map((obj: any) => {
                return (
                  <div
                    onClick={() => onSelectChange(obj, "camera")}
                    key={obj.id}
                    className={`w-32 h-32 cursor-pointer relative border ${
                      select.camera.id === obj.id
                        ? "border-green"
                        : "border-header-border"
                    } rounded`}
                  >
                    <img
                      src={obj.preview_link}
                      alt="preview"
                      className="w-full h-full rounded"
                    />

                    <div className="w-full absolute bottom-0 px-1 flex justify-between items-center text-white">
                      <p>{obj.name}</p>
                      {obj.status === "ACTIVE" && (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          x="0px"
                          y="0px"
                          width="15"
                          height="15"
                          fill="red"
                          viewBox="0 0 50 50"
                        >
                          <path d="M 8.0136719 7.0292969 A 1.0001 1.0001 0 0 0 7.3222656 7.3222656 C 2.7995047 11.846068 -1.1842379e-15 18.102792 0 25 C 0 31.897208 2.7995047 38.153932 7.3222656 42.677734 A 1.0001 1.0001 0 0 0 8.7363281 42.677734 L 11.570312 39.84375 A 1.0001 1.0001 0 0 0 11.570312 38.429688 C 8.1286602 34.987084 6 30.242812 6 25 C 6 19.757188 8.1297921 15.012916 11.570312 11.570312 A 1.0001 1.0001 0 0 0 8.7363281 7.3222656 C 11.320529 5.7607891 14.136175 7.0292969 16 7.0292969 C 16.863825 7.0292969 19.679471 5.7607891 22.263672 7.3222656 A 1.0001 1.0001 0 0 0 22.677734 7.3222656 C 27.200495 3.7984699 31.184238 1.0 37 0 C 42.821348 -1.0 48 2.7790148 48 8 C 48 13.220985 42.821348 16 37 16 C 31.184238 16 27.200495 13.798470 22.677734 10.322266 A 1.0001 1.0001 0 0 0 22.263672 10.322266 C 19.679471 11.883742 16.863825 13.152251 16 13.152251 C 14.136175 13.152251 11.320529 11.883742 8.7363281 10.322266 A 1.0001 1.0001 0 0 0 8.0136719 7.0292969 z" />
                        </svg>
                      )}
                    </div>
                  </div>
                );
              })}
          </div>

          <button disabled={page === totalPages} onClick={handleNextPage}>
            <TriangleRightIcon
              className={`w-10 h-10 cursor-pointer ${
                page === totalPages ? "text-gray-500" : "text-white"
              }`}
            />
          </button>
        </div>

        <div className="flex h-full flex-col space-y-2">
          <button
            onClick={() =>
              setStreamingType(streaming)
            }
            className="bg-[#6E6767] p-1 rounded text-white capitalize"
          >
            Switch {streaming}
          </button>
          <button
            onClick={handleButtonClick}
            className={`p-1 rounded text-white ${
              loopEnabled ? "bg-blue-600" : "bg-[#6E6767]"
            }`}
          >
            {loopEnabled ? "Stop Looping" : "Start Looping"}
          </button>
          {select.camera && (
            <button
              onClick={() => navigate(`/${select.camera.id}/labelling`)}
              className="w-36 bg-blue-600 p-1 text-white"
            >
              Submit Label
            </button>
          )}
        </div>
      </div>
      {select.camera && (
        <div className="relative h-[calc(100vh-240px)] px-4">
          <ReactPlayer
            type="inbuilt"
            videoRef={videoRef}
            url={streaming ? select.camera.hash_link : select.camera.dash_link}
          />
          <canvas ref={canvasRef} style={{ display: "none" }}></canvas>
          <div className="fixed bottom-24 right-8">
            <Toast open={isOpen} setOpen={setIsOpen}>
              <button
                onClick={recordPreview}
                className="bg-red-500 active:bg-red-800 px-6 py-1.5 text-white rounded-full"
              >
                Record
              </button>
            </Toast>
          </div>
        </div>
      )}
    </div>
  );
};
