import React from "react";

interface TextInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: string;
  theme?: "black" | "white"; // Consider restricting theme values
}

export const TextInput: React.FC<TextInputProps> = ({
  error,
  theme = "black", // Set a default theme
  children,
  ...props
}) => {
  return (
    <div
      className={`w-full flex flex-col justify-start space-y-1 ${
        theme === "black" ? "text-black" : "text-white"
      }`}
    >
      {children}

       {/* Wrap input in a relative div for positioning */}
        <input
          type={props.type || "text"} // Allow dynamic type
          className={`focus:outline-none border rounded-lg h-10 px-3 ${
            theme === "black"
              ? `${props.disabled ? "bg-[#f2f2f2]" : "bg-transparent"} border-main`
              : "text-white bg-transparent border-white"
          } ${error ? "border-red-600" : ""}`} // Highlight border when error exists
          {...props}
        />
     

      <div className="h-4"> {/* Fixed height for error message space */}
        {error && <div className="text-xs text-red-600">{error}</div>}
      </div>
    </div>
  );
};