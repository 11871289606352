import React from "react";

export const Remark = ({ formData, onChangeHandler }: any) => {
  return (
    <div className="relative space-y-4 w-full max-w-md h-full md:h-auto text-black text-center mt-8">
      {/* <p className="">Are you sure you want to delete this Segment?</p> */}

      <fieldset className="Fieldset flex space-x-4 items-center justify-center">
        {/* <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth="1.5"
          stroke="currentColor"
          className="size-6 text-green"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 0 0 2.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 0 1-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 0 0-1.091-.852H4.5A2.25 2.25 0 0 0 2.25 4.5v2.25Z"
          />
        </svg> */}
        <textarea
          className="w-full px-2 border border-gray focus:outline-none rounded"
          placeholder="Remarks"
          value={formData.remark}
          onChange={(e) => onChangeHandler(e.target.value, "remark")}
        />
      </fieldset>
    </div>
  );
};
