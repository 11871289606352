import flattenDeep from "lodash/flattenDeep";
import { Route, Routes as ReactRoutes } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import ProtectedRolesRoute from "./ProtectedRolesRoute";

const generateFlattenRoutes = (routes: any) => {
  if (!routes) return [];
  return flattenDeep(
    routes &&
      routes?.map(({ routes: subRoutes, ...rest }: any) => [
        rest,
        generateFlattenRoutes(subRoutes),
      ])
  );
};

export const renderRoutes = (mainRoutes: any) => {
  const Routes = ({
    isAuthorized,

  }: {
    isAuthorized: boolean;
  
  }) => {
    const layouts = mainRoutes?.map(
      ({ layout: Layout, routes, isPublic }: any, index: number) => (
        <Route key={index} element={<Layout />}>
          <Route
            element={
              <ProtectedRoute isPublic={isPublic} isAuthorized={isAuthorized} />
            }
          >
            {generateFlattenRoutes(routes)?.map(
              ({ element: Element, path, name, roles }: any) => {
               
                if (Element && path && Array.isArray(roles)) {
                  return (
                    <Route
                      key={name}
                      element={
                        <ProtectedRolesRoute
                          roles={roles}
                        >
                          {Element}
                        </ProtectedRolesRoute>
                      }
                      path={path}
                    />
                  );
                }
                return Element && path && <Route key={name} element={Element} path={path} />;
              }
            )}
          </Route>
        </Route>
      )
    );
    return <ReactRoutes>{layouts}</ReactRoutes>;
  };
  return Routes;
};
