import React, { useCallback, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  getRequest,
  optimisticOptions,
  postRequest,
  queries,
} from "../../react-query";
import { reverseArray } from "../../utils";
import { useParams } from "react-router-dom";
import { LabelStudioUI } from "../../components/bound-box/LabelStudioUI";
import { defaultConfig } from "../../components/bound-box/config";
import { DynamicLabel } from "../../types";

export const VideoLabelling = () => {
  // Constants and State
  let size = 1;
  const [page, setPage] = useState<number>(1);
  const params = useParams();
  const [dynamicLabels, setDynamicLabels] = useState<DynamicLabel[]>([]);
  
  // Data Fetching
  const { data: videoData = {}, isLoading: isVideoLoading }: any = useQuery(
    [queries.videos, page, size],
    () => getRequest(`/video/${params.video_id}`),
    {
      enabled: !!params.video_id,
    }
  );
  const { data: video } = videoData;

  const {
    data: preEventFetchingData = [],
    isLoading,
    error,
  }: any = useQuery(
    [queries.preEvent, page, size],
    () =>
      getRequest(
        `/pre-event?labelled=false&signed=true&is_streaming=false&page=${page}&pageSize=${size}`
      ),
    {
      enabled: !!page,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  // Destructuring Data
  const { data: preEvents = [], pagination = {} } = preEventFetchingData;

  const { data: eventResponse, mutate: createEventMutate } = useMutation(
    postRequest,
    optimisticOptions(queries.preEvent)
  );

  // Event Handlers
  const handleNextPage = useCallback(
    () => setPage((prevPage) => prevPage + 1),
    []
  );

  const handlePrevPage = useCallback(
    () => setPage((prevPage) => prevPage - 1),
    []
  );

  //submit
  const submit = async (annotations: any) => {
    try {
      page > 1 && handlePrevPage();
      createEventMutate({
        endPoint: "/event",
        payload: {
          annotations: annotations,
          pre_event_capture_id: preEvents[0].id,
          status: "LABELLED",
        },
      });
    } catch (error) {
      console.error("Error uploading snapshot:", error);
    }
  };

  const currentEvent = useMemo(() => preEvents[0], [preEvents]);

  if (isVideoLoading || isLoading) {
    return <div />;
  }

  return (
    <div
      className="h-full flex flex-col justify-between"
      style={{ position: "relative" }}
    >
      {currentEvent ? (
        <LabelStudioUI
        taskId={currentEvent.id}
        instructionLink={currentEvent.instruction_link}
        imageLocation={currentEvent.image_link}
        handleNextPage={handleNextPage}
        handlePrevPage={handlePrevPage}
        submit={submit}
        page={page}
        pagination={pagination}
        categories={reverseArray(video?.sub_categories)}
        dynamicLabels={dynamicLabels}
        setDynamicLabels={setDynamicLabels}
      />
      ) : (
        <div className="w-full h-full grid place-content-center text-white">
          No image found!
        </div>
      )}
    </div>
  );
};
