import React, { useMemo, useState } from "react";
import { useInfiniteQuery } from "react-query";
import { getRequest, queries } from "../../react-query";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation } from "react-router-dom";

export function Gallery() {
  let LIMIT = 20; // Number of items to fetch per page
  const { state } = useLocation();

  const { data, fetchNextPage, hasNextPage, isFetching, status, error }: any =
    useInfiniteQuery(
      [queries.images, state.datasetId],
      ({ pageParam = 1 }) =>
        getRequest(
          `/images?datasetId=${state.datasetId}&labelled=false&page=${pageParam}&pageSize=${LIMIT}`
        ),
      {
        getNextPageParam: (lastPage, allPages) =>
          lastPage.length ? allPages.length + 1 : undefined,
        enabled: !!state.datasetId,
      }
    );

  const images = useMemo(() => {
    if (!data) return [];
     return data.pages?.reduce((acc: any, page: any) => [...acc, ...page.data], []);
  }, [data]);

  

 
  if (status === 'loading') return <div>Loading...</div>;
  if (status === 'error') return <div>Error: {error.message}</div>;
 

  return (
    <div
      id="scrollableDiv"
      className="w-full"
      style={{
        height: "calc(100vh - 60px)",
        overflow: "auto",
        scrollbarWidth: "none",
      }}
    >
      <InfiniteScroll
        dataLength={images.length}
        next={fetchNextPage}
        hasMore={hasNextPage}
        loader={<h4 className="text-white pt-1">Loading...</h4>}
        endMessage={
          <p className="text-white text-center pt-1">
            Yay! You have seen it all
          </p>
        }
        scrollThreshold={0.5}
        scrollableTarget="scrollableDiv"
      >
        {/* <div className="grid  grid-cols-7 gap-2"> */}
          {images.map((image: any) => {
            return (
              <div key={image.id}>
                <img
                  className="w-40 h-40 rounded-lg"
                  src={image.access_url}
                  alt="gallery-photo"
                />
              </div>
            );
          })}
        {/* </div> */}
      </InfiniteScroll>
    </div>
  );
}
