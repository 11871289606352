import React from "react";

export const Forbidden: React.FC = () => {
  return (
    <div className="w-full h-full grid place-content-center">
      <h1 className="text-4xl font-bold text-white">403 - Forbidden</h1>
      <p className="text-lg text-white">You are not authorized to access this page.</p>
    </div>
  );
};


