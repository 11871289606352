import { useEffect, useState } from "react";
import { validateField, validateForm } from "../../utils";
import { TextInput } from "..";
import hide from "../../assets/images/hide.svg";
import unhide from "../../assets/images/unhide.svg";
import { useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { postRequest } from "../../react-query";
import { useStoreActions } from "../../store/easy-peasy/hooks";
import errorToast from "../../utils/errorToast";
import successToast from "../../utils/successToast";

const validationRules = {
  email: [
    { required: true, message: "Please enter your email" },
    { pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: "Invalid email format" },
  ],
  password: [
    { required: true },
    {
      pattern: /^(?=.*\d)(?=.*[a-z]).{6,}$/,
      message:
        "Password must be at least 6 characters and include at least one uppercase letter, one lowercase letter, and one number",
    },
  ],
};

export function SigninForm({ setAuthPage }: any) {
  const { data: signinResponse, mutate: signin } = useMutation(postRequest);
  const [isLoading, setIsLoading] = useState(false);

  const addUser = useStoreActions((store) => store.addUser);
  const setAuthenticated = useStoreActions((store) => store.setAuthenticated);

  const naviagte = useNavigate();

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const [isHide, setIsHide] = useState(false);

  const [errors, setErrors] = useState<Record<string, string>>({});

  const onChangeHandler = (value: string | boolean, name: string) => {
    setFormData({ ...formData, [name]: value });
    // Validate field on change
    const fieldErrors = validateField(name, value, validationRules);

    setErrors((prevErrors) => ({
      ...prevErrors,
      [name]: fieldErrors.length > 0 ? fieldErrors[0] : "",
    }));
  };

  const onSubmitHandler = async (e: any) => {
    e.preventDefault();
    try {
      const validationErrors = validateForm(formData, validationRules);
      if (Object.keys(validationErrors).length === 0) {
        signin({
          endPoint: `/auth/signin`,
          payload: {
            email: formData.email,
            password: formData.password,
          },
        });
      } else {
        setErrors(validationErrors);
      }
    } catch (error) {
      setErrors({});
    }
  };

  useEffect(() => {
    let timeoutId: any;

    if (signinResponse && signinResponse.token) {
      setIsLoading(true);
      addUser({
        token: signinResponse.token,
        setting: "profile",
        roles: signinResponse.roles,
        user: {
          email: signinResponse.email,
          name: signinResponse.username,
        },
      });
      successToast("signed in successfully");
      timeoutId = setTimeout(() => {
        setAuthenticated(true);
        setIsLoading(false);
      }, 8000);
    }

    return () => {
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
    };
  }, [signinResponse, addUser, naviagte]);

  return (
    <form className="h-w-full w-full space-y-24" onSubmit={onSubmitHandler}>
      <div className="space-y-5">
        <TextInput
          type="email"
          value={formData.email}
          onChange={(e) => onChangeHandler(e.target.value, "email")}
          required
          theme="black"
          error={errors.email}
        >
          <label htmlFor="Email">Email</label>
        </TextInput>

        <TextInput
          type={isHide ? "password" : "text"}
          value={formData.password}
          onChange={(e) => onChangeHandler(e.target.value, "password")}
          required
          theme="black"
          error={errors.password}
        >
          <div className="flex justify-between items-center">
            <label className="" htmlFor="Password">
              Password
            </label>
            <button
              type="button" // Prevents form submission on click
              onClick={() => setIsHide(!isHide)}
              className="inline-flex items-center space-x-1"
            >
              {isHide ? (
                <img className="w-4 h-4" src={hide} alt="view" />
              ) : (
                <img className="w-4 h-4" src={unhide} alt="view" />
              )}
              <span>{isHide ? "Hide" : "View"}</span>
            </button>
          </div>
        </TextInput>
      </div>
      <button
        className="w-full rounded-full bg-secondary text-white py-2"
        type="submit"
      >
        {isLoading ? "Please Wait" : "Login"}
      </button>
    </form>
  );
}
