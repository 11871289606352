import React, { useCallback, useMemo, useState } from "react";
import { DataGrid, List, ScrollArea } from "../../../../components";
import { getRequest, queries } from "../../../../react-query";
import { useQuery } from "react-query";
import ReactPaginate from "react-paginate";
import { TriangleLeftIcon, TriangleRightIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import { GlobalFilters, GlobalSearch } from "../../../../components/filters";
import { copyLinkHandler, openLinkInNewTab } from "../../utils";

export const InstructionList = () => {
  let size = 10;
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);

  const [input, setInput] = useState({
    filter: "ACTIVE",
    search: "",
  });

  const {
    data: instructionsData = [],
    isLoading,
    error,
  }: any = useQuery(
    [queries.instructions, input.filter, page, size],
    () =>
      getRequest(
        `/instruction?status=${input.filter}&page=${page}&pageSize=${size}`
      ),
    {
      enabled: !!page || !!input.filter,
    }
  );

  // Destructuring Data
  const { data: instructions = [], pagination } = instructionsData;

  const totalPages = useMemo(
    () => Math.ceil(pagination?.totalCount / pagination?.pageSize),
    [pagination]
  );

  const onPageChangeHandler = useCallback((page: number) => {
    setPage(page + 1);
  }, []);

  

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        disableFilters: true,
      },
      {
        Header: "Copy Id",
        accessor: "copy_id",
        disableFilters: true,
        Cell: ({ row }: any) => (
          <button
            onClick={(e) =>
              copyLinkHandler(e, row.original.id, `${row.original.id} copied to clipboard!`)
            }
            className="flex justify-center items-center"
          >
            <p>{row.original.id}</p>
          </button>
        ),
      },
      {
        Header: "Copy PDF Link",
        accessor: "copy_link",
        disableFilters: true,
        Cell: ({ row }: any) => (
          <button
            onClick={(e) => openLinkInNewTab(row.original.pdf_link, e)}
            className=" text-blue-600 flex justify-center items-center pl-10"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244"
              />
            </svg>
          </button>
        ),
      },
    ],
    [instructions]
  );

  const onValueChangeHandler = (value: string, name: string) => {
    if (value) {
      setInput({
        ...input,
        [name]: value,
      });
    }
  };

  const onRowHandler = (data: any) => {
    navigate(`/settings/instruction/update/${data.id}`, {
      state: {
        data: data,
      },
    });
  };

  const goToPage = () => {
    navigate("/settings/instruction/create");
  };

  return (
    <div className="text-black space-y-4 py-4">
      <div className="flex justify-between items-center">
        <GlobalFilters
          filter={input["filter"]}
          setFilter={(value: string) => onValueChangeHandler(value, "filter")}
        />
        <div className="flex items-center space-x-4">
          <GlobalSearch
            value={input["search"]}
            setValue={(e: any) =>
              onValueChangeHandler(e.target.value, "search")
            }
          />
          <button
            onClick={goToPage}
            className="bg-green active:bg-[#228B22] px-4 py-1.5 text-white rounded-full"
          >
            Create
          </button>
        </div>
      </div>

      <ScrollArea className="w-full  h-[calc(100vh-260px)]">
        <DataGrid
          data={instructions}
          columns={columns}
          onRowHandler={onRowHandler}
        />
      </ScrollArea>

      <ReactPaginate
        containerClassName={"paginationContainer"}
        pageClassName={"paginationItem"}
        activeClassName={"paginationActive"}
        onPageChange={(event) => onPageChangeHandler(event.selected)}
        pageCount={totalPages | 1}
        breakLabel="..."
        previousLabel={
          <TriangleLeftIcon className={`w-10 h-10 cursor-pointer`} />
        }
        nextLabel={<TriangleRightIcon className={`w-10 h-10 cursor-pointer`} />}
      />
    </div>
  );
};
