import React, { useCallback, memo } from "react";
import * as Dialog_ from "@radix-ui/react-dialog";
import { Remark } from "./Remark";
import { Confirm } from "./Confirm";

interface WrapperDialogProps {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: (e: React.FormEvent) => void;
  formData: {
    type: string;
    method: string;
    remark: string;
  };
  onChangeHandler: (value: string, name: string) => void;
}

const WrapperDialogContent = memo(
  ({
    isOpen,
    onClose,
    onConfirm,
    formData,
    onChangeHandler,
  }: WrapperDialogProps) => {
    const renderUI = useCallback(() => {
      switch (formData.type) {
        case "remark":
          return (
            <Remark formData={formData} onChangeHandler={onChangeHandler} />
          );
        case "confirm":
          return <Confirm />;
        default:
          return <div />;
      }
    }, [formData, onChangeHandler]);

    const handleConfirm = useCallback(
      (e: React.FormEvent) => {
        e.preventDefault();
        onConfirm(e);
      },
      [onConfirm]
    );

    return (
      <Dialog_.Root open={isOpen}>
        <Dialog_.Portal>
          <Dialog_.Overlay
            onClick={onClose}
            className="bg-white opacity-50 data-[state=open]:animate-overlayShow fixed inset-0"
          />
          <Dialog_.Content className="data-[state=open]:animate-contentShow fixed top-[50%] left-[50%] max-h-[85vh] w-[90vw] max-w-[450px] translate-x-[-50%] translate-y-[-50%] rounded-[6px] bg-white p-[25px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
            <Dialog_.Title className="text-lg font-bold"></Dialog_.Title>

            {renderUI()}

            <div className="mt-[35px] flex justify-end space-x-4">
              <button
                onClick={onClose}
                data-modal-toggle="deleteModal"
                type="button"
                className="py-2 px-3 text-sm font-medium text-white bg-white rounded-lg border border-gray-200 hover:bg-gray-100 dark:bg-gray-700 dark:border-gray-500 dark:hover:bg-gray-600"
              >
                No, cancel
              </button>

              <button
                onClick={handleConfirm}
                className="py-2 px-3 text-sm font-medium text-white bg-white rounded-lg border border-green hover:bg-green dark:bg-green dark:text-white dark:border-green dark:hover:bg-green"
              >
                Submit
              </button>
            </div>
          </Dialog_.Content>
        </Dialog_.Portal>
      </Dialog_.Root>
    );
  }
);

export const WrapperDialog = memo(WrapperDialogContent);
