import { ToggleGroup } from "../../components";
import {
  CamerasList,
  VideosList,
  ImagesList,
  VSettings,
  Profile,
  CaptainsList,
  CategoryList,
  ClientList,
  InstructionList,
} from "./tabs";

import { useStoreActions, useStoreState } from "../../store/easy-peasy/hooks";
import { SlotList } from "./tabs/slots";
import { Role } from "../../utils/enum";

interface Tab {
  id: string;
  name: string;
  value: string;
  roles: Role[];
}

const tabs: Tab[] = [
  {
    id: "1",
    name: "Cameras",
    value: "cameras",
    roles: [Role.ADMIN],
  },
  {
    id: "2",
    name: "Videos",
    value: "videos",
    roles: [Role.ADMIN],
  },
  {
    id: "3",
    name: "Datasets",
    value: "images",
    roles: [Role.ADMIN],
  },
  {
    id: "4",
    name: "Clients",
    value: "clients",
    roles: [Role.ADMIN],
  },
  {
    id: "5",
    name: "Categories",
    value: "categories",
    roles: [Role.ADMIN],
  },
  {
    id: "6",
    name: "Members",
    value: "members",
    roles: [Role.ADMIN],
  },
  {
    id: "7",
    name: "Slots",
    value: "slots",
    roles: [Role.ADMIN],
  },
  {
    id: "8",
    name: "Instructions",
    value: "instructions",
    roles: [Role.ADMIN],
  },
  {
    id: "9",
    name: "Profile",
    value: "profile",
    roles: [
      Role.ADMIN,
      Role.IMAGE_CAPTAIN,
      Role.IMAGE_QUALITY_CONTROLLER,
      Role.STREAMING_CAPTAIN,
      Role.STREAMING_QUALITY_CONTROLLER,
      Role.VIDEO_CAPTAIN,
      Role.VIDEO_QUALITY_CONTROLLER,
    ],
  },
];

export const Settings = () => {
  const userRoles = useStoreState((store) => store.roles);
  const setting = useStoreState((store) => store.setting);
  const updateSetting = useStoreActions((store) => store.updateSetting);

  const redirectPage = () => {
    switch (setting) {
      case "cameras":
        return <CamerasList />;
      case "videos":
        return <VideosList />;
      case "images":
        return <ImagesList />;
      case "members":
        return <CaptainsList />;
      case "settings":
        return <VSettings />;
      case "profile":
        return <Profile />;
      case "clients":
        return <ClientList />;
      case "categories":
        return <CategoryList />;
      case "slots":
        return <SlotList />;
      case "instructions":
        return <InstructionList/>
      default:
        return <CamerasList />;
    }
  };

  const onValueChangeHandler = (value: string, name: string) => {
    if (value) {
      updateSetting(value);
    }
  };



  return (
    <div className="bg-white h-full w-full px-2 py-2 border-2">
      <div className="flex justify-between items-center">
        <ToggleGroup
          innerClass="h-8 flex items-center items-center border border-main divide-x divide-main"
          outerClass="h-full  data-[state=on]:bg-green data-[state=on]:text-white px-2 py-1"
          data={tabs.filter(({ roles }:{roles:Role[]}) =>
            userRoles.some((role) => roles.includes(role))
          )}
          defaultValue={setting}
          onValueChange={(value: string) => onValueChangeHandler(value, "tab")}
        />
      </div>
      <div className="mt-4">{redirectPage()}</div>
    </div>
  );
};
