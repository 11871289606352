import React, { useCallback, useMemo, useState } from "react";
import { DataGrid, List, ScrollArea } from "../../../../components";
import { getRequest, queries } from "../../../../react-query";
import { useQuery } from "react-query";
import ReactPaginate from "react-paginate";
import { TriangleLeftIcon, TriangleRightIcon } from "@radix-ui/react-icons";
import { GlobalFilters, GlobalSearch } from "../../../../components/filters";

import { useNavigate } from "react-router-dom";
import { reverseArray } from "../../../../utils";
import { copyLinkHandler, openLinkInNewTab } from "../../utils";

export const CamerasList = () => {
  const navigate = useNavigate();

  let size = 10;
  const [page, setPage] = useState<number>(1);

  const [input, setInput] = useState({
    filter: "ACTIVE",
    search: "",
  });

  const {
    data: camerasData = [],
    isLoading,
    error,
  }: any = useQuery(
    [queries.camera, input.filter, page, size],
    () =>
      getRequest(
        `/camera?status=${input.filter}&page=${page}&pageSize=${size}`
      ),
    {
      enabled: !!page || !!input.filter,
    }
  );

  // Destructuring Data
  const { data: cameras = [], pagination } = camerasData;

  const totalPages = useMemo(
    () => Math.ceil(pagination?.totalCount / pagination?.pageSize),
    [pagination]
  );

  const onPageChangeHandler = useCallback((page: number) => {
    setPage(page + 1);
  }, []);

  const columns = useMemo(
    () => [
      { Header: "Name", accessor: "name", disableFilters: true },
      // {
      //   minWidth: 350,
      //   Header: "RTMP Link",
      //   accessor: "rtmp_link",
      //   disableFilters: true,
      // },
      {
        Header: "Client",
        accessor: (row: any) =>
          `${row?.admin_client?.company_name} (${row?.admin_client?.full_name})`,
        disableFilters: true,
      },
      {
        minWidth: 200,
        Header: "Video Trim Time",
        accessor: "video_trim_time",
        disableFilters: true,
      },
      {
        Header: "Location",
        accessor: "location.name",
        disableFilters: true,
      },
      {
        Header: "Categories",
        accessor: (row: any) => {
          const formatted = reverseArray(row.sub_categories)?.map(
            (category: any) => {
              const subCategoryNames = category.sub_categories
                ?.map((sub: any) => sub.name)
                .join(", ");
              return `${category.name} - ${subCategoryNames}`;
            }
          );
          return JSON.stringify(formatted);
        },
        disableFilters: true,
      },

      {
        Header: "Copy Config",
        accessor: "copy_link",
        disableFilters: true,
        Cell: ({ row }: any) => (
          <button
            onClick={(e) =>
              copyLinkHandler(
                e,
                JSON.stringify({
                  sensor_id: row.original.id,
                  rtmp_link: `${row.original.rtmp_link}?username:password`,
                }),
                `Configuration copied to clipboard!`
              )
            }
            className=" text-blue-600 flex justify-center items-center pl-10"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M13.19 8.688a4.5 4.5 0 0 1 1.242 7.244l-4.5 4.5a4.5 4.5 0 0 1-6.364-6.364l1.757-1.757m13.35-.622 1.757-1.757a4.5 4.5 0 0 0-6.364-6.364l-4.5 4.5a4.5 4.5 0 0 0 1.242 7.244"
              />
            </svg>
          </button>
        ),
      },
    ],
    [cameras]
  );

  const onValueChangeHandler = (value: string, name: string) => {
    if (value) {
      setInput({
        ...input,
        [name]: value,
      });
    }
  };

  const onRowHandler = (data: any) => {
    navigate(`/settings/camera/update/${data.id}`, {
      state: {
        data: data,
      },
    });
  };

  const goToPage = () => {
    navigate("/settings/camera/create");
  };

  return (
    <div className="text-black space-y-4 py-4">
      <div className="flex justify-between items-center">
        <GlobalFilters
          filter={input["filter"]}
          setFilter={(value: string) => onValueChangeHandler(value, "filter")}
        />
        <div className="flex items-center space-x-4">
          <GlobalSearch
            value={input["search"]}
            setValue={(e: any) =>
              onValueChangeHandler(e.target.value, "search")
            }
          />
          <button
            onClick={goToPage}
            className="bg-green active:bg-[#228B22] px-4 py-1.5 text-white rounded-full"
          >
            Create
          </button>
        </div>
      </div>
      <ScrollArea className="w-full  h-[calc(100vh-260px)]">
        <DataGrid
          data={cameras}
          columns={columns}
          onRowHandler={onRowHandler}
        />
      </ScrollArea>
      <div>
        <ReactPaginate
          containerClassName={"paginationContainer"}
          pageClassName={"paginationItem"}
          activeClassName={"paginationActive"}
          onPageChange={(event) => onPageChangeHandler(event.selected)}
          pageCount={totalPages | 1}
          breakLabel="..."
          previousLabel={
            <TriangleLeftIcon className={`w-10 h-10 cursor-pointer`} />
          }
          nextLabel={
            <TriangleRightIcon className={`w-10 h-10 cursor-pointer`} />
          }
        />
      </div>
    </div>
  );
};
