import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  getRequest,
  queries,
  optimisticOptions,
  deleteRequest,
  putRequest,
} from "../../react-query";
import { TriangleLeftIcon, TriangleRightIcon } from "@radix-ui/react-icons";
import { WrapperDialog } from "../../components/dialog";
import { ReLabelStudioUI } from "../../components/bound-box/ReLabelStudioUI";
import { reverseArray } from "../../utils";
import { AnnotationData, DynamicLabel } from "../../types";

const PAGE_SIZE = 1;

export const Quality = () => {
  const [page, setPage] = useState<number>(1);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [formData, setFormData] = useState({
    remark: "Unsafe violation detected",
    type: "",
    method: "",
  });
  const [annotations, setAnnotations] = useState<AnnotationData[]>([]);
  const [dynamicLabels, setDynamicLabels] = useState<DynamicLabel[]>([]);

  const { data: eventFetchingData = {}, isLoading } = useQuery(
    [queries.event, page, PAGE_SIZE],
    () =>
      getRequest(
        `/event?status=LABELLED&signed=true&page=${page}&pageSize=${PAGE_SIZE}`
      ),
    {
      enabled: !!page,
      onSuccess: ({ data: events }) => {
        if (events.length > 0) {
          // Create a Map to store unique labels using the label text as the key
          const uniqueLabelsMap = new Map();

          events[0]?.annotations?.forEach((annot: any) => {
            const label = annot.value.rectanglelabels[0];
            // Only add to map if the label doesn't exist yet
            if (!uniqueLabelsMap.has(label)) {
              uniqueLabelsMap.set(label, {
                label: label,
                background: annot.color,
              });
            }
          });

          // Convert Map values to array
          const uniqueLabels = Array.from(uniqueLabelsMap.values());

          setDynamicLabels(uniqueLabels);
          setAnnotations([{ result: events[0]?.annotations }]);
        }
      },
    }
  );

  const { data: events = [], pagination = {} } = eventFetchingData;
  const totalPages = useMemo(
    () => Math.ceil(pagination.totalCount / pagination.pageSize),
    [pagination]
  );

  const { mutate: updateEvents } = useMutation(
    putRequest,
    optimisticOptions(queries.event)
  );
  const { mutate: deleteEvents } = useMutation(
    deleteRequest,
    optimisticOptions(queries.event)
  );

  // Event Handlers
  const handleNextPage = useCallback(
    () => setPage((prevPage) => prevPage + 1),
    []
  );

  const handlePrevPage = useCallback(
    () => setPage((prevPage) => prevPage - 1),
    []
  );

  const handleRelabel = useCallback(
    (event: any) => {
      deleteEvents({ endPoint: `/event/${event.id}` });
    },
    [deleteEvents]
  );

  const handleReject = useCallback(
    (id: string) => {
      updateEvents({
        endPoint: `/event/${id}`,
        payload: { status: "REJECTED", remark: formData.remark },
      });
    },
    [formData, updateEvents]
  );

  const handleSubmit = useCallback(
    (id: string) => {
      updateEvents({
        endPoint: `/event/${id}`,
        payload: {
          annotations: annotations[0]?.result,
          status: "COMPLETED",
          remark: formData.remark,
          is_fixed_by_quality_member: true,
        },
      });
    },
    [formData, updateEvents]
  );

  const handleAction = useCallback(
    (method: string, type: string) => {
      setFormData((prev) => ({ ...prev, method, type }));
      setIsOpen(true);
    },
    [setFormData, setIsOpen]
  );

  const currentEvent = useMemo(() => events[0], [events]);
  const isDataReady = useMemo(
    () => currentEvent && dynamicLabels.length > 0 && annotations.length > 0,
    [currentEvent, dynamicLabels, annotations]
  );

  if (isLoading) return <div className="text-white">Loading...</div>;

  return (
    <div className="w-full py-2 h-full">
      {isDataReady ? (
        <>
          <div className="flex items-center">
            <NavigationButton
              disabled={page === 1}
              onClick={handlePrevPage}
              icon={
                <TriangleLeftIcon
                  className={`w-10 h-10 flex items-center justify-center ${
                    page === 1 ? "text-gray-500" : "text-white cursor-pointer"
                  }`}
                />
              }
            />

            {currentEvent && currentEvent.pre_event_capture && currentEvent.pre_event_capture.image_link && (<ReLabelStudioUI
              instructionLink={currentEvent.instruction_link}
              taskId={currentEvent.id}
              imageLocation={currentEvent.pre_event_capture.image_link}
              dynamicLabels={dynamicLabels}
              setDynamicLabels={setDynamicLabels}
              annotations={annotations}
              setAnnotations={setAnnotations}
              categories={reverseArray(currentEvent.sub_categories)}
              handleAction={handleAction}
            />)}

            <NavigationButton
              disabled={page === totalPages}
              onClick={handleNextPage}
              icon={
                <TriangleRightIcon
                  className={`w-10 h-10 flex items-center justify-center ${
                    page === totalPages
                      ? "text-gray-500"
                      : "text-white cursor-pointer"
                  }`}
                />
              }
            />
          </div>

          <WrapperDialog
            formData={formData}
            onChangeHandler={(value, name) =>
              setFormData((prev) => ({ ...prev, [name]: value }))
            }
            isOpen={isOpen}
            onClose={() => setIsOpen(false)}
            onConfirm={() => {
              switch (formData.method) {
                case "relabel":
                  handleRelabel(currentEvent);
                  break;
                case "reject":
                  handleReject(currentEvent.id);
                  break;
                case "submit":
                  handleSubmit(currentEvent.id);
                  break;
              }
              setIsOpen(false);
            }}
          />
        </>
      ) : (
        <div className="h-full grid place-content-center text-white">
          <p>No labelled Image found!</p>
        </div>
      )}
    </div>
  );
};

const NavigationButton = ({ disabled, onClick, icon }: any) => (
  <button disabled={disabled} onClick={onClick}>
    {icon}
  </button>
);
