import { useState } from "react";
import { Outlet } from "react-router-dom";
import { MainHeader, Sidebar } from "../components";
import { useStoreActions, useStoreState } from "../store/easy-peasy/hooks";

export const MainLayout = () => {
  let isSideMenuOpen = useStoreState((store) => store.isSideMenuOpen);
  let toggleSideMenu = useStoreActions((store) => store.toggleSideMenu);

  return (
    <div className="w-full h-screen">
      <MainHeader onToggle={() => toggleSideMenu(!isSideMenuOpen)} />
      <div className="w-full flex h-[calc(100vh-60px)]">
        <Sidebar isOpen={isSideMenuOpen} />
        <div className={`${isSideMenuOpen ? "w-[calc(100vw-6rem)]":"w-[100vw]"} h-full bg-secondary`}>
          <Outlet />
        </div>
      </div>
    </div>
  );
};
