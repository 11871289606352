import React, { useCallback, useState } from "react";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import {
  MultiSelect,
  TextInput,
  Image,
  ScrollArea,
  NormalSelect,
} from "../../../../components";
import {
  getRequest,
  optimisticOptions,
  postRequest,
  queries,
} from "../../../../react-query";
import { useMutation, useQuery } from "react-query";
import { roles } from "../../utils";
import { validateForm } from "../../../../utils";
import errorToast from "../../../../utils/errorToast";



export const CreateCaptain = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    email: "",
    country: {
      label: "",
      value: "",
    },
    roles: [],
    supervisor: "",
    password: "",
    remark: "",
    image: "",
    slot: {
      label: "",
      value: "",
    }
  });

  const [errors, setErrors] = useState<Record<string, string>>({});

  const {
    data: countriesData = {}
  }: any = useQuery([queries.country], () => getRequest(`/country`));

  const { data: countries = [] } = countriesData;

  const {
    data: slotsData = {}
  }: any = useQuery([queries.slots], () => getRequest(`/work-slot`));

  const { data: slots = [] } = slotsData;


  const onChangeHandler = (value: any, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const { mutate: createCaptain } = useMutation(
    postRequest,
    optimisticOptions(queries.users)
  );

  const validationRules = {
    name: [{ required: true, message: "Name is required." }],
    contact: [{ required: true, message: "Contact is required." }],
    email: [
      { required: true, message: "Email is required." },
      { pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/, message: "Invalid email format." },
    ],
    country: [{ required: true, message: "Country selection is required." }],
    roles: [{ required: true, message: "At least one role is required." }],
    password: [{ required: true, message: "Password is required." }],
    remark: [{ required: true, message: "Remark is required." }],
    slot: [{ required: true, message: "Slot selection is required." }],
  };

  const onSubmitHandler = useCallback(
    (e: any) => {
      e.preventDefault();

      // Validate form data
      const formErrors = validateForm(formData, validationRules);

      setErrors(formErrors);

      // If no validation errors, proceed with submission
      if (Object.keys(formErrors).length === 0) {
        const { name, email, contact, slot, roles, password, country, image, remark } =
          formData;
        console.log("formData", formData)
        let form = new FormData();
        form.append("username", name);
        form.append("email", email);
        form.append("password", password);
        form.append("contact", contact);
        form.append("remark", remark);
        form.append("country_id", country.value);
        form.append("file", image);
        form.append("work_slot_id", slot.value)
        roles?.forEach((role: any) => {
          form.append("roles[]", role.value);
        });

        createCaptain({
          endPoint: "/users",
          payload: form,
        });
        navigate(-1);
      }else{
        errorToast('please check all fields')
      }
    },
    [formData]
  );

  return (
    <div className="bg-white w-full h-full p-8 space-y-4">
      <div className="flex w-full h-10 space-x-4 items-center">
        <ArrowLeftIcon
          onClick={() => navigate("/settings")}
          className="w-6 h-6 text-black cursor-pointer"
        />
        <span className="font-bold">Create Captain</span>
      </div>
      <form
        onSubmit={onSubmitHandler}
        className="w-full h-[calc(100vh-210px)] space-y-4 border border-gray-200 py-4 rounded"
      >
        <div className="px-4">
          <ScrollArea className="h-[calc(100vh-230px)] py-4">
            <div className="grid grid-cols-3 gap-8">
              <TextInput
                type="text"
                placeholder=""
                value={formData.name}
                onChange={(e) => onChangeHandler(e.target.value, "name")}

                theme="black"
                error={errors.name}
              >
                <label htmlFor="name">Name*</label>
              </TextInput>

              <TextInput
                type="email"
                placeholder=""
                value={formData.email}
                onChange={(e) => onChangeHandler(e.target.value, "email")}

                theme="black"
                error={errors.email}
              >
                <label htmlFor="streamingKey">Email*</label>
              </TextInput>

              <TextInput
                type="text"
                placeholder=""
                value={formData.contact}
                onChange={(e) => onChangeHandler(e.target.value, "contact")}

                theme="black"
                error={errors.contact}
              >
                <label htmlFor="applicationName">Contact*</label>
              </TextInput>

              {/* <TextInput
                type="text"
                placeholder=""
                value={formData.supervisor}
                onChange={(e) => onChangeHandler(e.target.value, "supervisor")}
                required
                theme="black"
                error={errors.supervisor}
              >
                <label htmlFor="storageDuration">Storage Duration(days)*</label>
              </TextInput> */}

              <NormalSelect
                theme="black"
                keyField="id"
                valueField="name"
                className="border border-main text-nowrap"
                data={countries}
                error={errors.country}
                onValueChange={(value: string) =>
                  onChangeHandler(value, "country")
                }
                defaultValue={formData.country}
                placeholder="Select"
              >
                <label htmlFor="country">Country*</label>
              </NormalSelect>

              <TextInput
                type="password"
                placeholder=""
                value={formData.password}
                onChange={(e) => onChangeHandler(e.target.value, "password")}

                theme="black"
                error={errors.password}
              >
                <label htmlFor="password">Password*</label>
              </TextInput>

              <TextInput
                type="text"
                placeholder=""
                value={formData.remark}
                onChange={(e) => onChangeHandler(e.target.value, "remark")}

                theme="black"
                error={errors.remark}
              >
                <label htmlFor="remark">Remarks</label>
              </TextInput>

              <MultiSelect
                isMulti={true}
                theme="white"
                keyField="value"
                valueField="name"
                data={roles}
                error={errors.roles}
                onValueChange={(value) => onChangeHandler(value, "roles")}
                defaultValue={formData.roles}
                placeholder="Roles"
              >
                <label htmlFor="role">Roles*</label>
              </MultiSelect>

              <MultiSelect
                theme="white"
                keyField="id"
                valueField="name"
                data={slots}
                error={errors.slot}
                onValueChange={(value) => onChangeHandler(value, "slot")}
                defaultValue={formData.slot}
                placeholder="slots"
              >
                <label htmlFor="slots">Slot*</label>
              </MultiSelect>

              <Image
                onChange={(e: any) =>
                  onChangeHandler(e.target.files[0], "image")
                }
                theme="black"
                className="w-full flex items-center file:text-text file:text-end file:bg-main file:border-none"
              >
                <label htmlFor="image">Upload*</label>
              </Image>
            </div>
          </ScrollArea>
        </div>

        <div className="w-full flex justify-end items-center">
          <button
            type="submit"
            className="rounded text-white bg-green px-6 py-2"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};
