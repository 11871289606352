import React, { useCallback, useMemo, useState } from "react";
import { useMutation, useQuery } from "react-query";
import {
  getRequest,
  optimisticOptions,
  postRequest,
  queries,
} from "../../react-query";
import { useParams } from "react-router-dom";
import { reverseArray } from "../../utils";
import { LabelStudioUI } from "../../components/bound-box/LabelStudioUI";
import { defaultConfig } from "../../components/bound-box/config";
import { DynamicLabel } from "../../types";

export const StreamingLabelling = () => {
  // Constants and State
  let size = 1;
  const [page, setPage] = useState<number>(1);
  const [dynamicLabels, setDynamicLabels] = useState<DynamicLabel[]>([]);

  const params = useParams();

  // Data Fetching
  const { data: cameraData = {}, isLoading: isCameraLoading }: any = useQuery(
    [queries.camera, page, size],
    () => getRequest(`/camera/${params.camera_id}`),
    {
      enabled: !!params.camera_id,
    }
  );
  const { data: camera } = cameraData;

  const {
    data: preEventFetchingData = [],
    isLoading,
    error,
  }: any = useQuery(
    [queries.preEvent, page, size],
    () =>
      getRequest(
        `/pre-event?labelled=false&signed=true&is_streaming=true&page=${page}&pageSize=${size}`
      ),
    {
      enabled: !!page,
    }
  );

  // Destructuring Data
  const { data: preEvents = [], pagination = {} } = preEventFetchingData;
  const totalPages = useMemo(
    () => Math.ceil(pagination?.totalCount / pagination?.pageSize),
    [pagination]
  );

  const { data: eventResponse, mutate: createEventMutate } = useMutation(
    postRequest,
    optimisticOptions(queries.preEvent)
  );

  // Event Handlers
  const handleNextPage = useCallback(
    () => setPage((prevPage) => prevPage + 1),
    []
  );

  const handlePrevPage = useCallback(
    () => setPage((prevPage) => prevPage - 1),
    []
  );

  //submit
  const submit = (payload: any) => {
    page > 1 && handlePrevPage();
    console.log("annotation1", payload);
    try {
      createEventMutate({
        endPoint: "/event",
        payload: {
          annotations: payload,
          pre_event_capture_id: preEvents[0].id,
          status: "LABELLED",
        },
      });
    } catch (error) {
      console.error("Error uploading snapshot:", error);
    }
  };

  // useEffect(() => {
  //   if (eventResponse && eventResponse.id) {
  //     setAnnotations([]);
  //   }
  // }, [eventResponse]);


  const currentEvent = useMemo(() => preEvents[0], [preEvents]);

  if (isCameraLoading || isLoading) {
    return <div />;
  }

  return (
    <div
      className="h-full flex flex-col justify-between"
      style={{ position: "relative" }}
    >
      {currentEvent ? (
        <LabelStudioUI
          taskId={currentEvent.id}
          instructionLink={currentEvent.instruction_link}
          imageLocation={currentEvent.image_link}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
          submit={submit}
          page={page}
          pagination={pagination}
          categories={reverseArray(camera.sub_categories)}
          dynamicLabels={dynamicLabels}
        setDynamicLabels={setDynamicLabels}
        />
      ) : (
        <div className="w-full h-full grid place-content-center text-white">
          No image found!
        </div>
      )}
    </div>
  );
};
