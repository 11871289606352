import React from "react";

export const GlobalSearch = ({ filter, setFilter }: any) => {
  return (
    <div>
        <input
          className="w-28 border border-main rounded-full h-8 px-2 focus:outline-green"
          placeholder="Search"
          value={filter}
          onChange={setFilter}
        />
    </div>
  );
};
