import React, { useCallback, useMemo, useState } from "react";
import { DataGrid, List, ScrollArea } from "../../components";
import { getRequest, queries } from "../../react-query";
import { useQuery } from "react-query";
import ReactPaginate from "react-paginate";
import { TriangleLeftIcon, TriangleRightIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import { GlobalFilters, GlobalSearch } from "../../components/filters";

export const DatasetList = () => {
  let size = 10;
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);

  const [input, setInput] = useState({
    filter: "ACTIVE",
    search: "",
  });

  const {
    data: imagesData = [],
    isLoading,
    error,
  }: any = useQuery(
    [queries.dataset, input.filter, page, size],
    () =>
      getRequest(
        `/dataset?status=${input.filter}&page=${page}&pageSize=${size}`
      ),

    {
      enabled: !!page || !!input.filter,
    }
  );

  // Destructuring Data
  const { data: images = [], pagination } = imagesData;

  const totalPages = useMemo(
    () => Math.ceil(pagination?.totalCount / pagination?.pageSize),
    [pagination]
  );

  const onPageChangeHandler = useCallback((page: number) => {
    setPage(page + 1);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        disableFilters: true,
      },

      {
        Header: "Category",
        accessor: "category.name",
        disableFilters: true,
      },
      {
        Header: "Completed",
        accessor: (row: any) => {
          return `${row.labeled_images_count} / ${row.total_images_count}`;
        },
        disableFilters: true,
      },

      {
        Header: "Sub Categories",
        accessor: (row: any) => {
          return row.sub_categories?.map((sub: any) => sub.name).join(", ");
        },
        disableFilters: true,
      },
      // {
      //   Header: "Action",
      //   accessor: (row: any) => {
      //     return (
      //       <button
      //         disabled={!row.is_completed}
      //         onClick={() => console.log(row.id)} // Pass the row's id or relevant data
      //         className={` ${
      //           row.is_completed ? "bg-blue-500" : "bg-gray-300"
      //         }  text-white font-bold py-1 px-2 rounded`}
      //       >
      //         Download ZIP
      //       </button>
      //     );
      //   },
      //   disableFilters: true,
      // },
    ],
    [images]
  );

  const onValueChangeHandler = (value: string, name: string) => {
    if (value) {
      setInput({
        ...input,
        [name]: value,
      });
    }
  };

  const onRowHandler = (data: any) => {
    navigate(`/image/${data.id}/labelling`, {
      state: {
        datasetId: data.id,
      },
    });
  };

  return (
    <div className="min-h-full bg-white text-black space-y-4 p-6">
      <div className="flex justify-between items-center ">
        <div className="flex items-center">
          <p className="font-bold text-lg">Directories</p>
        </div>

        <div className="flex items-center space-x-4">
          <GlobalSearch
            value={input["search"]}
            setValue={(e: any) =>
              onValueChangeHandler(e.target.value, "search")
            }
          />
        </div>
      </div>
      
      <ScrollArea className="w-full  h-[calc(100vh-260px)]">
        <DataGrid data={images} columns={columns} onRowHandler={onRowHandler} />
      </ScrollArea>

      <ReactPaginate
        containerClassName={"paginationContainer"}
        pageClassName={"paginationItem"}
        activeClassName={"paginationActive"}
        onPageChange={(event) => onPageChangeHandler(event.selected)}
        pageCount={totalPages | 1}
        breakLabel="..."
        previousLabel={
          <TriangleLeftIcon className={`w-10 h-10 cursor-pointer`} />
        }
        nextLabel={<TriangleRightIcon className={`w-10 h-10 cursor-pointer`} />}
      />
    </div>
  );
};
