

interface ValidationRule {
  required?: boolean;
  pattern?: RegExp;
  message?: string;
}

interface ValidationRules {
  [key: string]: ValidationRule[];
}

const validateField = (
  fieldName: string,
  value: string | boolean | { label: string; value: string },
  rules: ValidationRules
): string[] => {
  const fieldRules = rules[fieldName];
  const fieldErrors: string[] = [];

  fieldRules.forEach((rule) => {
    if (rule.required) {
      if (Array.isArray(value)) {
        // For multi-select or array fields
        if (value.length === 0) {
          fieldErrors.push(rule.message || "This field is required");
        }
      }else if (typeof value === "string") {
        // String fields (like name, email, etc.)
        if (!value.trim()) {
          fieldErrors.push(rule.message || "This field is required");
        }
      } else if (typeof value === "object" && value !== null) {
        // Dropdown fields (like country, slot, etc.)
        if (value instanceof File) {
          // File validation
          if (!value || value.size === 0) {
            fieldErrors.push(rule.message || "File is required");
          }
        } else if (Array.isArray(value) && value.every((v) => v instanceof File)) {
          // Multiple file validation
          if (value.length === 0 || value.some((file) => file.size === 0)) {
            fieldErrors.push(rule.message || "File is required");
          }
        } else if (!value.value || value.value.trim() === "") {
          fieldErrors.push(rule.message || "This field is required");
        }
      } else if (!value) {
        fieldErrors.push(rule.message || "This field is required");
      }
    }
  
    // Pattern validation for string fields
    if (rule.pattern && typeof value === "string" && !rule.pattern.test(value)) {
      fieldErrors.push(rule.message || "Invalid input");
    }
  });

  return fieldErrors;
};

const validateForm = (
  data: Record<string, any>,
  rules: ValidationRules
): Record<string, string> => {
  const errors: Record<string, string> = {};

  Object.keys(rules).forEach((fieldName) => {
    const fieldErrors = validateField(fieldName, data[fieldName], rules);
    if (fieldErrors.length > 0) {
      errors[fieldName] = fieldErrors[0]; // Only return the first error for each field
    }
  });

  return errors;
};

export { validateField, validateForm };
