import React from "react";
import Select, { StylesConfig } from "react-select";

interface NormalSelectProps {
  data: Array<{ [key: string]: any }>;
  onValueChange: (value: any) => void;
  defaultValue: any;
  placeholder: string;
  className?: string;
  value?: any;
  theme?: "black" | "white";
  keyField: string;
  valueField: string;
  disabled?: boolean;
  children?: React.ReactNode;
  isMulti?: true | undefined;
  error?: string; // Added error prop
}

export const MultiSelect: React.FC<NormalSelectProps> = ({
  data,
  onValueChange,
  defaultValue,
  children,
  placeholder,
  className,
  value,
  theme = "black",
  keyField,
  valueField,
  disabled,
  error, // Destructure error prop
  ...props
}) => {
  const customStyles: StylesConfig<any, true> = {
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      height: "100%",
      borderRadius: 8,
      color: theme === "black" ? "white" : "black",
      borderColor: error ? "red" : "#DFDCDC", // Highlight border if error exists
      '&:hover': {
        borderColor: error ? "red" : "#DFDCDC",
      },
      '&:focus': {
        borderColor: error ? "red" : "#DFDCDC",
      },
      '&:active': {
        borderColor: error ? "red" : "#DFDCDC",
      },
      boxShadow: "none",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#000000",
    }),
  };

  return (
    <div className="space-y-1">
      {children}
      <Select
        isDisabled={disabled}
        className={`w-full inline-flex items-center min-h-10 justify-between ${className}`}
        styles={customStyles}
        placeholder={placeholder}
        options={data?.map((item) => ({
          label: item[valueField],
          value: item[keyField],
        }))}
        isClearable={false}
        value={value}
        defaultValue={defaultValue}
        onChange={onValueChange}
        {...props}
      />
      {error && <p className="text-red-500 text-sm">{error}</p>} {/* Display error */}
    </div>
  );
};
