import { SidebarFooter } from "./sidebar-footer";
import { renderSidebars } from "../../routes";
import { Link, NavLink } from "react-router-dom";

export const Sidebar = ({ isOpen }: any) => {
  return (
    <div
      className={`relative bg-primary min-h-full transform transition-opacity duration-1000 ease-in-out ${
        isOpen ? "translate-x-0" : "-translate-x-full"
      }`}
    >
      <div
        className={`min-w-24 h-full flex flex-col space-y-3 py-3 item-center text-white ${
          isOpen ? "visible" : "hidden"
        }`}
      >
        <div className="w-full flex flex-col mt-1 space-y-4 mx-auto">
          {renderSidebars([
            "monitor",
            "videos",
            "images",
            "quality",
            "setting",
          ])?.map((data: any) => (
            <NavLink
              key={data.title}
              to={data.path}
              className="flex items-center justify-center flex-col"
            >
              {({ isActive }) => (
                <>
                  <div
                    className={`${
                      isActive ? "bg-green" : "bg-transparent"
                    } w-16 h-12 mb-1  bg-green rounded flex justify-center items-center`}
                  >
                    <img src={data.icon} alt="icon" width={48} height={48} />
                  </div>
                  <p
                    className={`w-16 text-center text-xs ${
                      isActive ? "text-white" : "text-white"
                    }`}
                  >
                    {data.title}
                  </p>
                </>
              )}
            </NavLink>
          ))}
        </div>

        {/* <SidebarFooter /> */}
      </div>
    </div>
  );
};
