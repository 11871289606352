import React, { useCallback, useMemo, useState } from "react";
import { DataGrid, List, ScrollArea } from "../../../../components";
import { getRequest, queries } from "../../../../react-query";
import { useQuery } from "react-query";
import ReactPaginate from "react-paginate";
import { TriangleLeftIcon, TriangleRightIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import { GlobalFilters, GlobalSearch } from "../../../../components/filters";
import axiosInstance from "../../../../react-query/axios.config";

export const ImagesList = () => {
  let size = 10;
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);

  const [input, setInput] = useState({
    filter: "ACTIVE",
    search: "",
  });

  const {
    data: imagesData = [],
    isLoading,
    error,
  }: any = useQuery(
    [queries.images, input.filter, page, size],
    () =>
      getRequest(
        `/dataset?status=${input.filter}&page=${page}&pageSize=${size}`
      ),

    {
      enabled: !!page || !!input.filter,
    }
  );

  // Destructuring Data
  const { data: images = [], pagination } = imagesData;

  const totalPages = useMemo(
    () => Math.ceil(pagination?.totalCount / pagination?.pageSize),
    [pagination]
  );

  const onPageChangeHandler = useCallback((page: number) => {
    setPage(page + 1);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        disableFilters: true,
        width: 150,
      },
      {
        Header: "Client",
        accessor: (row:any)=>`${row.client.company_name} (${row.client.full_name})`,
        disableFilters: true,
        width: 150,
      },
      {
        Header: "Category",
        accessor: "category.name",
        disableFilters: true,
        width: 150,
      },
      {
        Header: "Task Completed",
        width: 250,
        accessor: (row: any) => {
          return (
            <div className="w-[200px]">
              <table className="min-w-full bg-white table-auto">
                <tbody>
                  {row.captain_image_stats.map((stat: any, index: number) => (
                    <tr key={index} className="border-b">
                      <td className="px-2 py-1 text-gray-700">
                        {stat.image_captain_name}
                      </td>
                      <td className="px-2 py-1 text-gray-600">
                        {stat.labeled_images_count} /{" "}
                        {stat.assigned_images_count}
                      </td>
                    </tr>
                  ))}
                  {/* Row for Total */}
                  <tr className="font-semibold">
                    <td className="px-2 py-1">Total</td>
                    <td className="px-2 py-1">
                      {row.labeled_images_count} / {row.total_images_count}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          );
        },
        disableFilters: true,
      },

      {
        Header: "Sub Categories",
        accessor: (row: any) => {
          return row.sub_categories?.map((sub: any) => sub.name).join(", ");
        },
        disableFilters: true,
        width: 250,
      },
      {
        Header: "Action",
        accessor: (row: any) => {
          return (
            <button
              disabled={!row.is_completed}
              onClick={(event) => {
                event.stopPropagation(); // Prevent row click
                downloadZipFile(`/dataset/download/${row.id}`);
              }}
              className={` ${
                row.is_completed ? "bg-blue-500" : "bg-gray-300"
              }  text-white font-bold py-1 px-2 rounded z-[9999]`}
            >
              Download ZIP
            </button>
          );
        },
        disableFilters: true,
      },
    ],
    [images]
  );

  const onValueChangeHandler = (value: string, name: string) => {
    if (value) {
      setInput({
        ...input,
        [name]: value,
      });
    }
  };

  const onRowHandler = (data: any) => {
    navigate(`/settings/image/update/${data.id}`, {
      state: {
        data: data,
      },
    });
  };

  const goToPage = () => {
    navigate("/settings/image/create");
  };

  const downloadZipFile = async (url: string) => {
    try {
      // const response = await getRequest(url);
      const response: any = await axiosInstance({
        method: "GET",
        url: url,
      });

      // Check if the response is valid and has the correct content type
      if (
        response.status === 200 &&
        response.headers["content-type"] === "application/zip"
      ) {
        // Create a Blob from the response data
        const blob = new Blob([response.data], { type: "application/zip" });
        const url = window.URL.createObjectURL(blob);

        // Create a link element to download the file
        const a = document.createElement("a");
        a.href = url;
        a.download = "dataset-plant.zip"; // Set the filename here
        document.body.appendChild(a);
        a.click();

        // Cleanup
        a.remove();
        window.URL.revokeObjectURL(url);
      }
    } catch (error) {
      console.error("Error downloading the zip file", error);
    }
  };

  return (
    <div className="text-black space-y-4 py-4">
      <div className="flex justify-between items-center">
        <GlobalFilters
          filter={input["filter"]}
          setFilter={(value: string) => onValueChangeHandler(value, "filter")}
        />
        <div className="flex items-center space-x-4">
          <GlobalSearch
            value={input["search"]}
            setValue={(e: any) =>
              onValueChangeHandler(e.target.value, "search")
            }
          />
          <button
            onClick={goToPage}
            className="bg-green active:bg-[#228B22] px-4 py-1.5 text-white rounded-full"
          >
            Create
          </button>
        </div>
      </div>
      <ScrollArea className="w-full  h-[calc(100vh-260px)]">
        <DataGrid data={images} columns={columns} onRowHandler={onRowHandler} />
      </ScrollArea>

      <ReactPaginate
        containerClassName={"paginationContainer"}
        pageClassName={"paginationItem"}
        activeClassName={"paginationActive"}
        onPageChange={(event) => onPageChangeHandler(event.selected)}
        pageCount={totalPages | 1}
        breakLabel="..."
        previousLabel={
          <TriangleLeftIcon className={`w-10 h-10 cursor-pointer`} />
        }
        nextLabel={<TriangleRightIcon className={`w-10 h-10 cursor-pointer`} />}
      />
    </div>
  );
};
