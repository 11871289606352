import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import {
  MultiSelect,
  MultiSelectDropdown,
  NormalSelect,
  ScrollArea,
  TextArea,
  TextInput,
} from "../../../../components";
import {
  getRequest,
  optimisticOptions,
  postRequest,
  queries,
} from "../../../../react-query";
import { useMutation, useQuery } from "react-query";
import { SourceInput, VideoUpload } from "../../../../components/upload";
import { validateForm } from "../../../../utils";

const captures = [
  {
    id: "1",
    name: "Image",
    value: "image",
  },
  {
    id: "2",
    name: "Video",
    value: "video",
  },
];

export const CreateVideo = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    location: {
      label:"",
      value:""
    },
    name: "",
    description: "",
    safetyAssigne: "",
    remark: "",
    client: {
      label:"",
      value:""
    },
    access_link:"",
    preview_link:"",
    video_captains: [],
    instruction_id: {
      label: "",
      value: "",
    },
    video_quality_controllers: [],
  });

  const [errors, setErrors] = useState<Record<string, string>>({});

  const [locations, setLocations] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState<any>();

  const { data: safetyMembers = [], isSafetyLoading }: any = useQuery(
    [queries.users],
    () => getRequest(`/users`)
  );

  // Destructuring Data
  const { data: captains = [] } = safetyMembers;

  const { data: clientData = [], isClientLoading }: any = useQuery(
    [queries.clients],
    () => getRequest(`/client`)
  );

  // Destructuring Data
  const { data: clients = [] } = clientData;

  const {
    data: instructionsData = {}
  }: any = useQuery([queries.instructions], () => getRequest(`/instruction`));

  const { data: instructions = [] } = instructionsData;

  const { data: categoryData = [], isCategoryLoading }: any = useQuery(
    [queries.categories],
    () => getRequest(`/category`)
  );

  // Destructuring Data
  const { data: categories = [] } = categoryData;

  const onChangeHandler = (value: string | number, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const { mutate: createClient } = useMutation(
    postRequest,
    optimisticOptions(queries.videos)
  );

    // Validation rules
    const validationRules = {
      name: [{ required: true, message: "Name is required." }],
      description: [{ required: true, message: "Description is required." }],
      client: [{ required: true, message: "Client is required." }],
      location: [{ required: true, message: "Location is required." }],
      instruction_id: [{ required: true, message: "Instruction ID is required." }],
      remark:[{required: true, message: "remark is required." }],
      video_captains:[{required: true, message:"select a captain"}],
      video_quality_controllers:[{required: true, message:"select a captain"}],
    };

  const onSubmitHandler = useCallback(
    (e: any) => {
      e.preventDefault();

 // Validate form data
 const formErrors = validateForm(formData, validationRules);
 setErrors(formErrors);

 // If no validation errors, proceed with submission
 if (Object.keys(formErrors).length === 0) {
      const payload = {
        name: formData.name,
        description: formData.description,
        status: "ACTIVE",
        client_id: formData.client.value,
        location_id: formData.location.value,
        categories: selectedCategories,
        remark: formData.remark,
        access_link:formData.access_link,
        preview_link:formData.preview_link,
        instruction_id:formData.instruction_id.value,
        video_captain_id : formData.video_captains[0] && formData.video_captains[0]['value'],
        video_quality_controller_id: formData.video_quality_controllers[0] && formData.video_quality_controllers[0]['value'],
      };
      createClient({
        endPoint: "/video",
        payload: payload,
      });
      navigate(-1);
    }
    },
    [formData, selectedCategories]
  );

  const handleSelectCategories = (selectedCategories: any) => {
    setSelectedCategories(selectedCategories);
  };

  const onFileChangeHandler = (access_link: string, preview_link: string) => {
    setFormData({...formData, access_link, preview_link})
  };

//   const video_captains = captains
//   .filter((ele: any) => ele.roles.includes("VIDEO_CAPTAIN"))
//   .map((captain: any) => {
//     return {
//       name: captain.username,
//       value: captain.id,
//     };
//   });

// const video_quality_controllers = captains
//   .filter((ele: any) => ele.roles.includes("VIDEO_QUALITY_CONTROLLER"))
//   .map((captain: any) => {
//     return {
//       name: captain.username,
//       value: captain.id,
//     };
//   });


  // Memoized filtered captains
  const video_captains = useMemo(
    () =>
      captains
        .filter((c: any) => c.roles.includes("VIDEO_CAPTAIN"))
        .map((c: any) => ({ label: c.username, value: c.id })),
    [captains]
  );
  const video_quality_controllers = useMemo(
    () =>
      captains
        .filter((c: any) => c.roles.includes("VIDEO_QUALITY_CONTROLLER"))
        .map((c: any) => ({ label: c.username, value: c.id })),
    [captains]
  );

  if (isCategoryLoading || isClientLoading ) {
    return <div />;
  }

  return (
    <div className="bg-white w-full h-full p-8 space-y-4">
      <div className="flex w-full h-10 space-x-4 items-center">
        <ArrowLeftIcon
          onClick={() => navigate("/settings")}
          className="w-6 h-6 text-black cursor-pointer"
        />
        <span className="font-bold">Create Video</span>
      </div>
      <form
        onSubmit={onSubmitHandler}
        className="w-full h-[calc(100vh-210px)] space-y-4 border border-gray-200 py-4 rounded"
      >
        <div className="px-4">
          <ScrollArea className="h-[calc(100vh-230px)] py-4">
            <div className="grid grid-cols-3 gap-8">
              <TextInput
                type="text"
                placeholder=""
                value={formData.name}
                onChange={(e) => onChangeHandler(e.target.value, "name")}
               
                theme="black"
                error={errors.name}
              >
                <label htmlFor="name">Video Name*</label>
              </TextInput>

              <NormalSelect
                theme="black"
                keyField="id"
                valueField="name"
                className="border border-main text-nowrap"
                data={instructions}
                error={errors.instruction_id}
                onValueChange={(value: string) =>
                  onChangeHandler(value, "instruction_id")
                }
                defaultValue={formData.instruction_id}
                placeholder="Select"
              >
                <label htmlFor="remark">Insruction Template</label>
              </NormalSelect>

              <NormalSelect
                theme="black"
                keyField="id"
                valueField="full_name"
                className="border border-main text-nowrap"
                data={clients}
                error={errors.client}
                onValueChange={(cli: any) => {
                  let client = clients.find(
                    (client: any) => client.id === cli.value
                  );
                  setFormData({ ...formData, ["client"]: cli});
                  setLocations(client.locations);
                }}
                defaultValue={formData.client}
                placeholder="Select"
              >
                <label htmlFor="remark">Clients Name*</label>
              </NormalSelect>

              <NormalSelect
                theme="black"
                disabled={formData.client.value ? false : true}
                keyField="id"
                valueField="name"
                className="border border-main text-nowrap"
                data={locations}
                error={errors.location}
                onValueChange={(value: any) =>
                  onChangeHandler(value, "location")
                }
                defaultValue={formData.location}
                placeholder="Select"
              >
                <label htmlFor="remark">Camera Location*</label>
              </NormalSelect>


              

              <div className="col-span-3">
                <MultiSelectDropdown
                  className="border border-main text-nowrap"
                  theme="black"
                  categories={categories}
                  onSelect={handleSelectCategories}
                >
                  <label htmlFor="Select">Categories*</label>
                </MultiSelectDropdown>
              </div>

              

              <div className="col-span-3">
                <TextArea
                  placeholder=""
                  value={formData.description}
                  onChange={(e) =>
                    onChangeHandler(e.target.value, "description")
                  }
                 
                  theme="black"
                  error={errors.description}
                >
                  <label htmlFor="description">Video Description*</label>
                </TextArea>
              </div>

              <div className="col-span-3">
                <MultiSelect
                  theme="white"
                  keyField="value"
                  valueField="label"
                  data={video_captains}
                  error={errors.video_captains}
                  onValueChange={(value) =>
                    onChangeHandler(value, "video_captains")
                  }
                  defaultValue={formData.video_captains}
                  placeholder="Video Captains"
                  isMulti
                >
                  <label htmlFor="video_captains">Video Captains*</label>
                </MultiSelect>
              </div>

              <div className="col-span-3">
                <MultiSelect
                  theme="white"
                  keyField="value"
                  valueField="label"
                  data={video_quality_controllers}
                  error={errors.video_quality_controllers}
                  onValueChange={(value) =>
                    onChangeHandler(value, "video_quality_controllers")
                  }
                  defaultValue={formData.video_quality_controllers}
                  placeholder="Video Quality Controllers"
                  isMulti
                >
                  <label htmlFor="video_quality_controllers">Video Quality Controllers*</label>
                </MultiSelect>
              </div>

              <TextInput
                type="text"
                placeholder=""
                value={formData.remark}
                onChange={(e) => onChangeHandler(e.target.value, "remark")}
           
                theme="black"
                error={errors.remark}
              >
                <label htmlFor="remark">Remarks</label>
              </TextInput>


              <div className="col-span-3">
                <SourceInput
                  accept="video/*"
                  innerClassName="border border-dashed p-8"
                  label="Upload video"
                  onFileChangeHandler={onFileChangeHandler}
                >
                  <label htmlFor="description">Video Source*</label>
                </SourceInput>
              </div>
            </div>
          </ScrollArea>
        </div>

        <div className="w-full flex justify-end items-center">
          <button
            type="submit"
            className="rounded text-white bg-green px-6 py-2"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};
