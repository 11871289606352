import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const errorToast = (message:any) => {
    const customStyle = {
        fontSize: '12px', // Adjust the font size as needed
    };

    toast.error(message, {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        style: customStyle,
      });
};

export default errorToast;