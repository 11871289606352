import React, { useCallback, useState } from "react";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import { NormalSelect, TagsInput, TextInput } from "../../../../components";
import { useMutation } from "react-query";
import {
  optimisticOptions,
  postRequest,
  queries,
} from "../../../../react-query";
import { validateForm } from "../../../../utils";

export const CreateCategory = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    name: "",
    color: "#e66465",
    sub_categories: [],
  });

  const [subCategories, setSubCategories] = useState<string[]>([]);

  const [errors, setErrors] = useState<Record<string, string>>({});

  const onChangeHandler = (value: string, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const { mutate: createClient } = useMutation(
    postRequest,
    optimisticOptions(queries.categories)
  );

    // Define validation rules
    const validationRules = {
      name: [{ required: true, message: "Name is required." }],
      color: [{ required: true, message: "Color selection is required." }],
      // sub_categories: [{ required: true, message: "At least one sub-category is required." }],
    };

  const onSubmitHandler = useCallback(
    (e: any) => {
      e.preventDefault();

       // Validate form data
       const formErrors = validateForm(formData, validationRules);
       setErrors(formErrors);
      
       // If there are no errors, proceed with the submission
       if (Object.keys(formErrors).length === 0) {
      createClient({
        endPoint: "/category",
        payload: {
          ...formData,
          sub_categories: subCategories,
        },
      });
      navigate(-1);
    }
    },
    [formData, subCategories]
  );

  return (
    <div className="bg-white w-full h-full p-8 space-y-4">
      <div className="flex w-full h-10 space-x-4 items-center">
        <ArrowLeftIcon
          onClick={() => navigate("/settings")}
          className="w-6 h-6 text-black cursor-pointer"
        />
        <span className="font-bold">Create Category</span>
      </div>
      <form
        onSubmit={onSubmitHandler}
        className="w-full h-[calc(100vh-180px)] flex flex-col justify-between border border-gray-200 p-4 rounded"
      >
        <div className="grid grid-cols-2 gap-8">
          <TextInput
            type="text"
            placeholder=""
            value={formData.name}
            onChange={(e) => onChangeHandler(e.target.value, "name")}
            theme="black"
            error={errors.name}
          >
            <label htmlFor="name">Name*</label>
          </TextInput>



          <div className={`w-full flex flex-col  justify-center space-y-1 text-black`}>
            <div className="flex justify-between items-center">
              <label className="" htmlFor="color">
                Color*
              </label>
            </div>
            <input 
             className={`w-full focus:outline-none  border rounded-lg h-10 py-1 px-3 bg-transparent border-main`} 
             type="color"  
             value={formData.color}
             onChange={(e)=>onChangeHandler(e.target.value, "color")}
             />
          </div>

          <div className="space-y-1 col-span-3">
            <span>Sub-Categories*</span>
            <TagsInput
              placeholder="Add a sub-category"
              tags={subCategories}
              onValuePush={(value: string) => {
                setSubCategories([...subCategories, value]);
              }}
              onValueRemove={(value: string) => {
                const filterLocations = subCategories.filter(
                  (e) => e !== value
                );
                setSubCategories(filterLocations);
              }}
            />
          </div>
        </div>
        <div className="w-full flex justify-end items-center">
          <button
            type="submit"
            className="rounded text-white bg-green px-6 py-2"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};
