import React, { useCallback, useState } from "react";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import {
  NormalSelect,
  ScrollArea,
  TagsInput,
  TextInput,
} from "../../../../components";
import { useMutation, useQuery } from "react-query";
import {
  getRequest,
  optimisticOptions,
  postRequest,
  queries,
} from "../../../../react-query";
import { validateForm } from "../../../../utils";
import errorToast from "../../../../utils/errorToast";

export const CreateClient = () => {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    full_name: "",
    company_name: "",
    designation: "",
    department: "",
    state: "",
    city: "",
    pin_code: "",
    street_address: "",
    email: "",
    contact: "",
    country: {
      label: "",
      value: "",
    },
  });

  const [locations, setLocations] = useState<string[]>([]);

  const [errors, setErrors] = useState<Record<string, string>>({});

  const onChangeHandler = (value: string, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const {
    data: countriesData = {},
    isLoading,
    error,
  }: any = useQuery([queries.country], () => getRequest(`/country`));

  const { data: countries = [], pagination } = countriesData;

  const { mutate: createClient } = useMutation(
    postRequest,
    optimisticOptions(queries.clients)
  );

  // Define validation rules
  const validationRules = {
    full_name: [{ required: true, message: "Full Name is required." }],
    company_name: [
      { required: true, message: "Company Name name is required." },
    ],
    designation: [{ required: true, message: "Designation name is required." }],
    department: [{ required: true, message: "Department name is required." }],
    contact: [{ required: true, message: "Contact is required." }],
    email: [
      { required: true, message: "Email is required." },
      {
        pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: "Invalid email format.",
      },
      {
        pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: "Invalid email format.",
      },
    ],
    state: [{ required: true, message: "State is required." }],
    city: [{ required: true, message: "City is required." }],
    pin_code: [{ required: true, message: "Pin code is required." }],
    street_address: [
      { required: true, message: "Street address is required." },
    ],
    country: [{ required: true, message: "Country selection is required." }],
  };

  const onSubmitHandler = useCallback(
    (e: any) => {
      e.preventDefault();

      // Validate form data
      const formErrors = validateForm(formData, validationRules);
      setErrors(formErrors);
      console.log("formerroe", formErrors);

      // If there are no errors, proceed with the submission
      if (Object.keys(formErrors).length === 0) {
        createClient({
          endPoint: "/client",
          payload: {
            full_name: formData.full_name,
            email: formData.email,
            contact: formData.contact,
            company_name: formData.company_name,
            designation: formData.designation,
            department: formData.department,
            address: {
              state: formData.state,
              pin_code: formData.pin_code,
              street_address: formData.street_address,
              city: formData.city,
              country_id: formData.country.value,
            },
            locations: locations,
          },
        });
        navigate(-1);
      } else {
        errorToast("please check all fields");
      }
    },
    [formData, locations]
  );

  return (
    <div className="bg-white w-full h-full p-8 space-y-4">
      <div className="flex w-full h-10 space-x-4 items-center">
        <ArrowLeftIcon
          onClick={() => navigate("/settings")}
          className="w-6 h-6 text-black cursor-pointer"
        />
        <span className="font-bold">Create Client</span>
      </div>
      <form
        onSubmit={onSubmitHandler}
        className="w-full h-[calc(100vh-220px)] flex flex-col justify-between border border-gray-200 p-4 rounded"
      >
        <div className="px-4">
          <ScrollArea className="h-[calc(100vh-230px)] py-4">
            <div className="grid grid-cols-3 gap-x-8">
              <TextInput
                type="text"
                placeholder=""
                value={formData.full_name}
                onChange={(e) => onChangeHandler(e.target.value, "full_name")}
                theme="black"
                error={errors.full_name}
              >
                <label htmlFor="name">Full Name*</label>
              </TextInput>

              <TextInput
                type="email"
                placeholder=""
                value={formData.email}
                onChange={(e) => onChangeHandler(e.target.value, "email")}
                theme="black"
                error={errors.email}
              >
                <label htmlFor="streamingKey">Email*</label>
              </TextInput>

              <TextInput
                type="text"
                placeholder=""
                value={formData.contact}
                onChange={(e) => onChangeHandler(e.target.value, "contact")}
                theme="black"
                error={errors.contact}
              >
                <label htmlFor="applicationName">Contact*</label>
              </TextInput>

              <TextInput
                type="text"
                placeholder=""
                value={formData.company_name}
                onChange={(e) =>
                  onChangeHandler(e.target.value, "company_name")
                }
                theme="black"
                error={errors.company_name}
              >
                <label htmlFor="streamingKey">Company Name*</label>
              </TextInput>

              <TextInput
                type="text"
                placeholder=""
                value={formData.designation}
                onChange={(e) => onChangeHandler(e.target.value, "designation")}
                theme="black"
                error={errors.designation}
              >
                <label htmlFor="streamingKey">Designation*</label>
              </TextInput>

              <TextInput
                type="text"
                placeholder=""
                value={formData.department}
                onChange={(e) => onChangeHandler(e.target.value, "department")}
                theme="black"
                error={errors.department}
              >
                <label htmlFor="streamingKey">Department*</label>
              </TextInput>

              <div className="space-y-1 col-span-3 mb-5">
                <span>Locations*</span>
                <TagsInput
                  placeholder="Add a location"
                  tags={locations}
                  onValuePush={(value: string) => {
                    setLocations([...locations, value]);
                  }}
                  onValueRemove={(value: string) => {
                    const filterLocations = locations.filter(
                      (e) => e !== value
                    );
                    setLocations(filterLocations);
                  }}
                />
              </div>

              <NormalSelect
                theme="black"
                keyField="id"
                valueField="name"
                className="border border-main text-nowrap"
                data={countries}
                error={errors.country}
                onValueChange={(value: string) =>
                  onChangeHandler(value, "country")
                }
                defaultValue={formData.country}
                placeholder="Select"
              >
                <label htmlFor="country">Country*</label>
              </NormalSelect>

              <TextInput
                type="text"
                placeholder=""
                value={formData.state}
                onChange={(e) => onChangeHandler(e.target.value, "state")}
                theme="black"
                error={errors.state}
              >
                <label htmlFor="applicationName">State*</label>
              </TextInput>

              <TextInput
                type="text"
                placeholder=""
                value={formData.city}
                onChange={(e) => onChangeHandler(e.target.value, "city")}
                theme="black"
                error={errors.city}
              >
                <label htmlFor="applicationName">City*</label>
              </TextInput>

              <TextInput
                type="text"
                placeholder=""
                value={formData.pin_code}
                onChange={(e) => onChangeHandler(e.target.value, "pin_code")}
                theme="black"
                error={errors.pin_code}
              >
                <label htmlFor="applicationName">Pin Code*</label>
              </TextInput>

              <TextInput
                type="text"
                placeholder=""
                value={formData.street_address}
                onChange={(e) =>
                  onChangeHandler(e.target.value, "street_address")
                }
                theme="black"
                error={errors.street_address}
              >
                <label htmlFor="applicationName">Street Address</label>
              </TextInput>
            </div>
          </ScrollArea>
        </div>

        <div className="w-full flex justify-end items-center">
          <button
            type="submit"
            className="rounded text-white bg-green px-6 py-2"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};
