import React from "react";
import { Navigate } from "react-router-dom";
import { useStoreState } from "../store/easy-peasy/hooks";

interface ProtectedRolesRouteProps {
  roles: string[];   // roles is an array of allowed roles for the route
  children: React.ReactNode; // Children to render if access is allowed
}

const ProtectedRolesRoute: React.FC<ProtectedRolesRouteProps> = ({
    roles,
    children,
}) => {

  const userRoles = useStoreState((store) => store.roles);

  // Check if the route requires specific roles and whether the user has any matching roles
  const hasAccess = userRoles.some(role => roles.includes(role));

  if (!hasAccess) {
    return <Navigate to="/403" />; // Redirect to "403 Forbidden" page
  }

  return <>{children}</>;
};

export default ProtectedRolesRoute;
