import React from "react";
import * as ToggleGroup_ from "@radix-ui/react-toggle-group";
import classNames from "classnames";

export const ToggleGroup = ({
  data,
  onValueChange,
  defaultValue,
  type = "",
  ...props
}: any) => (
  <ToggleGroup_.Root
    className={`${props.innerClass}`}
    type="single"
    value={defaultValue}
    aria-label="Text alignment"
    onValueChange={onValueChange}
  >
    {data?.map((ele: any, index: number) => {
      let cl;
      if (index === 0 && type === "rounded") {
        cl = "rounded-l-full";
      } else if (index === data.length - 1 && type === "rounded") {
        cl = "rounded-r-full";
      }
    
      return (
        <ToggleGroup_.Item
          key={ele.id}
          className={classNames(cl, props.outerClass)}
          value={ele.value}
          aria-label={ele.name}
        >
          <span>{ele.name}</span>
        </ToggleGroup_.Item>
      );
    })}
  </ToggleGroup_.Root>
);
