import React, { useState, ChangeEvent, KeyboardEvent, InputHTMLAttributes } from 'react';

interface TagsInputProps extends InputHTMLAttributes<HTMLInputElement> {
  tags: string[];
  onValuePush: (tag: string) => void;
  onValueRemove: (tag: string) => void;
}

export const TagsInput: React.FC<TagsInputProps> = ({ tags, onValuePush, onValueRemove, ...props }) => {
  const [inputValue, setInputValue] = useState<string>('');

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter' && inputValue.trim() !== '') {
      event.preventDefault();
      onValuePush(inputValue.trim());
      setInputValue('');
    }
  };

  return (
    <div className="flex flex-wrap gap-2 border border-main h-10 p-1 rounded-md">
      {tags?.map((tag, index) => (
        <div key={index} className="bg-gray-200 px-2 py-1 rounded flex items-center">
          {tag}
          <span
            className="ml-2 cursor-pointer"
            onClick={() => onValueRemove(tag)}
          >
            &times;
          </span>
        </div>
      ))}
      <input
        type="text"
        value={inputValue}
        onChange={handleInputChange}
        onKeyDown={handleKeyDown}
        className="border-none outline-none bg-transparent placeholder:text-gray-500"
        {...props} // Spread the additional props here
      />
    </div>
  );
};
