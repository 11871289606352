import React from "react";

export const Image = ({ theme, children, ...props }: any) => {
  return (
    <div
      className={`w-full flex flex-col justify-center space-y-1 ${
        theme === "black" ? "text-black" : "text-white"
      }`}
    >
      <div className="flex justify-between items-center">{children}</div>

      <div
        className={`focus:outline-none  border rounded-lg py-1 px-3 ${
          theme === "black"
            ? "bg-transparent border-main"
            : "text-white bg-transparent border-white"
        }`}
      >
        <input
          type="file"
          accept="image/*"
          {...props}
        />
      </div>
    </div>
  );
};
