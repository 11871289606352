import React, { useCallback, useMemo, useState } from "react";

import { useMutation, useQuery } from "react-query";
import {
  getRequest,
  optimisticOptions,
  postRequest,
  queries,
} from "../../react-query";
import { reverseArray } from "../../utils";
import { useParams } from "react-router-dom";
import { defaultConfig } from "../../components/bound-box/config";
import { LabelStudioUI } from "../../components/bound-box/LabelStudioUI";
import { DynamicLabel } from "../../types";

export const ImageLabelling = () => {
  // Constants and State
  let size = 1;
  const [page, setPage] = useState<number>(1);
  const params = useParams();
  const [dynamicLabels, setDynamicLabels] = useState<DynamicLabel[]>([]);

  // Data Fetching
  const { data: datasetData = {}, isLoading: isVideoLoading }: any = useQuery(
    [queries.dataset, page, size, params.image_id],
    () => getRequest(`/dataset/${params.image_id}`),
    {
      enabled: !!params.image_id,
    }
  );
  const { data: dataset } = datasetData;

  const {
    data: preEventFetchingData = [],
    isLoading,
    error,
  }: any = useQuery(
    [queries.images, page, size, params.image_id],
    () =>
      getRequest(
        `/image?datasetId=${params.image_id}&labelled=false&signed=true&page=${page}&pageSize=${size}`
      ),
    {
      enabled: !!page && !!params.image_id,
    }
  );

  // Destructuring Data
  const { data: images = [], pagination = {} } = preEventFetchingData;

  const { data: eventResponse, mutate: createEventMutate } = useMutation(
    postRequest,
    optimisticOptions(queries.images)
  );

  // Event Handlers
  const handleNextPage = useCallback(
    () => setPage((prevPage) => prevPage + 1),
    []
  );

  const handlePrevPage = useCallback(
    () => setPage((prevPage) => prevPage - 1),
    []
  );

  //submit
  const submit = async (annotations: any) => {
    try {
      page > 1 && handlePrevPage();

      createEventMutate({
        endPoint: "/event",
        payload: {
          annotations: annotations,
          image_id: images[0].id,
          status: "LABELLED",
        },
      });
    } catch (error) {
      console.error("Error uploading snapshot:", error);
    }
  };

  const currentEvent = useMemo(() => images[0], [images]);
  
  if (isVideoLoading || isLoading) {
    return <div />;
  }

  return (
    <div
      className="h-full flex flex-col justify-between"
      style={{ position: "relative" }}
    >
      {currentEvent ? (
        <LabelStudioUI
          taskId={currentEvent.id}
          imageLocation={currentEvent.image_link}
          instructionLink={currentEvent.instruction_link}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
          submit={submit}
          page={page}
          pagination={pagination}
          categories={reverseArray(dataset?.sub_categories)}
          dynamicLabels={dynamicLabels}
        setDynamicLabels={setDynamicLabels}
        />
      ) : (
        <div className="w-full h-full grid place-content-center text-white">
          No image found!
        </div>
      )}
    </div>
  );
};
