export const roles = [
  {
    id: "1",
    name: "Streaming Captain",
    value: "STREAMING_CAPTAIN",
  },
  {
    id: "2",
    name: "Image Captain",
    value: "IMAGE_CAPTAIN",
  },
  {
    id: "3",
    name: "Video Captain",
    value: "VIDEO_CAPTAIN",
  },
  {
    id: "4",
    name: "Image Quality Controller",
    value: "IMAGE_QUALITY_CONTROLLER",
  },
  {
    id: "5",
    name: "Video Quality Controller",
    value: "VIDEO_QUALITY_CONTROLLER",
  },
  {
    id: "6",
    name: "Streaming Quality Controller",
    value: "STREAMING_QUALITY_CONTROLLER",
  },
];

export function convertTo24HourFormat(time: string): string {
  const [timePart, period] = time.split(" "); // Split into time and AM/PM
  const [hours, minutes] = timePart.split(":").map(Number); // Split time into hours and minutes

  let adjustedHours = hours;

  if (period.toUpperCase() === "PM" && hours !== 12) {
    adjustedHours += 12; // Convert PM hour to 24-hour format
  } else if (period.toUpperCase() === "AM" && hours === 12) {
    adjustedHours = 0; // Handle midnight (12 AM is 00:00 in 24-hour format)
  }

  // Pad the minutes with a leading zero if necessary
  const formattedHours = String(adjustedHours).padStart(2, "0");
  const formattedMinutes = String(minutes).padStart(2, "0");

  return `${formattedHours}:${formattedMinutes}`;
}

export const copyLinkHandler = (
  event: React.MouseEvent,
  link: string,
  message: string
) => {
  event.stopPropagation(); // Prevents the event from propagating to parent elements

  if (navigator.clipboard && typeof navigator.clipboard.writeText === "function") {
    navigator.clipboard.writeText(link).then(() => {
      alert(message);
    }).catch(err => {
      console.error("Failed to copy text: ", err);
      alert("Failed to copy the link. Please try again.");
    });
  } else {
    // Fallback for unsupported browsers
    const textArea = document.createElement("textarea");
    textArea.value = link;
    textArea.style.position = "fixed"; // Avoid scrolling to the bottom
    textArea.style.opacity = "0";
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
      document.execCommand("copy");
      alert(message);
    } catch (err) {
      console.error("Fallback: Unable to copy", err);
      alert("Failed to copy the link. Please try again.");
    } finally {
      document.body.removeChild(textArea);
    }
  }
};


export const openLinkInNewTab = (link: string, event: React.MouseEvent) => {
  event.stopPropagation(); // Prevents the event from propagating to parent elements
  window.open(link, "_blank"); // Opens the link in a new tab
};
