import React, { useCallback, useEffect, useState } from "react";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { useNavigate, useParams } from "react-router-dom";
import {
  MultiSelect,
  TextInput,
  Image,
  ScrollArea,
  NormalSelect,
} from "../../../../components";
import {
  getRequest,
  optimisticOptions,
  postRequest,
  putRequest,
  queries,
} from "../../../../react-query";
import { useMutation, useQuery } from "react-query";
import { roles } from "../../utils";
import { validateForm } from "../../../../utils";
import errorToast from "../../../../utils/errorToast";

export const UpdateCaptain = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    name: "",
    contact: "",
    email: "",
    country: {
      label: "",
      value: "",
    },
    roles: [{}],
    supervisor: "",
    password: "",
    remark: "",
    image: "",
  });

  const [errors, setErrors] = useState<Record<string, string>>({});

  const { data: captainData = {}, isLoading: isUserLoading }: any = useQuery(
    [queries.users, id],
    () => getRequest(`/users/${id}`),
    {
      enabled: !!id,
    }
  );

  // Destructuring Data
  const { data: captain = {} } = captainData;

  const {
    data: countriesData = {},
    isLoading,
    error,
  }: any = useQuery([queries.country], () => getRequest(`/country`));

  const { data: countries = [], pagination } = countriesData;

  const onChangeHandler = (value: any, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const { mutate: updateCaptain } = useMutation(
    putRequest,
    optimisticOptions(queries.users)
  );
  const onSubmitHandler = useCallback(
    (e: any) => {
      e.preventDefault();

      // Validate form data
      const formErrors = validateForm(formData, validationRules);
      console.log("foem", formErrors);

      setErrors(formErrors);

      // If no validation errors, proceed with submission
      if (Object.keys(formErrors).length === 0) {
        const { name, email, contact, roles, password, country, remark } =
          formData;
        let form = new FormData();
        if (name) form.append("username", name);
        if (email) form.append("email", email);
        if (password) form.append("password", password);
        if (contact) form.append("contact", contact);
        if (remark) form.append("remark", remark);
        if (country && country.value) form.append("country_id", country.value);
        if (roles && Array.isArray(roles)) {
          roles.forEach((role: any) => {
            if (role.value) form.append("roles[]", role.value);
          });
        }

        updateCaptain({
          endPoint: `/users/${id}`,
          payload: form,
        });
        navigate(-1);
      } else {
        errorToast("please check all fields");
      }
    },
    [formData, id]
  );

  const validationRules = {
    name: [{ required: true, message: "Name is required." }],
    contact: [{ required: true, message: "Contact is required." }],
    email: [
      { required: true, message: "Email is required." },
      {
        pattern: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        message: "Invalid email format.",
      },
    ],
    country: [{ required: true, message: "Country selection is required." }],
    roles: [{ required: true, message: "At least one role is required." }],
    // password: [{ required: true, message: "Password is required." }],
    remark: [{ required: true, message: "Remark is required." }],
  };

  useEffect(() => {
    if (captain.id) {
      const { username, email, password, contact, country, roles, remark } =
        captain;

      setFormData({
        name: username,
        email: email,
        password: password,
        contact: contact,
        roles: roles
          .filter((role: any) => captain.roles.includes(role))
          .map((role: any) => ({ label: role, value: role })),
        country: {
          label: country.name,
          value: country.id,
        },
        supervisor: "",
        remark: remark,
        image: "",
      });
    }
  }, [setFormData, captain, roles]);

  if (isUserLoading || isLoading) {
    return <div />;
  }

  return (
    <div className="bg-white w-full h-full p-8 space-y-4">
      <div className="flex w-full h-10 space-x-4 items-center">
        <ArrowLeftIcon
          onClick={() => navigate("/settings")}
          className="w-6 h-6 text-black cursor-pointer"
        />
        <span className="font-bold">Update Captain</span>
      </div>
      <form
        onSubmit={onSubmitHandler}
        className="w-full h-[calc(100vh-210px)] space-y-4 border border-gray-200 py-4 rounded"
      >
        <div className="px-4">
          <ScrollArea className="h-[calc(100vh-230px)] py-4">
            <div className="grid grid-cols-3 gap-8">
              <TextInput
                type="text"
                placeholder=""
                value={formData.name}
                onChange={(e) => onChangeHandler(e.target.value, "name")}
                required
                theme="black"
                error={errors.name}
              >
                <label htmlFor="name">Name*</label>
              </TextInput>

              <TextInput
                type="email"
                placeholder=""
                value={formData.email}
                onChange={(e) => onChangeHandler(e.target.value, "email")}
                required
                theme="black"
                error={errors.email}
              >
                <label htmlFor="streamingKey">Email*</label>
              </TextInput>

              <TextInput
                type="text"
                placeholder=""
                value={formData.contact}
                onChange={(e) => onChangeHandler(e.target.value, "contact")}
                required
                theme="black"
                error={errors.contact}
              >
                <label htmlFor="applicationName">Contact*</label>
              </TextInput>

              {/* <TextInput
                type="text"
                placeholder=""
                value={formData.supervisor}
                onChange={(e) => onChangeHandler(e.target.value, "supervisor")}
                required
                theme="black"
                error={errors.supervisor}
              >
                <label htmlFor="storageDuration">Storage Duration(days)*</label>
              </TextInput> */}

              <NormalSelect
                theme="black"
                keyField="id"
                valueField="name"
                className="border border-main text-nowrap"
                data={countries}
                onValueChange={(value: string) =>
                  onChangeHandler(value, "country")
                }
                defaultValue={formData.country}
                placeholder="Select"
              >
                <label htmlFor="country">Country*</label>
              </NormalSelect>

              <TextInput
                required={false}
                type="password"
                placeholder="**********"
                value={formData.password}
                onChange={(e) => onChangeHandler(e.target.value, "password")}
                theme="black"
                // error={errors.password}
              >
                <label htmlFor="password">Password*</label>
              </TextInput>

              <TextInput
                type="text"
                placeholder=""
                value={formData.remark}
                onChange={(e) => onChangeHandler(e.target.value, "remark")}
                required
                theme="black"
                error={errors.remark}
              >
                <label htmlFor="remark">Remarks</label>
              </TextInput>

              <MultiSelect
                theme="black"
                isMulti={true}
                keyField="value"
                valueField="name"
                data={roles}
                onValueChange={(value) => onChangeHandler(value, "roles")}
                value={formData.roles}
                defaultValue={roles.filter((role: any) =>
                  captain.roles.includes(role)
                )}
                placeholder="Roles"
              >
                <label htmlFor="role">Roles*</label>
              </MultiSelect>
            </div>
          </ScrollArea>
        </div>

        <div className="w-full flex justify-end items-center">
          <button
            type="submit"
            className="rounded text-white bg-green px-6 py-2"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};
