import { DynamicLabel } from "../../types";


export const defaultConfig = `<View>
<RectangleLabels name="tag" toName="img">
</RectangleLabels>
<Image name="img" value="$image"/>
</View>
`;


export const getConfig = (dynamicLabels: DynamicLabel[]) => {
    const labels = dynamicLabels
    .map(({label, background}:DynamicLabel) => `<Label background="${background}" value="${label.replace(/"/g, "&quot;")}" />`)
    .join("\n");

  // Update label config
  const [beforeRectangleLabels, afterRectangleLabels] =
    defaultConfig.split("</RectangleLabels>");
  return `${beforeRectangleLabels}${labels}</RectangleLabels>${afterRectangleLabels}`;
}

export const extractLabelColors = (config: string): Map<string, string> => {
  const labelColors = new Map<string, string>();
  
  try {
    // Create a temporary DOM parser
    const parser = new DOMParser();
    const xmlDoc = parser.parseFromString(config, "text/xml");
    
    // Find all Label elements
    const labelElements:any = xmlDoc.getElementsByTagName('Label');
    
    // Extract value and background color for each label
    for (const label of labelElements) {
      const value = label.getAttribute('value');
      const background = label.getAttribute('background');
      if (value && background) {
        labelColors.set(value, background);
      }
    }
  } catch (error) {
    console.error('Error parsing label config:', error);
  }
  
  return labelColors;
};


// Utility function to format labels with various sorting options
export const formatLabel = (
  mainCategory: DynamicLabel,
  subCategories: string[],
  options: {
    sort?: 'asc' | 'desc' | 'none';
    separator?: string;
    transform?: (s: string) => string;
  } = {}
) => {
  const {
    sort = 'asc',
    separator = ', ',
    transform = (s: string) => s
  } = options;

  // Sort subcategories based on option
  let sortedSubCategories = [...subCategories];
  if (sort === 'asc') {
    sortedSubCategories.sort((a, b) => a.localeCompare(b));
  } else if (sort === 'desc') {
    sortedSubCategories.sort((a, b) => b.localeCompare(a));
  }

  // Apply any transformation and join
  const subCategoriesString = sortedSubCategories
    .map(transform)
    .join(separator);

  return `${mainCategory.label} - ${subCategoriesString}`;
};