import React, { useCallback, useEffect, useRef, useState } from "react";
import LabelStudio from "@heartexlabs/label-studio";
import "@heartexlabs/label-studio/build/static/css/main.css";
import { useStoreState } from "../../store/easy-peasy/hooks";
import { BottomControls } from "./BottomControls";
import { AnnotationData, DynamicLabel } from "../../types";
import { label_studio_interfaces } from "../../utils/labelStudio";
import { extractLabelColors, getConfig } from "./config";

interface LabelStudioUIProps {
  imageLocation: string;
  handleNextPage: any;
  handlePrevPage: any;
  submit: any;
  page: number;
  pagination: any;
  categories: any;
  dynamicLabels: DynamicLabel[];
  setDynamicLabels: (config: DynamicLabel[]) => void;
  instructionLink: string;
  taskId: string;
}

export const LabelStudioUI: React.FC<LabelStudioUIProps> = ({
  imageLocation,
  handleNextPage,
  handlePrevPage,
  submit,
  page,
  pagination,
  categories,
  dynamicLabels,
  setDynamicLabels,
  instructionLink,
  taskId,
}) => {
  const user = useStoreState((store) => store.user);
  const rootRef = useRef<HTMLDivElement>(null);
  const labelStudioRef = useRef<any>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const [annotations, setAnnotations] = useState<AnnotationData[]>([]);
  const [isInitialized, setIsInitialized] = useState(false);

  // Function to handle auto-scrolling
  const scrollToBottom = useCallback(() => {
    if (scrollRef.current) {
      const labelPanel = scrollRef.current.querySelector('.lsf-labels-list');
      if (labelPanel) {
        labelPanel.scrollTop = labelPanel.scrollHeight;
      }
    }
  }, []);

  // Initialize LabelStudio
  useEffect(() => {
    if (!rootRef.current) return;

    if (labelStudioRef.current) {
      labelStudioRef.current.destroy();
      labelStudioRef.current = null;
    }

    labelStudioRef.current = new LabelStudio(rootRef.current, {
      config: getConfig(dynamicLabels),
      interfaces: label_studio_interfaces,
      user: {
        pk: 1,
        firstName: user.name,
        lastName: "",
      },
      task: {
        id: Number("0x" + taskId.slice(0, 15).replace(/-/g, "")),
        data: {
          image: imageLocation,
        },
        annotations: [
          {
            result: annotations,
          },
        ],
        predictions: [],
      },
      onLabelStudioLoad: (LS: any) => {
        if (!annotations.length) {
          const c = LS.annotationStore.addAnnotation({
            userGenerate: true,
          });
          LS.annotationStore.selectAnnotation(c.id);
        }
        setIsInitialized(true);
        
        // Apply initial scroll adjustment
        setTimeout(scrollToBottom, 100);
      },
      onSubmitAnnotation: (LS: any, annotation: AnnotationData) => {
        setAnnotations(prev => [...prev, annotation]);
        setTimeout(scrollToBottom, 100);
      },
      onUpdateAnnotation: (LS: any, annotation: AnnotationData) => {
        setAnnotations(prev => 
          prev.map(a => a.id === annotation.id ? annotation : a)
        );
      },
      onDeleteAnnotation: (LS: any, annotation: AnnotationData) => {
        setAnnotations(prev => 
          prev.filter(a => a.id !== annotation.id)
        );
      },
    });

    return () => {
      if (labelStudioRef.current) {
        labelStudioRef.current.destroy();
        labelStudioRef.current = null;
      }
      setIsInitialized(false);
    };
  }, [imageLocation, dynamicLabels, taskId, user.name]);

  // Add custom styles and mutation observer for dynamic content
  useEffect(() => {
    if (!isInitialized) return;

    const style = document.createElement('style');
    style.textContent = `
      .label-studio-root {
        display: flex !important;
        flex-direction: column !important;
        height: 100% !important;
        max-height: calc(100vh - 60px) !important;
      }
      .lsf-sidebar-tabs {
        height: auto !important;
        max-height: calc(100vh - 180px) !important;
        overflow-y: auto !important;
      }
      .lsf-labels-list {
        max-height: none !important;
        overflow-y: auto !important;
        scrollbar-width: thin !important;
      }
      .lsf-labels-list > div {
        height: auto !important;
      }
      .ls-wrap {
        flex: 1 !important;
        min-height: 0 !important;
        overflow: auto !important;
      }
      .ls-common-grid {
        overflow: visible !important;
      }
      .lsf-annotations {
        max-height: none !important;
      }
    `;
    document.head.appendChild(style);

    // Set up mutation observer to handle dynamic content changes
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length > 0) {
          scrollToBottom();
        }
      });
    });

    const labelsList = rootRef.current?.querySelector('.lsf-labels-list');
    if (labelsList) {
      observer.observe(labelsList, {
        childList: true,
        subtree: true
      });
    }

    return () => {
      document.head.removeChild(style);
      observer.disconnect();
    };
  }, [isInitialized, scrollToBottom]);

  const handleSubmit = useCallback(() => {
    if (labelStudioRef.current) {
      const LS = labelStudioRef.current;
      const annotation = LS.store.annotationStore.selected;
      const serializedAnnotations = annotation.serializeAnnotation();

      if (Array.isArray(serializedAnnotations) && serializedAnnotations.length > 0) {
        const labelColors = extractLabelColors(LS.store.config);
        const annotations = serializedAnnotations?.map((item: any) => {
          const labelValue = item.value.rectanglelabels[0];
          const color = labelColors.get(labelValue);
          return {
            ...item,
            color,
          };
        });

        submit(annotations);
        setAnnotations([]);
      }
    }
  }, [labelStudioRef.current, submit]);

  const handleLabeling = useCallback((config: any) => {
    if (labelStudioRef.current) {
      const LS = labelStudioRef.current;
      const annotation = LS.store.annotationStore.selected;
      if (annotation) {
        setDynamicLabels(config);
        setAnnotations(annotation.serializeAnnotation());
      }
    }
  }, [setDynamicLabels]);

  return (
    <div className="relative w-full z-0 flex flex-col h-screen">
      <div 
        ref={scrollRef}
        className="flex-1 min-h-0 overflow-hidden"
        style={{
          height: 'calc(100vh - 60px)',
        }}
      >
        <div
          ref={rootRef}
          className="w-full h-full"
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
        />
      </div>
      <div className="h-[60px] flex-shrink-0 flex justify-between items-center px-6 ">
        <BottomControls
          instructionLink={instructionLink}
          handleNextPage={handleNextPage}
          handlePrevPage={handlePrevPage}
          handleSubmit={handleSubmit}
          page={page}
          pagination={pagination}
          categories={categories}
          dynamicLabels={dynamicLabels}
          handleLabeling={handleLabeling}
        />
      </div>
    </div>
  );
};