import ZipInput from "../../assets/images/zip_input.png";

export function SourceZip({
  label,
  children,
  status,
  ...props
}: any) {

  const renderProcessing = (type: string) => {
    switch (type) {
      case "loading":
        return (
          <div className="relative w-28 h-28">
            <svg className="w-full h-full" viewBox="0 0 100 100">
              <circle
                className="text-gray-200 stroke-current"
                strokeWidth="10"
                cx="50"
                cy="50"
                r="40"
                fill="transparent"
              ></circle>

              <circle
                className="text-indigo-500  progress-ring__circle stroke-current"
                strokeWidth="10"
                strokeLinecap="round"
                cx="50"
                cy="50"
                r="40"
                fill="transparent"
                stroke-dasharray="251.2"
                stroke-dashoffset={`calc(251.2 - (251.2 * 100) / 100)`}
              ></circle>
            </svg>
          </div>
        );
      case "initial":
        return (
          <div className="flex flex-col space-y-2 justify-center items-center">
            <img src={ZipInput} alt="zip" height={48} width={48} />
            <p className="text-nowrap">{label}</p>
          </div>
        );
      case "uploaded":
        return (
          <div className="relative text-center w-full h-full">
            {/* <img src={thumbnailUrl} alt="uploaded"/> */}
            <p className="text-green">Uploaded Successfully</p>
          </div>
        );
      default:
        return <div />;
    }
  };

  return (
    <div className={`relative w-full ${props.innerClassName}`}>
      <input
        type="file"
        accept=".zip"
        {...props}
        className="w-full h-full absolute bg-transparent opacity-0"
      />
      {renderProcessing(status)}
    </div>
  );
}
