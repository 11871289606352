import React, { useMemo } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  TableOptions
} from "react-table";
import { ColumnFilter } from "./ColumnFilter";
import { GlobalFilter } from "./GlobalFilter";

interface TableState {
  globalFilter: string;
  pageIndex: number;
  pageSize: number;
}

export function DataGrid({
  columns,
  data,
  onRowHandler
}: any) {



  const defaultColumn: any = useMemo(
    () => ({
      Filter: ({ column }: any) => <ColumnFilter column={column} />,
    }),
    []
  );


  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow
  } = useTable<TableState>(
    {
      columns,
      data,
      defaultColumn,
      manualPagination: true
    } as TableOptions<any>,
    useFilters,
    useGlobalFilter,
    useSortBy
  ) as any; // Type assertion to any to access setGlobalFilter

 

  return (
    <div className="overflow-x-auto overflow-y-hidden">
      {/* <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} /> */}
      <table {...getTableProps()} className="min-w-full divide-y divide-main">
        
        <thead>
          {headerGroups?.map((headerGroup: any, index: number) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={index}>
              {headerGroup.headers?.map((column: any) => {
                const { key, ...rest } = column.getHeaderProps(
                  column.getSortByToggleProps()
                );
                return (
                  <th
                    key={key}
                    scope="col"
                    className="group py-3 text-left font-bold capitalize tracking-wider"
                    {...rest}
                    style={{ minWidth:column.minWidth, width:column.width, maxWidth:column.maxWidth }} // Fixed column width
                  >
                    <div className="flex items-center space-x-4">
                      <div>{column.render("Header")}</div>
                      <div>
                        {column.isSorted
                          ? column.isSortedDesc
                            ? " 🔽"
                            : " 🔼"
                          : ""}
                      </div>
                      <div>
                        {column.canFilter ? column.render("Filter") : null}
                      </div>
                    </div>
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody
          {...getTableBodyProps()}
          className="relative bg-transparent divide-y divide-main"
        >
          {rows.length === 0 ? (
            <tr>
              {columns?.map((column: any, index: number) => (
                <td
                  key={index}
                  className="py-3 whitespace-nowrap"
                  style={{ minWidth: "150px" }} // Fixed column width
                >
                  &nbsp;
                </td>
              ))}
            </tr>
          ) : (
            rows?.map((row: any) => {
              prepareRow(row);
              return (
                <tr
                  className="cursor-pointer "
                  onClick={() => onRowHandler(row.original)}
                  {...row.getRowProps()}
                  key={row.id}
                >
                  {row.cells?.map((cell: any) => {
                    const { key, ...rest } = cell.getCellProps();
                    return (
                      <td
                        key={key}
                        {...rest}
                        className="py-3 whitespace-nowrap"
                        style={{ minWidth: "150px" }} // Fixed column width
                        role="cell"
                      >
                        {cell.render("Cell")}
                      </td>
                    );
                  })}
                </tr>
              );
            })
          )}
        </tbody>
      </table>
    </div>
  );
}
