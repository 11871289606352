import React from "react";
import Logo from "../../assets/images/header/logo.png";
import Profile from "../../assets/images/header/profile.png";

export function AnonymousHeader() {
  return (
    <header className="w-full h-16 bg-primary flex justify-between items-center px-4">
      <div className="">
        <img
          className="object-cover"
          src="http://arresto.in/web/wp-content/uploads/2023/11/arresto-menu-logo.png"
          width={130}
          height={60}
          alt="logo"
        />
      </div>

      {/* <div className="flex justify-between items-center space-x-6 text-white">
        <p>25 days left in trial</p>
        <button className="bg-blue-500 rounded px-4 py-1">Add billing info</button>
        <img
          className="object-cover"
          src={Profile}
          width={24}
          height={24}
          alt="profile"
        />
      </div> */}
    </header>
  );
}
