import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { useNavigate, useParams } from "react-router-dom";
import {
  MultiSelect,
  MultiSelectDropdown,
  NormalSelect,
  ScrollArea,
  TextArea,
  TextInput,
} from "../../../../components";
import {
  getRequest,
  optimisticOptions,
  postRequest,
  putRequest,
  queries,
} from "../../../../react-query";
import { useMutation, useQuery } from "react-query";
import { reverseArray, reverseArrayWithIds, validateForm } from "../../../../utils";
import errorToast from "../../../../utils/errorToast";

export const UpdateVideo = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState<any>({
    location: {
      label: "",
      value: "",
    },
    name: "test",
    description: "",
    remark: "",
    client: {
      label: "",
      value: "",
    },
    video_captains: [],
    video_quality_controllers: [],
    instruction: {
      label: "",
      value: "",
    }
  });

  const [errors, setErrors] = useState<Record<string, string>>({});

  const [locations, setLocations] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState<any>();

  const { data: videoData = {}, isLoading: isVideoLoading }: any = useQuery(
    [queries.videos, id],
    () => getRequest(`/video/${id}`),
    {
      enabled: !!id,
    }
  );
  // Destructuring Data
  const { data: video = {} } = videoData;

  const { data: safetyMembers = [], isSafetyLoading }: any = useQuery(
    [queries.users],
    () => getRequest(`/users`)
  );
  // Destructuring Data
  const { data: captains = [] } = safetyMembers;

  const { data: clientData = [], isLoading: isClientLoading }: any = useQuery(
    [queries.clients],
    () => getRequest(`/client`)
  );

  const {
    data: instructionsData = {}
  }: any = useQuery([queries.instructions], () => getRequest(`/instruction`));

  const { data: instructions = [] } = instructionsData;

  // Destructuring Data
  const { data: clients = [] } = clientData;

  const { data: categoryData = [], isLoading: isCategoryLoading }: any =
    useQuery([queries.categories], () => getRequest(`/category`));

  // Destructuring Data
  const { data: categories = [] } = categoryData;

  const onChangeHandler = (value: string | number, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const { mutate: updateVideo } = useMutation(
    putRequest,
    optimisticOptions(queries.videos)
  );

  // Validation rules
  const validationRules = {
    name: [{ required: true, message: "Name is required." }],
    description: [{ required: true, message: "Description is required." }],
    client: [{ required: true, message: "Client is required." }],
    location: [{ required: true, message: "Location is required." }],
    instruction: [{ required: true, message: "Instruction ID is required." }],
    remark: [{ required: true, message: "remark is required." }],
    video_captains: [{ required: true, message: "select a captain" }],
    video_quality_controllers: [{ required: true, message: "select a captain" }],
  };

  const onSubmitHandler = useCallback(
    (e: any) => {
      e.preventDefault();

      // Validate form data
      const formErrors = validateForm(formData, validationRules);
      setErrors(formErrors);
      console.log("form", formErrors)
      // If no validation errors, proceed with submission
      if (Object.keys(formErrors).length === 0) {
        const payload = {
          name: formData.name,
          description: formData.description,
          status: "ACTIVE",
          client_id: formData.client.value,
          location_id: formData.location.value,
          categories: selectedCategories,
          remark: formData.remark,
          instruction_id: formData.instruction.value,
        };
        updateVideo({
          endPoint: `/video/${id}`,
          payload: payload,
        });
        navigate(-1);
      } else {
        errorToast("Unable to update")
      }
    },
    [formData, selectedCategories]
  );

  const handleSelectCategories = (selectedCategories: any) => {
    setSelectedCategories(selectedCategories);
  };

  useEffect(() => {
    if (video.id) {
      const {
        location,
        name,
        description,
        video_captain,
        video_quality_controller,
        remark,
        client,
        instruction
      } = video;

      setFormData({
        location: {
          label: location?.name || "",
          value: location?.id || "",
        },
        name: name,
        description: description,
        instruction:{
          label:instruction.name,
          value:instruction.id,
        } ,
        remark: remark,
        client: {
          label: client?.company_name || "",
          value: client?.id || "",
        },
        video_captains: video_captain
          ? [{ label: video_captain.username, value: video_captain.id }]
          : [],
        video_quality_controllers: video_quality_controller
          ? [
            {
              label: video_quality_controller.username,
              value: video_quality_controller.id,
            },
          ]
          : [],
      });

      const locations = client
        ? clients?.find((cli: any) => cli.id === client.id)?.locations
        : [];
      setLocations(locations);

      let categories = reverseArrayWithIds(video.sub_categories);
      setSelectedCategories(categories);
    }
  }, [video, clients]);

  // Memoized filtered captains
  const video_captains = useMemo(
    () =>
      captains
        .filter((c: any) => c.roles.includes("VIDEO_CAPTAIN"))
        .map((c: any) => ({ label: c.username, value: c.id })),
    [captains]
  );
  const video_quality_controllers = useMemo(
    () =>
      captains
        .filter((c: any) => c.roles.includes("VIDEO_QUALITY_CONTROLLER"))
        .map((c: any) => ({ label: c.username, value: c.id })),
    [captains]
  );

  if (isCategoryLoading || isClientLoading || isVideoLoading) {
    return <div />;
  }

  return (
    <>
      {formData && (
        <div className="bg-white w-full h-full p-8 space-y-4">
          <div className="flex w-full h-10 space-x-4 items-center">
            <ArrowLeftIcon
              onClick={() => navigate("/settings")}
              className="w-6 h-6 text-black cursor-pointer"
            />
            <span className="font-bold">Update Video</span>
          </div>
          <form
            onSubmit={onSubmitHandler}
            className="w-full h-[calc(100vh-210px)] space-y-4 border border-gray-200 py-4 rounded"
          >
            <div className="px-4">
              <ScrollArea className="h-[calc(100vh-230px)] py-4">
                <div className="grid grid-cols-3 gap-8">
                  <TextInput
                    type="text"
                    placeholder=""
                    value={formData.name}
                    onChange={(e) => onChangeHandler(e.target.value, "name")}
                    required
                    theme="black"
                    error={errors.name}
                  >
                    <label htmlFor="name">Video Name*</label>
                  </TextInput>

                  <NormalSelect
                    theme="black"
                    keyField="id"
                    valueField="name"
                    className="border border-main text-nowrap"
                    data={instructions}
                    error={errors.instruction}
                    onValueChange={(value: string) =>
                      onChangeHandler(value, "instruction")
                    }
                    defaultValue={formData.instruction}
                    placeholder="Select"
                  >
                    <label htmlFor="remark">Insruction Template</label>
                  </NormalSelect>

                  <NormalSelect
                    theme="black"
                    keyField="id"
                    valueField="company_name"
                    className="border border-main text-nowrap"
                    data={clients}
                    onValueChange={(cli: any) => {
                      let client = clients.find(
                        (client: any) => client.id === cli.value
                      );
                      setFormData({ ...formData, ["client"]: cli });
                      setLocations(client.locations);
                    }}
                    defaultValue={formData.client}
                    placeholder="Select"
                  >
                    <label htmlFor="remark">Clients Name*</label>
                  </NormalSelect>

                  <NormalSelect
                    theme="black"
                    disabled={formData.client.value ? false : true}
                    keyField="id"
                    valueField="name"
                    className="border border-main text-nowrap"
                    data={locations}
                    onValueChange={(value: string) =>
                      onChangeHandler(value, "location")
                    }
                    defaultValue={formData.location}
                    placeholder="Select"
                  >
                    <label htmlFor="remark">Camera Location*</label>
                  </NormalSelect>

                  <div className="col-span-3">
                    <MultiSelectDropdown
                      className="border border-main text-nowrap"
                      theme="black"
                      categories={categories}
                      onSelect={handleSelectCategories}
                      defaultValue={selectedCategories}
                    >
                      <label htmlFor="Select">Categories*</label>
                    </MultiSelectDropdown>
                  </div>

                  <div className="col-span-3">
                    <MultiSelect
                      theme="white"
                      keyField="value"
                      valueField="label"
                      error={errors.video_captains}
                      data={video_captains}
                      onValueChange={(value) =>
                        onChangeHandler(value, "video_captains")
                      }
                      value={formData.video_captains}
                      defaultValue={formData.video_captains}
                      placeholder="Video Captains"
                      isMulti
                    >
                      <label htmlFor="video_captains">Video Captains*</label>
                    </MultiSelect>
                  </div>

                  <div className="col-span-3">
                    <MultiSelect
                      theme="white"
                      keyField="value"
                      valueField="label"
                      data={video_quality_controllers}
                      error={errors.video_quality_controllers}
                      onValueChange={(value) =>
                        onChangeHandler(value, "video_quality_controllers")
                      }
                      value={formData.video_quality_controllers}
                      defaultValue={formData.video_quality_controllers}
                      placeholder="Video Quality Controllers"
                      isMulti
                    >
                      <label htmlFor="video_quality_controllers">
                        Video Quality Controllers*
                      </label>
                    </MultiSelect>
                  </div>

                  <div className="col-span-3">
                    <TextArea
                      placeholder=""
                      value={formData.description}
                      onChange={(e) =>
                        onChangeHandler(e.target.value, "description")
                      }
                      required
                      theme="black"
                      error={errors.description}
                    >
                      <label htmlFor="description">Video Description*</label>
                    </TextArea>
                  </div>

                  <TextInput
                    type="text"
                    placeholder=""
                    value={formData.remark}
                    onChange={(e) => onChangeHandler(e.target.value, "remark")}
                    required
                    theme="black"
                    error={errors.remark}
                  >
                    <label htmlFor="remark">Remarks</label>
                  </TextInput>
                </div>
              </ScrollArea>
            </div>

            <div className="w-full flex justify-end items-center">
              <button
                type="submit"
                className="rounded text-white bg-green px-6 py-2"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};
