import { AxiosError, AxiosProgressEvent } from "axios";
import axiosInstance from "./axios.config";
import errorToast from "../utils/errorToast";
import successToast from "../utils/successToast";

type RequestConfig = {
  endPoint: string;
  payload?: {};
  onUploadProgress?: (progressEvent: AxiosProgressEvent) => void;
  headers?: {};
};

// Define a type for the expected error response data structure
interface ErrorResponseData {
  message?: string;
}

const makeRequest = async (
  method: "get" | "post" | "put" | "delete" | "patch",
  { endPoint, payload, onUploadProgress, headers }: RequestConfig
) => {
  try {
    const { data } = await axiosInstance({
      method,
      url: endPoint,
      data: payload,
      headers,
      onUploadProgress: onUploadProgress,
    });
    //console.log("daatttt", data?.data);
    if (data?.status === true) {
      successToast('success')
    }
    return data.data ? data.data : data;
  } catch (error) {
    const axiosError = error as AxiosError<ErrorResponseData>;
    console.error(`Error ${method.toUpperCase()} request:`, axiosError);
    errorToast(`${axiosError.response?.data?.message}`);
    console.log("eeeee", axiosError.response?.data?.message);
    throw axiosError;
  }
};

const makeRequest2 = async (
  method: "get" | "post" | "put" | "delete",
  { endPoint, payload, onUploadProgress, headers }: RequestConfig
) => {

  try {
    const { data } = await axiosInstance({
      method,
      url: endPoint,
      data: payload,
      headers,
      onUploadProgress: onUploadProgress,
    });
    //console.log("daa", data)
    return data
  } catch (error) {
    const axiosError = error as AxiosError<ErrorResponseData>;
    console.error(`Error ${method.toUpperCase()} request:`, axiosError);
    errorToast(`${axiosError.response?.data?.message}`);
    console.log("eeeee", axiosError.response?.data?.message);
    throw axiosError;
  }
};

const getRequest = async (endPoint: string) => {
  return makeRequest2("get", { endPoint });
};

const postRequest = async ({
  endPoint,
  payload,
  onUploadProgress,
}: RequestConfig) => {
  return makeRequest("post", { endPoint, payload, onUploadProgress });
};

const putRequest = async ({ endPoint, payload }: RequestConfig) => {
  return makeRequest("put", { endPoint, payload });
};

const patchRequest = async ({ endPoint, payload }: RequestConfig) => {
  return makeRequest("patch", { endPoint, payload });
};

const deleteRequest = async ({ endPoint }: RequestConfig) => {
  return makeRequest("delete", { endPoint });
};

export { getRequest, postRequest, patchRequest, putRequest, deleteRequest };
