import { action, Action, createStore, persist } from "easy-peasy";
import { User, Payload } from "../type";
import ls from "localstorage-slim";
import { Role } from "../../utils/enum";

export interface EasyPeasyStore {
  isAuthenticated: boolean;
  roles: Role[];
  isSideMenuOpen: boolean;
  setting:string,
  user: User;
  addUser: Action<this, Payload>;
  setAuthenticated: Action<this, boolean>;
  updateSetting: Action<this, string>;
  toggleSideMenu: Action<this, boolean>;
  logout: Action<this>;
  token: string;
}

const initialState = {
  isSideMenuOpen: true,
  token: "",
  setting: "profile",
  isAuthenticated: false,
  roles:[],
  user: {
    email: "",
    name: ""
  },
};

export const store = createStore<EasyPeasyStore>(
  persist(
    {
      ...initialState,

      addUser: action((state: any, payload: Payload) => {
        state.user = payload.user;
        state.token = payload.token;
        state.roles = payload.roles;
        state.setting = payload.setting;
        state.isAuthenticated = true;
      }),

      setAuthenticated:action((state: any, payload: boolean) => {
        state.isAuthenticated = payload;
      }),

      updateSetting: action((state: any, payload: string) => {
        state.setting = payload;
      }),

      toggleSideMenu: action((state: any, payload: boolean) => {
        state.isSideMenuOpen = payload;
      }),

      logout: action((state: any) => {
        state.user = {
          email: "",
        };
        state.token = "";
        state.isAuthenticated = false;
      })
    },
    {
      storage: {
        getItem: async function (key) {
          const value: any = ls.get(key, { decrypt: true });
          return value;
        },

        setItem: function (key, value) {
          ls.set(key, value);
        },

        removeItem: function (key) {
          ls.remove(key);
        },
      },
      allow: ["user", "isAuthenticated", "isSideMenuOpen", "token", "setting", "roles"],
    }
  ),
  {
    name: "safety",
  }
);
