import React, { useCallback, useEffect, useState } from "react";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { useNavigate, useParams } from "react-router-dom";
import {
  NormalSelect,
  ScrollArea,
  TagsInput,
  TextInput,
  UploadFile,
} from "../../../../components";
import { useMutation, useQuery } from "react-query";
import {
  getRequest,
  optimisticOptions,
  postRequest,
  putRequest,
  queries,
} from "../../../../react-query";

export const UpdateInstruction = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    name: "",
    pdf_link: "",
  });

  const [errors, setErrors] = useState({
    name: "",
    pdf_link: "",
  });

  const { data: instructionData = {}, isLoading: isInstructionLoading }: any =
    useQuery(
      [queries.instructions, id],
      () => getRequest(`/instruction/${id}`),
      {
        enabled: !!id,
      }
    );

  const { data: instruction = {} } = instructionData;

  const onChangeHandler = (value: string, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const { mutate: updateClient } = useMutation(
    putRequest,
    optimisticOptions(queries.instructions)
  );

  const onSubmitHandler = useCallback(
    (e: any) => {
      e.preventDefault();
      let form = new FormData();
      form.append("name", formData.name);
      form.append("file", formData.pdf_link);
      updateClient({
        endPoint: `/instruction/${instruction.id}`,
        payload: form,
      });
      navigate(-1);
    },
    [formData, instruction]
  );

  useEffect(() => {
    if (instruction.id) {
      setFormData({
        ...formData,
        name: instruction.name,
        pdf_link: instruction.pdf_link,
      });
    }
  }, [instruction]);

  if (isInstructionLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      {formData && (
        <div className="bg-white w-full h-full p-8 space-y-4">
          <div className="flex w-full h-10 space-x-4 items-center">
            <ArrowLeftIcon
              onClick={() => navigate("/settings")}
              className="w-6 h-6 text-black cursor-pointer"
            />
            <span className="font-bold">Update Instruction</span>
          </div>
          <form
            onSubmit={onSubmitHandler}
            className="w-full h-[calc(100vh-210px)] space-y-4 border border-gray-200 py-4 rounded"
          >
            <div className="px-4">
              <ScrollArea className="h-[calc(100vh-230px)] py-4">
                <div className="grid grid-cols-2 gap-8">
                  <TextInput
                    type="text"
                    placeholder=""
                    value={formData.name}
                    onChange={(e) => onChangeHandler(e.target.value, "name")}
                    required
                    theme="black"
                    error={errors.name}
                  >
                    <label htmlFor="name">Name*</label>
                  </TextInput>
                  <UploadFile
                    accept="application/pdf,application/vnd.ms-excel"
                    onChange={(e: any) =>
                      onChangeHandler(e.target.files[0], "pdf_link")
                    }
                    theme="black"
                    className="w-full flex items-center file:text-text file:text-end file:bg-main file:border-none"
                  >
                    <label htmlFor="pdf_link">Upload PDF*</label>
                  </UploadFile>
                </div>
              </ScrollArea>
            </div>

            <div className="w-full flex justify-end items-center">
              <button
                type="submit"
                className="rounded text-white bg-green px-6 py-2"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};
