import React from "react";
import { ToggleGroup } from "..";

const tabs = [
  {
    id: "1",
    name: "Active",
    value: "ACTIVE",
  },
  {
    id: "2",
    name: "Inactive",
    value: "INACTIVE",
  },
  {
    id: "2",
    name: "Deleted",
    value: "Deleted",
  },
];

export const GlobalFilters = ({ filter, setFilter }: any) => {
  return (
    <ToggleGroup
    innerClass="h-8 flex items-center items-center space-x-4"
    outerClass="h-full border-b text-black border-transparent data-[state=on]:border-[#A39D9D] data-[state=on]:text-black px-2 py-1"
    data={tabs}
    defaultValue={filter}
    onValueChange={setFilter}
  />
  );
};
