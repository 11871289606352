import React, { useCallback, useEffect, useState } from "react";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  NormalSelect,
  ScrollArea,
  TagsInput,
  TextInput,
} from "../../../../components";
import { useMutation, useQuery } from "react-query";
import {
  getRequest,
  optimisticOptions,
  putRequest,
  queries,
} from "../../../../react-query";
import { convertTo24HourFormat } from "../../utils";
import { validateForm } from "../../../../utils";


export const UpdateSlot = () => {
  const navigate = useNavigate();
  const { id } = useParams();

  const [formData, setFormData] = useState({
    name: "",
    start_time: "",
    end_time: "",
  });

  const [errors, setErrors] = useState<Record<string, string>>({});

  const { data: workSlotData = {} }: any = useQuery(
    [queries.slots, id],
    () => getRequest(`/work-slot/${id}`),
    {
      enabled: !!id,
    }
  );
  const { data: workSlot = {} } = workSlotData;

     // Define validation rules
     const validationRules = {
      name: [{ required: true, message: "Name is required." }],
      start_time: [{ required: true, message: "Start time is required." }],
      end_time: [{ required: true, message: "End time is required." }],
    };

  const { mutate: updateWorkSlot } = useMutation(
    putRequest,
    optimisticOptions(queries.slots)
  );

  const onChangeHandler = (value: string, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const onSubmitHandler = useCallback(
    (e: any) => {
      e.preventDefault();

       // Validate form data
 const formErrors = validateForm(formData, validationRules);
 setErrors(formErrors);

 // If no validation errors, proceed with submission
 if (Object.keys(formErrors).length === 0) {
      updateWorkSlot({
        endPoint: `/work-slot/${id}`,
        payload: {
          ...formData
        },
      });
      navigate(-1);
    }
    },
    [formData]
  );

  useEffect(() => {
    if (workSlot && workSlot.id) {
      setFormData({
        name: workSlot.name,
        start_time: convertTo24HourFormat(workSlot.start_time),
        end_time: convertTo24HourFormat(workSlot.end_time),
      });
    }
  }, [workSlot]);

  return (
    <>
      {formData && (
        <div className="bg-white w-full h-full p-8 space-y-4">
          <div className="flex w-full h-10 space-x-4 items-center">
            <ArrowLeftIcon
              onClick={() => navigate("/settings")}
              className="w-6 h-6 text-black cursor-pointer"
            />
            <span className="font-bold">Update Work Slot</span>
          </div>
          <form
            onSubmit={onSubmitHandler}
            className="w-full h-[calc(100vh-210px)] space-y-4 border border-gray-200 py-4 rounded"
          >
            <div className="px-4">
              <ScrollArea className="h-[calc(100vh-230px)] py-4">
                <div className="grid grid-cols-3 gap-8">
                  <TextInput
                    type="name"
                    placeholder=""
                    value={formData.name}
                    onChange={(e) => onChangeHandler(e.target.value, "name")}
                    required
                    theme="black"
                    error={errors.name}
                  >
                    <label htmlFor="applicationName">Name*</label>
                  </TextInput>

                  <TextInput
                    type="time"
                    placeholder=""
                    value={formData.start_time}
                    onChange={(e) =>
                      onChangeHandler(e.target.value, "start_time")
                    }
                    required
                    theme="black"
                    error={errors.start_time}
                  >
                    <label htmlFor="applicationName">Start time*</label>
                  </TextInput>

                  <TextInput
                    type="time"
                    placeholder=""
                    value={formData.end_time}
                    onChange={(e) =>
                      onChangeHandler(e.target.value, "end_time")
                    }
                    required
                    theme="black"
                    error={errors.end_time}
                  >
                    <label htmlFor="applicationName">End time*</label>
                  </TextInput>
                </div>
              </ScrollArea>
            </div>

            <div className="w-full flex justify-end items-center">
              <button
                type="submit"
                className="rounded text-white bg-green px-6 py-2"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      )}
    </>
  );
};
