export const ReactPlayer = ({ videoRef, url, type }: any) => {
  return (
    <div className="h-full w-full border rounded-[15px] border-header-border space-y-4">
      <video
        ref={videoRef}
        className="camera_video_discover"
        id="streaminPlayer"
        controls
        autoPlay={true}
        style={{ width: "100%", height: "100%" }}
        muted={true}
      >
        <source src={url} />
      </video>
    </div>
  );
};
