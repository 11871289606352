import React from "react";
import Select, { StylesConfig } from "react-select";

interface NormalSelectProps {
  data: Array<{ [key: string]: any }>;
  onValueChange: (value: any) => void;
  defaultValue?: any;
  placeholder: string;
  className?: string;
  value?: string;
  theme?: "black" | "white";
  keyField: string;
  valueField: string;
  disabled?: boolean;
  error?: string; // Added error prop
  children?: React.ReactNode;
}

export const NormalSelect: React.FC<NormalSelectProps> = ({
  data,
  onValueChange,
  defaultValue,
  children,
  placeholder,
  className,
  value,
  theme = "black",
  keyField,
  disabled,
  valueField,
  error, // Destructure error prop
  ...props
}) => {
  const customStyles: StylesConfig<any, true> = {
    control: (provided, state) => ({
      ...provided,
      width: "100%",
      height: "100%",
      borderRadius: 8,
      color: theme === "black" ? "white" : "black",
      borderColor: error ? "red" : "#DFDCDC", // Highlight border in red if error
      '&:hover': {
        borderColor: error ? "red" : "#DFDCDC", // Apply red border color on hover if error
      },
      '&:focus': {
        borderColor: error ? "red" : "#DFDCDC",
      },
      '&:active': {
        borderColor: error ? "red" : "#DFDCDC",
      },
      boxShadow: error ? "0 0 0 1px red" : "none", // Add red shadow if error
    }),

    placeholder: (provided) => ({
      ...provided,
      color: "#000000",
    }),
  };

  return (
    <div className="space-y-1">
      {children}
      <Select
        isDisabled={disabled}
        className={`w-full inline-flex items-center h-10 justify-between`}
        styles={customStyles}
        placeholder={placeholder}
        options={data?.map((item) => ({
          label: item[valueField],
          value: item[keyField],
        }))}
        isClearable={false}
        defaultValue={defaultValue}
        value={defaultValue}
        onChange={onValueChange}
        {...props}
      />
      {error && <p className="text-red-500 text-sm mt-1">{error}</p>} {/* Display error message */}
    </div>
  );
};
