import React, { useState } from "react";
import * as Dropdown from "@radix-ui/react-dropdown-menu";
import classnames from "classnames";
import { Link, useNavigate } from "react-router-dom";

export const DropdownMenu = ({
  onToggleHandle,
  children,
  innerStyle,
  arrowStyle,
  isArrowShow,
  data,
}: any) => {
  const navigate = useNavigate();

  const renderContent = (obj: any) => {
    switch (obj.name) {
      case "top":
        return <div key={obj.id}>{obj.component}</div>;

      case "separater":
        return (
          <Dropdown.Separator
            key={obj.id}
            className="h-[1px] bg-header-border m-[5px]"
          />
        );

      case "Add Before":
        return (
          <Dropdown.Item
            key={obj.id}
            className="inline-flex items-center space-x-2 text-white pl-6 focus:outline-none cursor-pointer"
          >
            <div>{obj.icon}</div>
            <p>{obj.name}</p>
          </Dropdown.Item>
        );

      case "Add After":
        return (
          <Dropdown.Item
            key={obj.id}
            className="inline-flex items-center space-x-2 text-white pl-6 focus:outline-none cursor-pointer"
          >
            <div>{obj.icon}</div>
            <p>{obj.name}</p>
          </Dropdown.Item>
        );

      case "Delete":
        return (
          <Dropdown.Item
            key={obj.id}
            onClick={onToggleHandle}
            className="inline-flex items-center space-x-2 text-white pl-6 focus:outline-none cursor-pointer"
          >
            <div>{obj.icon}</div>
            <p>{obj.name}</p>
          </Dropdown.Item>
        );

      case "Logout":
        return (
          <Dropdown.Item
            key={obj.id}
            onClick={obj.logout}
            className="inline-flex items-center space-x-2 text-white pl-6 focus:outline-none cursor-pointer"
          >
            <div>{obj.icon}</div>
            <div>{obj.name}</div>
          </Dropdown.Item>
        );

      default:
        return (
          <Dropdown.Item
            key={obj.id}
            onClick={() => navigate(obj.path)}
            className="focus:outline-none cursor-pointer"
          >
            <div className="inline-flex items-center space-x-2 text-white pl-6">
              <div>{obj.icon}</div>
              <div>{obj.name}</div>
            </div>
          </Dropdown.Item>
        );
    }
  };

  return (
    <Dropdown.Root>
      <Dropdown.Trigger asChild>{children}</Dropdown.Trigger>

      <Dropdown.Portal>
        <Dropdown.Content className={classnames(innerStyle)} sideOffset={5}>
          {data?.map((object: any) => renderContent(object))}
          {isArrowShow && <Dropdown.Arrow className={classnames(arrowStyle)} />}
        </Dropdown.Content>
      </Dropdown.Portal>
    </Dropdown.Root>
  );
};
