import axios from "axios";
import getStorage from "../services/storageService";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

instance.interceptors.request.use(
  (config) => {
    const ls = getStorage("[safety][0]");
    if (ls) {
      const token = ls["token"];
      config.headers["Authorization"] = token ? `Bearer ${token}` : "";
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  (response) => response,
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;
