import { Outlet } from "react-router-dom";
import { Footer, AnonymousHeader } from "../components";

export const AnonymousLayout = () => {
  return (
    <div className="w-full h-screen">
      <AnonymousHeader />
      <div className="w-full h-[calc(100vh-64px)]">
        <Outlet />
      </div>
      {/* <Footer/> */}
    </div>
  );
};
