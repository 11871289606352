import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ArrowLeftIcon } from "@radix-ui/react-icons";
import { useNavigate, useParams } from "react-router-dom";
import {
  SingleSelectDropdown,
  NormalSelect,
  ScrollArea,
  TextArea,
  TextInput,
  MultiSelect,
} from "../../../../components";
import {
  getRequest,
  optimisticOptions,
  postRequest,
  putRequest,
  queries,
} from "../../../../react-query";
import { useMutation, useQuery } from "react-query";
import { reverseArrayWithIds, validateForm } from "../../../../utils";
import errorToast from "../../../../utils/errorToast";

export const UpdateImage = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    name: "",
    description: "",
    client: {
      label: "",
      value: "",
    },
    image_captains: [],
    image_quality_controllers: [],
    zip_file: "",
    instruction: {
      label: "",
      value: "",
    }
  });

  const [errors, setErrors] = useState<Record<string, string>>({});

  const [selectedCategory, setSelectedCategory] = useState<any>();

  const { data: datasetData = {}, isLoading: isDatasetLoading }: any = useQuery(
    [queries.users, id],
    () => getRequest(`/dataset/${id}`),
    {
      enabled: !!id,
    }
  );

  // Destructuring Data
  const { data: dataset = {} } = datasetData;

  const { data: clientData = [], isClientLoading }: any = useQuery(
    [queries.clients],
    () => getRequest(`/client`)
  );
  // Destructuring Data
  const { data: clients = [] } = clientData;

  const {
    data: instructionsData = {}
  }: any = useQuery([queries.instructions], () => getRequest(`/instruction`));

  const { data: instructions = [] } = instructionsData;

  // Define validation rules
  const validationRules = {
    name: [{ required: true, message: "Name is required." }],
    description: [{ required: true, message: "Description is required." }],
    client: [{ required: true, message: "Client selection is required." }],
    image_captains: [{ required: true, message: "select a captain" }],
    image_quality_controllers: [{ required: true, message: "select a captain" }],
    instruction: [{ required: true, message: "Instruction is required" }],
  };

  const { data: categoryData = [], isCategoryLoading }: any = useQuery(
    [queries.categories],
    () => getRequest(`/category`)
  );
  // Destructuring Data
  const { data: categories = [] } = categoryData;

  const { data: safetyMembers = [], isSafetyLoading }: any = useQuery(
    [queries.users],
    () => getRequest(`/users`)
  );

  // Destructuring Data
  const { data: captains = [] } = safetyMembers;

  const onChangeHandler = (value: string | number, name: string) => {
    setFormData({ ...formData, [name]: value });
  };

  const { mutate: updateDataset } = useMutation(
    putRequest,
    optimisticOptions(queries.images)
  );

  const onSubmitHandler = useCallback(
    (e: any) => {
      e.preventDefault();


      // Validate form data
      const formErrors = validateForm(formData, validationRules);
      setErrors(formErrors);

      // Proceed with submission if no validation errors
      if (Object.keys(formErrors).length === 0) {
        updateDataset({
          endPoint: `/dataset/${id}`,
          payload: {
            name: formData.name,
            description: formData.description,
            client_id: formData.client.value,
            category_id: selectedCategory.category_id,
            sub_categories: selectedCategory.subcategory_ids,
            instruction_id: formData.instruction.value,
          },
        });
        navigate(-1);
      } else {
        errorToast('please check all fields')
      }
    },
    [formData, selectedCategory]
  );

  const handleSelectCategories = (selectedCategory: any) => {
    setSelectedCategory(selectedCategory);
  };

  useEffect(() => {
    if (dataset.id) {
      const {
        name,
        description,
        instruction,
        client,
        sub_categories,
        image_captains,
        image_quality_controllers,
      } = dataset;

      console.log("client", client)

      setFormData({
        name: name,
        description: description,
        client: {
          label: client.company_name,
          value: client.id,
        },
        zip_file: "",
        instruction:{
          label:instruction.name,
          value:instruction.id,
        } ,
        image_captains: image_captains.map((captain: any) => ({
          label: captain.username,
          value: captain.id,
        })),
        image_quality_controllers: image_quality_controllers.map(
          (captain: any) => ({
            label: captain.username,
            value: captain.id,
          })
        ),
      });
      setSelectedCategory(reverseArrayWithIds(sub_categories)[0]);
    }
  }, [setFormData, dataset, setSelectedCategory]);

  // Memoized filtered captains
  const image_captains = useMemo(
    () =>
      captains
        .filter((c: any) => c.roles.includes("IMAGE_CAPTAIN"))
        .map((c: any) => ({ label: c.username, value: c.id })),
    [captains]
  );
  const image_quality_controllers = useMemo(
    () =>
      captains
        .filter((c: any) => c.roles.includes("IMAGE_QUALITY_CONTROLLER"))
        .map((c: any) => ({ label: c.username, value: c.id })),
    [captains]
  );

  if (isCategoryLoading || isClientLoading) {
    return <div />;
  }

  return (
    <div className="bg-white w-full h-full p-8 space-y-4">
      <div className="flex w-full h-10 space-x-4 items-center">
        <ArrowLeftIcon
          onClick={() => navigate("/settings")}
          className="w-6 h-6 text-black cursor-pointer"
        />
        <span className="font-bold">Update Image Dataset</span>
      </div>
      <form
        onSubmit={onSubmitHandler}
        className="w-full h-[calc(100vh-210px)] space-y-4 border border-gray-200 py-4 rounded"
      >
        <div className="px-4">
          <ScrollArea className="h-[calc(100vh-230px)] py-4">
            <div className="grid grid-cols-3 gap-8">
              <TextInput
                type="text"
                placeholder=""
                value={formData.name}
                onChange={(e) => onChangeHandler(e.target.value, "name")}
                required
                theme="black"
                error={errors.name}
              >
                <label htmlFor="name">Dateset Name*</label>
              </TextInput>

              <NormalSelect
                theme="black"
                keyField="id"
                valueField="name"
                className="border border-main text-nowrap"
                data={instructions}
                error={errors.instruction}
                onValueChange={(value: string) =>
                  onChangeHandler(value, "instruction")
                }
                defaultValue={formData.instruction}
                placeholder="Select"
              >
                <label htmlFor="remark">Insruction Template</label>
              </NormalSelect>

              <NormalSelect
                theme="black"
                keyField="id"
                valueField="company_name"
                className="border border-main text-nowrap"
                data={clients}
                onValueChange={(cli: any) => {
                  // let client = clients.find(
                  //   (client: any) => client.id === cli.value
                  // );
                  setFormData({ ...formData, ["client"]: cli });
                }}
                defaultValue={formData.client}
                placeholder="Select"
              >
                <label htmlFor="remark">Clients Name*</label>
              </NormalSelect>

              <div className="col-span-3">
                <SingleSelectDropdown
                  className="border border-main text-nowrap"
                  theme="black"
                  categories={categories}
                  onSelect={handleSelectCategories}
                  defaultValue={selectedCategory}
                >
                  <label htmlFor="Select">Categories*</label>
                </SingleSelectDropdown>
              </div>

              <div className="col-span-3">
                <MultiSelect
                  theme="white"
                  keyField="value"
                  valueField="label"
                  data={image_captains}
                  onValueChange={(value) =>
                    onChangeHandler(value, "image_captains")
                  }
                  value={formData.image_captains}
                  defaultValue={formData.image_captains}
                  placeholder="Image Captains"
                  isMulti
                >
                  <label htmlFor="role">Image Captains*</label>
                </MultiSelect>
              </div>

              <div className="col-span-3">
                <MultiSelect
                  theme="white"
                  keyField="value"
                  valueField="label"
                  data={image_quality_controllers}
                  onValueChange={(value) =>
                    onChangeHandler(value, "image_quality_controllers")
                  }
                  value={formData.image_quality_controllers}
                  defaultValue={formData.image_quality_controllers}
                  placeholder="Quality Controllers"
                  isMulti
                >
                  <label htmlFor="role">Image Quality Controllers*</label>
                </MultiSelect>
              </div>

              <div className="col-span-3">
                <TextArea
                  placeholder=""
                  value={formData.description}
                  onChange={(e) =>
                    onChangeHandler(e.target.value, "description")
                  }
                  required
                  theme="black"
                  error={errors.description}
                >
                  <label htmlFor="description">Dataset Description*</label>
                </TextArea>
              </div>
            </div>
          </ScrollArea>
        </div>

        <div className="w-full flex justify-end items-center">
          <button
            type="submit"
            className="rounded text-white bg-green px-6 py-2"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
};
