import VideoInput from "../../assets/images/video_input.png";
import { useState } from "react";
import { postRequest } from "../../react-query";
import { getThumbnailForVideo } from "../../utils/generateThumbnail";

const CHUNK_SIZE = 5 * 1024 * 1024; // 5MB chunk size

export function SourceInput({
  onFileChangeHandler,
  label,
  children,
  ...props
}: any) {
  const [progress, setProgress] = useState(0);
  const [input, setInput] = useState({
    type: "initial",
  });

  const handleFileUpload = async (file: any) => {
    try {
      const file_name: any = `${file.name}_${Date.now()}`;
      const total_chunks: any = Math.ceil(file.size / CHUNK_SIZE);

      const { UploadId } = await postRequest({
        endPoint: `/upload/initiate-upload`,
        payload: {
          file_name,
        },
      });

      if (UploadId) {
        const uploadPromises = [];
        let uploadedChunks = 0;

        for (let i: any = 0, start = 0; i < total_chunks; i++) {
          const end = start + CHUNK_SIZE;
          const chunk = file.slice(start, end);
          const formData = new FormData();

          formData.append("index", i);
          formData.append("total_chunks", total_chunks);
          formData.append("file_name", file_name);
          formData.append("file", chunk);
          formData.append("upload_id", UploadId);

          const uploadPromise = postRequest({
            endPoint: `/upload/partial-video`,
            payload: formData,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }).then(() => {
            uploadedChunks++;
            const progress = Math.floor((uploadedChunks / total_chunks) * 100);
            setProgress(progress);
          });

          uploadPromises.push(uploadPromise);
          start = end;
        }

        await Promise.all(uploadPromises);

        const { media_url } = await postRequest({
          endPoint: `/upload/complete-upload`,
          payload: {
            file_name,
            upload_id: UploadId,
          },
        });
        return media_url;
      }
      return "";
    } catch (error) {
      console.log("error", error);
    }
  };

  const onSourceChangeHandler = async (target: any, name: string) => {
    const { files, type } = target;
    if (type === "file") {
      setInput({ ...input, type: "loading" });
      const preview_link = await getThumbnailForVideo(files[0]);
      const access_link = await handleFileUpload(files[0]);
      onFileChangeHandler(access_link, preview_link);
      setInput({ ...input, type: "uploaded" });

      // Optional: Delay the reset to show the success message
      setTimeout(() => {
        reset(); // Reset to initial state after a short delay
      }, 1000); // Adjust the timeout duration as needed
    }
  };

  const renderProcessing = (type: string) => {
    switch (type) {
      case "loading":
        return (
          <div className="relative w-28 h-28">
            <svg className="w-full h-full" viewBox="0 0 100 100">
              <circle
                className="text-gray-200 stroke-current"
                strokeWidth="10"
                cx="50"
                cy="50"
                r="40"
                fill="transparent"
              ></circle>

              <circle
                className="text-indigo-500 progress-ring__circle stroke-current"
                strokeWidth="10"
                strokeLinecap="round"
                cx="50"
                cy="50"
                r="40"
                fill="transparent"
                strokeDasharray="251.2"
                strokeDashoffset={`calc(251.2 - (251.2 * ${progress}) / 100)`}
              ></circle>

              <text
                x="50"
                y="50"
                fill={!!children ? "#000000" : "#ffffff"}
                fontFamily="Verdana"
                fontSize="12"
                textAnchor="middle"
                alignmentBaseline="middle"
              >
                {progress}
              </text>
            </svg>
          </div>
        );
      case "initial":
        return (
          <div className="flex flex-col space-y-2 justify-center items-center">
            <img src={VideoInput} alt="video" height={48} width={48} />
            <p className="text-nowrap">{label}</p>
          </div>
        );
      case "uploaded":
        return (
          <div className="relative text-center w-full h-full">
            <p className="text-green">Uploaded Successfully</p>
          </div>
        );
      default:
        return <div />;
    }
  };

  const reset = () => {
    setProgress(0);
    setInput({ ...input, type: "initial" });
  };

  const renderUI = (tab: string) => {
    switch (tab) {
      case "UPLOAD":
        return (
          <div className={`relative w-full ${props.innerClassName}`}>
            <input
              type="file"
              accept="video/*"
              onChange={(e: any) => onSourceChangeHandler(e.target, "media")}
              className="w-full h-full absolute bg-transparent opacity-0"
            />
            {renderProcessing(input.type)}
          </div>
        );
    }
  };

  return (
    <div className={`${props.className} space-y-1`}>
      {children}
      <div className="bg-transparent rounded cursor-pointer">
        {renderUI("UPLOAD")}
      </div>
    </div>
  );
}
