import React, { useEffect, useState } from "react";
import { ScrollArea } from "../scrollable";

interface SubCategory {
  id: string;
  name: string;
}

interface Category {
  id: string;
  name: string;
  color: string;
  sub_categories: SubCategory[];
}

interface SelectedCategory {
  category_id: string;
  subcategory_ids: string[];
}

interface MultiSelectDropdownProps {
  categories: Category[];
  onSelect: (selectedCategories: SelectedCategory[]) => void;
  children: any;
  theme: string;
  className: string;
  defaultValue?: any;
}

export const MultiSelectDropdown: React.FC<MultiSelectDropdownProps> = ({
  categories,
  onSelect,
  children,
  theme,
  className,
  defaultValue,
}) => {
  const [selectedSubcategories, setSelectedSubcategories] = useState<
    SelectedCategory[]
  >([]);
  const [showDropdown, setShowDropdown] = useState(false);

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const handleSubcategoryChange = (
    categoryId: string,
    subcategoryId: string
  ) => {
    const selectedIndex = selectedSubcategories.findIndex(
      (item) => item.category_id === categoryId
    );

    if (selectedIndex === -1) {
      // Category not found, add it with the selected subcategory
      setSelectedSubcategories([
        ...selectedSubcategories,
        { category_id: categoryId, subcategory_ids: [subcategoryId] },
      ]);
    } else {
      // Category found, check if the subcategory is already selected
      const isAlreadySelected =
        selectedSubcategories[selectedIndex].subcategory_ids.includes(
          subcategoryId
        );

      if (!isAlreadySelected) {
        // Add the subcategory to the existing category
        const updatedCategories = [...selectedSubcategories];
        updatedCategories[selectedIndex].subcategory_ids.push(subcategoryId);
        setSelectedSubcategories(updatedCategories);
      } else {
        // Remove the subcategory if already selected, or remove all if unchecking
        const updatedCategories = [...selectedSubcategories];
        if (updatedCategories[selectedIndex].subcategory_ids.length === 1) {
          // If only one subcategory selected, remove the whole category
          setSelectedSubcategories(
            updatedCategories.filter((item) => item.category_id !== categoryId)
          );
        } else {
          // Remove the specific subcategory
          updatedCategories[selectedIndex].subcategory_ids = updatedCategories[
            selectedIndex
          ].subcategory_ids.filter((id) => id !== subcategoryId);
          setSelectedSubcategories(updatedCategories);
        }
      }
    }
  };

  const handleRemoveSubcategory = (
    categoryId: string,
    subcategoryId: string
  ) => {
    const updatedCategories = selectedSubcategories?.map((item) => {
      if (item.category_id === categoryId) {
        item.subcategory_ids = item.subcategory_ids.filter(
          (id) => id !== subcategoryId
        );
      }
      return item;
    });

    setSelectedSubcategories(
      updatedCategories.filter((item) => item.subcategory_ids.length > 0)
    );
  };

  const handleSubmit = () => {
    onSelect(selectedSubcategories);
    toggleDropdown(); // Close dropdown after submission
  };

  useEffect(() => {
    if (defaultValue) {
      setSelectedSubcategories(defaultValue);
      onSelect(defaultValue);
    }
  }, [defaultValue]);

  return (
    <div className="space-y-1">
      {children}
      <div className="relative">
        <div
          onClick={toggleDropdown}
          className={`w-full inline-flex min-h-10 items-center justify-between bg-transparent ${className}  px-2 space-x-2 focus:outline-none ${
            theme === "black"
              ? "text-black border-main rounded-lg data-[placeholder]:text-black"
              : "text-white border-white rounded-lg data-[placeholder]:text-white"
          }`}
        >
          {selectedSubcategories.length > 0 ? (
            <div>
              {selectedSubcategories?.map((category, index) => (
                <div
                  key={`${category.category_id}_${index}`}
                  className="flex items-center p-1"
                >
                  <span>
                    {
                      categories.find((c) => c.id === category.category_id)
                        ?.name
                    }
                  </span>
                  <span className="mr-1">-</span>
                  {category.subcategory_ids?.map((subcategoryId) => (
                    <div key={subcategoryId} className="flex items-center mr-2">
                      <span className="mr-1">
                        {
                          categories
                            .find((c) => c.id === category.category_id)
                            ?.sub_categories.find((s) => s.id === subcategoryId)
                            ?.name
                        }
                      </span>
                      <button
                        onClick={() =>
                          handleRemoveSubcategory(
                            category.category_id,
                            subcategoryId
                          )
                        }
                        className="text-red-600"
                      >
                        &#x2716;
                      </button>
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ) : (
            <span>Select</span>
          )}
        </div>
        {/* Dropdown for selection */}
        {showDropdown && (
          <div>
            <div className="absolute z-[9999] mt-1 min-w-[400px] bg-white border border-gray-300 rounded-md shadow-lg">
              <div className="p-4 space-y-4">
                {/* Category and Subcategory selection */}
                <ScrollArea className="h-32">
                  <div className="space-y-4">
                    {categories?.map((category) => (
                      <div key={category.id}>
                        <div className="font-bold">{category.name}</div>
                        <ul className="flex space-x-2">
                          {category.sub_categories?.map((subcategory) => (
                            <li
                              key={subcategory.id}
                              className="flex items-center"
                            >
                              <input
                                type="checkbox"
                                id={subcategory.id}
                                checked={selectedSubcategories.some(
                                  (item) =>
                                    item.category_id === category.id &&
                                    item.subcategory_ids.includes(
                                      subcategory.id
                                    )
                                )}
                                onChange={() =>
                                  handleSubcategoryChange(
                                    category.id,
                                    subcategory.id
                                  )
                                }
                                className="mr-2"
                              />
                              <label htmlFor={subcategory.id}>
                                {subcategory.name}
                              </label>
                            </li>
                          ))}
                        </ul>
                      </div>
                    ))}
                  </div>
                </ScrollArea>
                {/* Submit button */}
                <div className="mt-4 flex justify-end">
                  <button
                    onClick={handleSubmit}
                    className="bg-blue-500 hover:bg-blue-600 text-white px-4 py-2 rounded-md"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};
