import React, { useMemo, useRef, useState } from "react";
import { defaultConfig, formatLabel } from "./config";
import { openLinkInNewTab } from "../../pages/settings/utils";
import { DynamicLabel } from "../../types";
import { toast } from "react-toastify";

export const BottomControls = ({
  handleNextPage,
  handlePrevPage,
  handleSubmit,
  page,
  pagination,
  categories,
  dynamicLabels,
  handleLabeling,
  instructionLink,
}: any) => {
  const categoryRef = useRef<any>();
  const [selectedCategory, setSelectedCategory] = useState<DynamicLabel>();
  const [selectedSubCategories, setSelectedSubCategories] = useState<any[]>([]);

  const totalPages = useMemo(
    () => Math.ceil(pagination?.totalCount / pagination?.pageSize),
    [pagination]
  );

  const onAddLabel = () => {
    if (!selectedCategory || selectedSubCategories.length === 0) return;

    const label = formatLabel(selectedCategory, selectedSubCategories, {
      sort: "asc", // Sort alphabetically ascending
      separator: ", ", // Keep the same separator
    });

    const updatedLabelsSet = new Set(dynamicLabels.map((l: DynamicLabel) => l.label));

    // Check if the label already exists
    if (!updatedLabelsSet.has(label)) {
      handleLabeling([
        ...dynamicLabels,
        { label: label, background: selectedCategory.background },
      ]);
    } else {
      toast.error("Label already exists!")
    }

    categoryRef.current.value = "";
    setSelectedCategory({
      label: "",
      background: "",
    });
    setSelectedSubCategories([]);
  };

  const onReset = () => {
    handleLabeling(defaultConfig);
    setSelectedCategory({
      label: "",
      background: "",
    });
    setSelectedSubCategories([]);
  };

  const handleCategoryChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const categoryId = event.target.value;
    const category = categories.find((cat: any) => cat.id === categoryId);
    setSelectedCategory({
      label: category.name,
      background: category.color,
    });
    setSelectedSubCategories([]);
  };

  const handleSubCategoryChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const subCategoryId = event.target.value;
    if (event.target.checked) {
      setSelectedSubCategories((prev) => [...prev, subCategoryId]);
    } else {
      setSelectedSubCategories((prev) =>
        prev.filter((id) => id !== subCategoryId)
      );
    }
  };

  return (
    <>
      <div className="flex items-center space-x-8">
        <select
          ref={categoryRef}
          className="bg-white focus:outline-none"
          onChange={handleCategoryChange}
        >
          <option value="">Select Category</option>
          {categories?.map((category: any) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </select>

        {selectedCategory && selectedCategory.label && (
          <div className="w-full grid grid-flow-col auto-cols-max rounded shadow-lg border border-white gap-x-3 px-3 py-0.5">
            {categories
              .find((cat: any) => cat.name === selectedCategory.label)
              ?.sub_categories?.map((sub: any) => (
                <label key={sub.id} className="flex items-center text-white">
                  <input
                    type="checkbox"
                    value={sub.name}
                    checked={selectedSubCategories.includes(sub.name)}
                    onChange={handleSubCategoryChange}
                    className="mr-2"
                  />
                  {sub.name}
                </label>
              ))}
          </div>
        )}

        <button onClick={onAddLabel} className="text-white text-nowrap">
          Add Label
        </button>
        <button className="text-white" onClick={onReset}>
          Reset
        </button>
      </div>

      <div className="flex items-center space-x-4">
        <div className="flex items-center space-x-4 relative">
          <button
            onClick={(e) => openLinkInNewTab(instructionLink, e)}
            className="text-white group relative"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="size-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z"
              />
            </svg>
            {/* Tooltip */}
            <div className="absolute bottom-8 left-1/2 transform -translate-x-1/2 bg-white text-black text-md rounded-md px-2 py-1 opacity-0 group-hover:opacity-100 transition-opacity shadow-lg z-[990]">
              Link To Instructions
            </div>
          </button>
        </div>
        <button
          disabled={page === 1}
          onClick={handlePrevPage}
          className={`${page === 1 ? "bg-gray-200" : "bg-[#E0F214]"
            } px-6 py-1 rounded-full`}
        >
          Previous
        </button>
        <button
          disabled={page >= totalPages}
          onClick={handleNextPage}
          className={` ${page === totalPages ? "bg-gray-200" : "bg-[#E0F214]"
            } px-6 py-1 rounded-full`}
        >
          Next
        </button>
        <button
          onClick={handleSubmit}
          className={`${true ? "bg-[#14FF00]" : "bg-gray-300"
            } px-6 py-1 rounded-full`}
        >
          Submit
        </button>
      </div>
    </>
  );
};
