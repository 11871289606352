



export const reverseArray = (arr=[]) => {
  return arr?.reduce((acc: any, subCat: any) => {
    const existingCategory = acc.find(
      (cat: any) => cat.id === subCat.category.id
    );
    if (existingCategory) {
      existingCategory.sub_categories.push({
        id: subCat.id,
        name: subCat.name,
      });
    } else {
      acc.push({
        id: subCat.category.id,
        name: subCat.category.name,
        color: subCat.category.color,
        sub_categories: [
          {
            id: subCat.id,
            name: subCat.name,
          },
        ],
      });
    }
    return acc;
  }, []);
};



interface SubCategory {
  id: number;
  name: string;
  category: {
    id: number;
    name: string;
    color: string;
  };
}

interface Result {
  category_id: number;
  subcategory_ids: number[];
}

export const reverseArrayWithIds = (arr: SubCategory[] = []): Result[] => {
  const categoryMap = new Map<number, { subcategory_ids: number[] }>();

  arr.forEach((subCat) => {
    const { id: catId } = subCat.category;
    const subCategoryId = subCat.id;

    if (categoryMap.has(catId)) {
      categoryMap.get(catId)!.subcategory_ids.push(subCategoryId);
    } else {
      categoryMap.set(catId, { subcategory_ids: [subCategoryId] });
    }
  });

  return Array.from(categoryMap.entries()).map(([category_id, { subcategory_ids }]) => ({
    category_id,
    subcategory_ids,
  }));
};
