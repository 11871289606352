import React, { useCallback, useMemo, useState } from "react";
import { DataGrid, List, ScrollArea } from "../../../../components";
import { getRequest, queries } from "../../../../react-query";
import { useQuery } from "react-query";
import ReactPaginate from "react-paginate";
import { TriangleLeftIcon, TriangleRightIcon } from "@radix-ui/react-icons";
import { useNavigate } from "react-router-dom";
import { GlobalFilters, GlobalSearch } from "../../../../components/filters";

export const CaptainsList = () => {
  let size = 10;
  const navigate = useNavigate();
  const [page, setPage] = useState<number>(1);

  const [input, setInput] = useState({
    filter: "ACTIVE",
    search: "",
  });

  const {
    data: usersData = [],
    isLoading,
    error,
  }: any = useQuery(
    [queries.users, input.filter, page, size],
    () =>
      getRequest(`/users?status=${input.filter}&page=${page}&pageSize=${size}`),
    {
      enabled: !!page || !!input.filter,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
    }
  );

  // Destructuring Data
  const { data: users = [], pagination } = usersData;

  const totalPages = useMemo(
    () => Math.ceil(pagination?.totalCount / pagination?.pageSize),
    [pagination]
  );

  const onPageChangeHandler = useCallback((page: number) => {
    setPage(page + 1);
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Username",
        accessor: "username",
        disableFilters: true,
      },
      {
        Header: "Email",
        accessor: "email",
        disableFilters: true
      },
      {
        Header: "Contact",
        accessor: "contact",
        disableFilters: true,
      },
      {
        Header: "Country",
        accessor: "country.name",
        disableFilters: true,
      },
     
      {
        Header: "Roles",
        accessor: (row: any) => <div className="grid grid-cols-1">
          {row.roles
            ?.map((role: any) => {
              return <p>{role}</p>;
            })}
        </div>,
        disableFilters: true,
        width:200
      },
    ],
    [users]
  );

  const onValueChangeHandler = (value: string, name: string) => {
    if (value) {
      setInput({
        ...input,
        [name]: value,
      });
    }
  };

  const onRowHandler = (data: any) => {
    navigate(`/settings/captain/update/${data.id}`, {
      state: {
        data: data,
      },
    });
  };

  const goToPage = () => {
    navigate("/settings/captain/create");
  };

  return (
    <div className="text-black space-y-4 py-4">
      <div className="flex justify-between items-center">
        <GlobalFilters
          filter={input["filter"]}
          setFilter={(value: string) => onValueChangeHandler(value, "filter")}
        />
        <div className="flex items-center space-x-4">
          <GlobalSearch
            value={input["search"]}
            setValue={(e: any) =>
              onValueChangeHandler(e.target.value, "search")
            }
          />
          <button
            onClick={goToPage}
            className="bg-green active:bg-[#228B22] px-4 py-1.5 text-white rounded-full"
          >
            Create
          </button>
        </div>
      </div>

      <ScrollArea className="w-full  h-[calc(100vh-260px)]">
        <DataGrid data={users} columns={columns} onRowHandler={onRowHandler} />
      </ScrollArea>

      <ReactPaginate
        containerClassName={"paginationContainer"}
        pageClassName={"paginationItem"}
        activeClassName={"paginationActive"}
        onPageChange={(event) => onPageChangeHandler(event.selected)}
        pageCount={totalPages | 1}
        breakLabel="..."
        previousLabel={
          <TriangleLeftIcon className={`w-10 h-10 cursor-pointer`} />
        }
        nextLabel={<TriangleRightIcon className={`w-10 h-10 cursor-pointer`} />}
      />
    </div>
  );
};
